import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useSetup } from '../../SetupContext';
import React from 'react';
import LinkedInLoadingManager, {
  WebviewLoadFailedResponse
} from '@common/LoadingManager/LinkedInLoadingManager.renderer';
import { language } from '@/index';
import { QuoteLoading } from 'ui-utils';
import LoadingManager from '@common/LoadingManager/LoadingManager.renderer';

export default function SetupLinkedInLoading() {
  const { linkedinLoadingComplete } = useSetup();

  const [webviewLoadingFailedOpen, setWebviewLoadingFailedOpen] =
    React.useState(false);
  const resolveWebviewLoadingFailed = React.useRef<
    (res: WebviewLoadFailedResponse) => void
  >(() => undefined);

  React.useEffect(() => {
    let mounted = true;
    LoadingManager.waitForLoad().then(() => {
      if (mounted) linkedinLoadingComplete();
    });
    LinkedInLoadingManager.setWebviewLoadFailedHandler(
      () =>
        new Promise((res) => {
          resolveWebviewLoadingFailed.current = res;
          setWebviewLoadingFailedOpen(true);
        })
    );

    return () => {
      mounted = false;
      LinkedInLoadingManager.setWebviewLoadFailedHandler(() =>
        Promise.resolve('once')
      );
    };
  }, []);

  return (
    <>
      <QuoteLoading
        play={!webviewLoadingFailedOpen}
        show
        sx={{
          pt: 12,
          pb: 5
        }}
      />
      <Dialog open={webviewLoadingFailedOpen} maxWidth="lg">
        <DialogTitle>{language.text.error_linkedin_loading}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.explanation_webview_vs_api}
          </DialogContentText>
          <DialogContentText>
            {language.text.choice_webview_vs_api}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              resolveWebviewLoadingFailed.current('retry');
              setWebviewLoadingFailedOpen(false);
            }}>
            {language.text.retry_safe_load}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              resolveWebviewLoadingFailed.current('once');
              setWebviewLoadingFailedOpen(false);
            }}>
            {language.text.use_just_once}
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              resolveWebviewLoadingFailed.current('always');
              setWebviewLoadingFailedOpen(false);
            }}>
            {language.text.use_always}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
