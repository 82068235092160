import Logger from 'electron-log';
import { useQuery } from 'react-query';

export interface Quote {
  quote: string;
  author: string;
}

interface RawQuote {
  q: string;
  a: string;
  h: string;
}
async function getQuote(): Promise<Quote | null> {
  try {
    const res = await fetch('https://zenquotes.io/api/random/', {
      method: 'GET',
      redirect: 'follow',
      mode: 'cors'
    });
    const result = (await res.json()) as unknown as RawQuote[];
    if (result[0].a === 'zenquotes.io') return null;
    const quote = result[0];
    return {
      author: quote.a,
      quote: quote.q
    };
  } catch (e) {
    Logger.error('Error getting quote: ', e);
    return null;
  }
}
getQuote.getQueryKey = () => ['misc', 'quote', 'get'];

const useQuote = () => {
  const { data: quote, ...rest } = useQuery(getQuote.getQueryKey(), getQuote, {
    cacheTime: 1000
  });
  return {
    quote,
    ...rest
  };
};

export default useQuote;
