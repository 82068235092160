import React from 'react';
import {
  Badge,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Styles } from '@/types';
import { Theme } from '@mui/material';

export type RouteButtonProps = {
  icon: React.ReactNode;
  label: React.ReactNode;
  activeColor?: string | ((theme: Theme) => string);
  disabled?: boolean;
  badge?: number;
  onlyIcon?: boolean;
  rootPath?: string;
  marginLeft?: number;
  iconStyle?: object;
} & (
  | {
      link: string;
    }
  | {
      active: boolean;
      link?: string;
      // if returned true the link will be navigated to
      onClick: (active: boolean) => boolean | undefined;
    }
);

const styles: Styles<'iconWrapper'> = {
  iconWrapper: {
    padding: (theme) => theme.spacing(1),
    svg: {
      height: (theme) => theme.spacing(3.25),
      width: (theme) => theme.spacing(3.25)
    }
  }
};

const RouteButton = React.forwardRef<HTMLDivElement, RouteButtonProps>(
  (props, ref) => {
    const navigate = useNavigate();
    const { pathname: path } = useLocation();

    const active = React.useMemo(() => {
      if ('active' in props) return props.active;
      const completeLink = props.link.startsWith('/')
        ? props.link
        : (props.rootPath ?? '') + '/' + props.link;
      return path.startsWith(completeLink);
    }, [path, props]);

    const click = React.useCallback(() => {
      // if onClick is defined, call it and if it returns true, navigate to link (if link is defined)
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- onClick can be undefined
      if ('onClick' in props && props.onClick)
        if (!props.onClick(active)) return;
      if (props.link)
        navigate(
          props.link.startsWith('/')
            ? props.link
            : (props.rootPath ?? '') + '/' + props.link
        );
    }, [props, navigate]);

    return (
      <ListItemButton
        onClick={click}
        sx={{
          ...(active && {
            'color':
              props.activeColor ?? ((theme) => theme.palette.primary.main),
            '&:hover': {
              color:
                props.activeColor ?? ((theme) => theme.palette.primary.main)
            }
          }),
          marginLeft: `${props.marginLeft ?? 0}px`
        }}
        ref={ref}
        disabled={props.disabled}>
        <ListItemIcon
          sx={{
            ...styles.iconWrapper,
            ...props.iconStyle,
            minWidth: 0,
            color: active
              ? 'currentColor'
              : (theme) => theme.palette.action.disabled
          }}>
          <Badge
            badgeContent={props.badge ?? 0}
            color="error"
            invisible={!props.badge}>
            {props.icon}
          </Badge>
        </ListItemIcon>
        {!props.onlyIcon && (
          <ListItemText sx={{ ml: 1 }}>
            <Typography fontWeight={active ? 600 : 500}>
              {props.label}
            </Typography>
          </ListItemText>
        )}
      </ListItemButton>
    );
  }
);

export default RouteButton;
