import { useNavigate } from 'react-router-dom';
import { APP_PATHS, MANUAL_MODE_PATHS, TRAINING_PATHS } from '@/Paths';
import { language } from '@/index';
import SettingsGenericInfo from '../SettingsGenericInfo';

export function ProgressionBasicUser() {
  const navigate = useNavigate();

  return (
    <SettingsGenericInfo
      header={language.text.nice_that_you_use_ssc}
      button={{
        label: language.text.to_the_pilot_training,
        onClick: () =>
          navigate(
            `/${APP_PATHS.Manual}/${MANUAL_MODE_PATHS.Training}/${TRAINING_PATHS.SSCTraining}`
          )
      }}>
      {language.text.congratulations_you_are_a_basic_user}
    </SettingsGenericInfo>
  );
}
