import { LinkedInAccount } from 'linkedin-domain-types';
import { useQuery } from 'react-query';
import { QueryOptions } from '@/types';
import queryClient from '@/other/QueryClient';
import PrintableError from '@common/PrintableError/PrintableError';
import WebviewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import log from 'electron-log';

let getSelfPromise: Promise<LinkedInAccount | null> | null = null;
/**
 * Get the LinkedIn account of the currently logged in user.
 * Wait until linkedin is initialized (state in useLinkedInAPI) before using this function.
 * @param useCache whether to try to get linkedin profile from cache or not
 */
export async function getSelf<ThrowError extends true | false = false>(
  useCache = true,
  throwErrorOnFail?: ThrowError
): Promise<ThrowError extends true ? LinkedInAccount : LinkedInAccount | null> {
  for (let i = 0; i < 3; i++) {
    try {
      const account = await retryGetSelf(useCache, throwErrorOnFail);
      if (account) return account;
    } catch (e) {
      if (i === 2) {
        if (throwErrorOnFail) throw e;
      }
    }
  }
  //@ts-expect-error -- ts does not vibe with conditional error throwing
  return null;
}
async function retryGetSelf<ThrowError extends true | false = false>(
  useCache = true,
  throwErrorOnFail?: ThrowError
): Promise<ThrowError extends true ? LinkedInAccount : LinkedInAccount | null> {
  let account: LinkedInAccount | null = null;
  if (getSelfPromise) {
    account = await getSelfPromise;
  } else {
    if (useCache) {
      // get cached linkedin profile
      const linkedInProfile = queryClient.getQueryData<LinkedInAccount | null>(
        LINKEDIN_OWN_ACCOUNT_QUERY
      );
      if (linkedInProfile) return linkedInProfile;
    }
    getSelfPromise = WebviewLinkedIn.getOwnProfile({
      prioritize: true
    }).catch(() => null);
    account = await getSelfPromise;
    getSelfPromise = null;
  }
  if (!account && throwErrorOnFail) {
    log.error("'Account.ts:getSelf': Could not get own LinkedIn profile");
    throw new PrintableError('Could not get own LinkedIn profile');
  }
  queryClient.setQueryData(LINKEDIN_OWN_ACCOUNT_QUERY, account);
  //@ts-expect-error -- ts does not vibe with conditional error throwing
  return account;
}

const LINKEDIN_OWN_ACCOUNT_QUERY = ['linkedin', 'account', 'self'];
// potentially move into a context to prevent to many refetches
export const useLinkedInAccount = (
  options?: QueryOptions<LinkedInAccount | null>
) => {
  const { data, ...rest } = useQuery(
    LINKEDIN_OWN_ACCOUNT_QUERY,
    () => getSelf(true, true),
    {
      initialData: null,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      retryDelay: 1000,
      // enable once the API is initialized (logged in and header set up)
      enabled: options?.enabled ?? true,
      ...options
    }
  );
  return {
    ...rest,
    linkedInAccount: data ?? null,
    errorString: rest.error
      ? rest.error instanceof PrintableError
        ? rest.error.message
        : undefined
      : undefined
  };
};
