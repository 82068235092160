//Convert String to uint8array
export function stringToBinary(s) {
    return new TextEncoder().encode(s);
}
//Convert uint8array to string
export function binaryToString(b) {
    return new TextDecoder().decode(b);
}
export function encodeToBinary(event, convertToBinary) {
    const name = stringToBinary(event.name);
    const eventId = stringToBinary(event.id);
    const payloadData = convertToBinary(event.name, event.payload);
    const payloadLength = new Uint8Array([payloadData.length]);
    return new Uint8Array([
        name.length,
        ...name,
        eventId.length,
        ...eventId,
        ...payloadData
    ]);
}
export function decodeFromBinary(data, convertFromBinary, returnBinary = true) {
    const nameLength = data[0];
    if (nameLength === undefined)
        throw new Error('Invalid message');
    const name = binaryToString(data.slice(1, nameLength + 1));
    const eventIdLength = data[nameLength + 1];
    if (eventIdLength === undefined)
        throw new Error('Invalid message');
    const eventId = binaryToString(data.slice(nameLength + 2, nameLength + 2 + eventIdLength));
    const rawPayload = data.slice(nameLength + 2 + eventIdLength);
    const payload = convertFromBinary(name, rawPayload);
    // @ts-expect-error typescript can't know that the name and payload will match
    if (!returnBinary)
        return { name, payload, id: eventId, rawPayload: null };
    // @ts-expect-error typescript can't know that the name and payload will match
    return { name, payload: payload, id: eventId, rawPayload };
}
export function decodeFromBinaryPartial(data) {
    const nameLength = data[0];
    if (nameLength === undefined)
        throw new Error('Invalid message');
    const name = binaryToString(data.slice(1, nameLength + 1));
    const eventIdLength = data[nameLength + 1];
    if (eventIdLength === undefined)
        throw new Error('Invalid message');
    const eventId = binaryToString(data.slice(nameLength + 2, nameLength + 2 + eventIdLength));
    const payload = data.slice(nameLength + 2 + eventIdLength);
    return { name, payload: payload, id: eventId };
}
export default {
    encodeToBinary,
    decodeFromBinary,
    decodeFromBinaryPartial
};
