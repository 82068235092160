import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Onboarding from './Sections/Onboarding';
import SSCTraining from './Sections/SSCTraining';
import TrainingUpcoming from './Sections/TrainingUpcoming';

import imgOnbording from '@/assets/img/training/Onboarding.png';
import imgTraining from '@/assets/img/training/SSC-Training.png';
import imgProgram from '@/assets/img/training/Trainingprogram.png';
import { language } from '@/index';
import MainConfig from '@common/config/MainConfig';
import { TRAINING_PATHS } from '@/Paths';
import Auth from '@common/AuthManager/Auth.renderer';

export const TRAINING_SECTIONS = [
  {
    path: TRAINING_PATHS.Onboarding,
    element: Onboarding,
    label: () => language.text.onboarding,
    imgSrc: imgOnbording
  },
  {
    path: TRAINING_PATHS.SSCTraining,
    element: SSCTraining,
    label: () => language.text.ssc_training,
    imgSrc: imgTraining,
    disabled: async () =>
      (Auth.getSessionInfo().usageDays ?? 0) <
      MainConfig.progression.usageDaysToUnlockTraining,
    disabledTooltip: () => language.text.use_ssc_to_unlock_training
  },
  {
    path: TRAINING_PATHS.Program,
    element: TrainingUpcoming,
    label: () => language.text.training_program,
    imgSrc: imgProgram,
    disabled: true
  }
] satisfies {
  path: string;
  element: React.FC<{}>;
  label: () => string;
  imgSrc: string;
  disabled?: boolean | (() => Promise<boolean>);
  disabledTooltip?: () => string;
}[];

export default function TrainingRouter() {
  return (
    <Routes>
      <Route index element={<Navigate to={TRAINING_SECTIONS[0].path} />} />
      {TRAINING_SECTIONS.map((section) => (
        <Route
          key={section.path}
          path={`${section.path}/*`}
          element={<section.element />}
        />
      ))}
    </Routes>
  );
}
