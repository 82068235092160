import LinkedInActions from '@common/Webview.renderer/LinkedInActions';
import AbstractLinkedInLoadingEngine from './AbstractLinkedInLoadingEngine';
import log from 'electron-log';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import { Iterators } from '@idot-digital/generic-helpers';
import { Conversation, LinkedInContact, Message } from 'linkedin-domain-types';
import WebviewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import ContactActions from '@/data/DataServer/Contact';
import { SSCChat } from '@/data/Classes/Chat/SSCChat';
import ChatActions from '@/data/DataServer/Chat';

export default class APILoadingEngine extends AbstractLinkedInLoadingEngine {
  private mb = MessageBus.getInstance();
  async firstLoad() {
    log.log('APILoadingEngine: starting first load');
    await this.loadNetwork();
    log.log('APILoadingEngine: finished first load');
  }

  private async loadNetwork() {
    await LinkedInActions.execute(['open-network', 'scroll-entire-network'], {
      forceBackground: true
    });
  }

  async startupLoad() {
    log.log('APILoadingEngine: starting startup load');
    await this.loadNewNetworkParticipants();
    await this.loadNewChatMessages();
    log.log('APILoadingEngine: finished startup load');
  }

  private async loadNewNetworkParticipants() {
    const mb = MessageBus.getInstance();
    const iterator = Iterators.fromApiV2<LinkedInContact & { index: number }>(
      (cursor) => WebviewLinkedIn.listNetworkParticipants(cursor?.index)
    );
    let knownParticipants = 0;
    for await (const participant of iterator) {
      mb.emit('load:info', {
        status: 'loading_network_participant',
        data: [participant.firstName + ' ' + participant.lastName]
      });
      const contact = await ContactActions.getFullProfile(
        participant.profileID
      ).catch(() => null);
      // send data as if it comes from the webview -> request interceptor handler will handle adding it to the database
      this.mb.emit('ReqIntersect:fullProfile', { profiles: [participant] });
      if (contact) {
        knownParticipants++;
        if (knownParticipants > 3) break;
      }
    }
    mb.emit('load:info', {
      status: 'finished_loading_network'
    });
  }

  private async loadNewChatMessages() {
    const mb = MessageBus.getInstance();
    const iterator = Iterators.fromApiV2<Conversation>((cursor) =>
      WebviewLinkedIn.listConversations(cursor?.lastActivityAt)
    );
    let unchangedConversations = 0;
    for await (const conversation of iterator) {
      mb.emit('ReqIntersect:conversations', {
        conversations: [
          {
            ...conversation,
            conversationID: conversation.conversationID
          }
        ]
      });
      const participant = conversation.participants[0];
      mb.emit('load:info', {
        status: 'loading_messages_with',
        data: [participant.firstName + ' ' + participant.lastName]
      });

      const isChatInteresting =
        this.manager.filterInterestingConversations([
          {
            conversationID: conversation.conversationID,
            profileIDs: conversation.participants.map((p) => p.profileID)
          }
        ]).length === 0;

      if (!isChatInteresting) continue;

      const chat = await ChatActions.getChat(
        conversation.conversationID,
        conversation
      );
      await this.checkMessagesOfChat(chat, conversation.conversationID);

      unchangedConversations++;
      if (unchangedConversations > 3) break;
    }
    mb.emit('load:info', {
      status: 'finished_loading_messages'
    });
  }

  private async checkMessagesOfChat(chat: SSCChat, conversationID: string) {
    const mb = MessageBus.getInstance();
    // go through messages of this chat
    const messageIterator = Iterators.fromApiV2<Message>((cursor) =>
      WebviewLinkedIn.listMessages(conversationID, cursor ?? undefined)
    );
    const lastKnownMessages = await chat.listMessages();
    let knownMessages = 0;
    let index = 0;
    for await (const message of messageIterator) {
      index++;
      mb.emit('load:info', {
        status: 'loading_message_from_chat_at_date',
        data: [
          chat.participants[0].firstName + ' ' + chat.participants[0].lastName,
          new Date(message.createdAt ?? 0),
          index
        ]
      });
      const parsedMessageID = message.messageID.split(',').pop()?.slice(0, -1);
      if (parsedMessageID)
        this.mb.emit('ReqIntersect:messages', {
          messages: [{ ...message, messageID: parsedMessageID, conversationID }]
        });

      if (lastKnownMessages.some((m) => m.messageID === message.messageID)) {
        knownMessages++;
        if (knownMessages > 3) break;
      }
    }
  }
}
