import React from 'react';
import { Box, Grid, IconButton, InputBase, Skeleton } from '@mui/material';
import { Button, Popover, Typography } from '@mui/material';
import ContactActions, {
  ContactFilter as ContactFilterType
} from '@/data/DataServer/Contact';
import { useManualMode } from '../ManualModeContext';
import { Contact } from '@/data/Classes/Contact';
import ContactFilter from '@/components/Contact/ContactFilter';
import ContactItem from './ContactItem';
import { useInView } from 'react-intersection-observer';
import ContactDetailsDialog from './ContactDetailsDialog';
import { language } from '@/index';
import { ContactType } from '@common/types/enums';
import {
  Clear,
  Close,
  PersonSearch,
  PriorityHigh,
  Search
} from '@mui/icons-material';
import log from 'electron-log';
import { FancyButton, useDebounced } from 'ui-utils';
import { Objects } from '@idot-digital/generic-helpers';
import FilterIcon from '@/components/Contact/FilterIcon';

const CONTACTS_PER_LINE = 5;

export default function ContactsWrapper() {
  const { ref, inView } = useInView();
  const { openChat } = useManualMode();

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const [search, setSearch] = React.useState('');
  const [filter, setFilter] = React.useState<ContactFilterType>({
    values: {
      type: ContactType.POTENTIAL_CUSTOMER
    }
  });

  const [stepsOpen, setStepsOpen] = React.useState<boolean>(false);
  const [stepsContact, setStepsContact] = React.useState<Contact | null>(null);

  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteContact, setDeleteContact] = React.useState<Contact | null>(
    null
  );
  const deleteRefs = React.useRef<{
    [key: Contact['profileID']]: HTMLButtonElement | null;
  }>({});

  const { contacts, status, hasNextPage, isLoading } =
    ContactActions.useContacts(
      { ...filter, searchQuery: search },
      {
        refetchOnWindowFocus: false,
        loadMore: inView
      }
    );

  // used to determine which "no more contacts" message is shown
  const filterActive = React.useMemo(() => {
    // if search is active, filter is active
    if (search) return true;
    // if any other filter beside values.type is active, filter is active
    if (Objects.keys(filter).some((key) => key !== 'values')) return true;
    if (Objects.keys(filter.values ?? {}).some((key) => key !== 'type'))
      return true;
    return false;
  }, [filter, search]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: (theme) => theme.spacing(3, 2)
      }}>
      <Box sx={{ mb: (theme) => theme.spacing(4) }}>
        <ContactFilter
          filter={filter}
          onChange={setFilter}
          hide={['unanswered']}>
          <FilterSearchIcon onChange={setSearch} value={search} />
        </ContactFilter>
      </Box>
      <Box>
        <Grid
          container
          justifyContent="flex-start"
          columnSpacing={0}
          rowSpacing={0}
          sx={{
            maxHeight: 'calc(100% - 12px)',
            alignContent: 'flex-start'
          }}>
          {contacts.map((contact) => (
            <ContactItem
              contact={contact}
              onDelete={() => {
                setDeleteContact(contact);
                setDeleteOpen(true);
              }}
              currentType={contact.type}
              itemsPerLine={CONTACTS_PER_LINE}
              onClick={() => {
                setStepsContact(contact);
                setStepsOpen(true);
              }}
              onOpenChat={() => openChat(contact)}
              ref={(ref) => (deleteRefs.current[contact.profileID] = ref)}
              key={contact.profileID}
            />
          ))}
          {hasNextPage && <Box ref={ref}></Box>}
          {isLoading &&
            Array.from(
              {
                length:
                  CONTACTS_PER_LINE - (contacts.length % CONTACTS_PER_LINE)
              },
              (_, i) => (
                <Grid item xs={12 / CONTACTS_PER_LINE} key={i}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: (theme) => theme.spacing(1),
                      height: '100%',
                      boxSizing: 'border-box'
                    }}>
                    <Skeleton
                      variant="circular"
                      sx={{
                        width: (theme) => theme.spacing(12),
                        height: (theme) => theme.spacing(12),
                        cursor: 'pointer',
                        mb: (theme) => theme.spacing(-5)
                      }}
                    />
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.background.paper,
                        borderRadius: 1.5,
                        padding: (theme) => theme.spacing(5, 1, 1, 1),
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: '1',
                        minHeight: (theme) => theme.spacing(21),
                        width: '100%',
                        marginTop: (theme) => theme.spacing(1),
                        boxSizing: 'border-box'
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: '100%'
                        }}>
                        <Skeleton
                          variant="text"
                          sx={{
                            width: '100%',
                            height: '1.5rem',
                            borderRadius: '0.5rem'
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )
            )}
        </Grid>
        {status === 'error' && (
          <>
            <PriorityHigh sx={{ fontSize: 60 }} color="error" />
            <Typography variant="h4" textAlign="center">
              {language.text.server_error}
            </Typography>
          </>
        )}
        {status === 'success' &&
          !isLoading &&
          (contacts.length === 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                placeItems: 'center',
                gap: 2,
                p: 4
              }}>
              {filterActive ? (
                <>
                  <Close sx={{ fontSize: 60 }} color="error" />
                  <Typography variant="h4" textAlign="center">
                    {language.text.no_fitting_contacts_found}
                  </Typography>
                </>
              ) : (
                <>
                  <PersonSearch sx={{ fontSize: 100 }} color="primary" />
                  <Typography variant="h4" textAlign="center">
                    {language.text.no_contacts_categorize_first}
                  </Typography>
                </>
              )}
            </Box>
          ) : (
            <Typography variant="caption" textAlign="center" mx="auto">
              {language.text.no_more_contacts}
            </Typography>
          ))}

        {/* delete contact popover */}
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          anchorEl={
            deleteContact ? deleteRefs.current[deleteContact.profileID] : null
          }
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}>
          <Box
            padding={2}
            sx={{
              background: (theme) => theme.palette.background.default
            }}>
            <Typography marginBottom={1}>
              {language.text.confirm_mark_as_audience_holder.replace(
                '{name}',
                deleteContact ? deleteContact.name : 'den Kontakt'
              )}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1
              }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  setDeleteOpen(false);
                }}>
                {language.text.back}
              </Button>
              <FancyButton
                fType={{
                  promise: true
                }}
                color="error"
                variant="contained"
                onClick={async () => {
                  if (!deleteContact) return;
                  try {
                    await deleteContact.setContactType(ContactType.NO_MATCH);
                  } catch (e) {
                    log.error(
                      `Error changing ${deleteContact.profileID} from audience holder to no match`
                    );
                    console.error(e);
                  } finally {
                    setDeleteOpen(false);
                  }
                }}>
                {language.text.no_match}
              </FancyButton>
              <FancyButton
                fType={{
                  promise: true
                }}
                color="tertiary"
                variant="contained"
                onClick={async () => {
                  if (!deleteContact) return;
                  try {
                    await deleteContact.setContactType(ContactType.PERSONAL);
                  } catch (e) {
                    log.error(
                      `Error changing ${deleteContact.profileID} from audience holder to personal contact`
                    );
                    console.error(e);
                  } finally {
                    setDeleteOpen(false);
                  }
                }}>
                {language.text.personal_contact}
              </FancyButton>
              {!deleteContact?.connected && (
                <FancyButton
                  fType={{
                    promise: true
                  }}
                  color="tertiary"
                  variant="contained"
                  onClick={async () => {
                    if (!deleteContact) return;
                    try {
                      await deleteContact.delete();
                    } catch (e) {
                      log.error(`Error deleting ${deleteContact.profileID}`);
                      console.error(e);
                    } finally {
                      setDeleteOpen(false);
                    }
                  }}>
                  {language.text.delete}
                </FancyButton>
              )}
            </Box>
          </Box>
        </Popover>

        <ContactDetailsDialog
          onClose={() => setStepsOpen(false)}
          open={stepsOpen}
          contact={stepsContact}
          onRecategorize={(type) => {
            setFilter((filter) => ({
              ...filter,
              values: {
                ...filter.values,
                type
              }
            }));
            setStepsOpen(false);
          }}
        />
      </Box>
    </Box>
  );
}

function FilterSearchIcon(props: {
  onChange: (search: string) => void;
  value: string;
}) {
  const [searchRaw, setSearchRaw] = React.useState(props.value);
  const { skipDebounce } = useDebounced(searchRaw, 500, (search) => {
    props.onChange(search);
  });

  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <FilterIcon
      active={searchRaw.length > 0}
      icon={<Search />}
      onOpen={() =>
        setTimeout(() => {
          ref.current?.focus();
        })
      }>
      <InputBase
        inputRef={ref}
        startAdornment={<Search />}
        endAdornment={
          <IconButton
            onClick={() => {
              setSearchRaw('');
              skipDebounce('');
            }}>
            <Clear />
          </IconButton>
        }
        placeholder={language.text.search}
        value={searchRaw}
        onChange={(e) => {
          setSearchRaw(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            skipDebounce();
          }
        }}
      />
    </FilterIcon>
  );
}
