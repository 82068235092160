import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { Typography } from '@mui/material';
import { Styles } from '@/types';
import { language } from '@/index';
import { AbstractChat } from '@/data/Classes/Chat/AbstractChat';
import SSCChatList from './SSCChatList';
import LinkedInChatList from './LinkedInChatList';
import { Logo } from 'ui-utils';
import { LinkedIn } from '@mui/icons-material';
import { ChatFilter } from '@/data/DataServer/Chat';

export type ChatListTab = 'ssc:normal' | 'li:normal' | 'li:archived';

export interface LinkedInChatListProps {
  openChat: AbstractChat | null;
  onSelect: (chat: AbstractChat, initial: boolean) => void | Promise<void>;
  filter?: ChatFilter & { searchQuery?: string };
  headerContent?: React.ReactNode | React.ReactNode[];
  tab?: ChatListTab;
  onTabChange?: (tab: ChatListTab) => void;
}

const styles = {
  header: {
    boxSizing: 'border-box',
    py: 1,
    px: 2,
    position: 'relative'
  },
  searchWrapper: {
    paddingRight: (theme) => theme.spacing(1),
    margin: (theme) => theme.spacing(2, 0, 0)
  },
  searchInput: {
    backgroundColor: (theme) => theme.palette.background.paper,
    borderRadius: 2,
    padding: (theme) => theme.spacing(1, 2),
    fontSize: '.9rem',
    height: (theme) => theme.spacing(4),
    border: (theme) => `1px solid ${theme.palette.divider}`
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    borderRight: (theme) => `1px solid ${theme.palette.divider}`
  },
  contactWrapper: {
    flex: '1 0 0',
    overflow: 'auto'
  }
} satisfies Styles<string>;

export default function ChatList(props: LinkedInChatListProps) {
  const { onTabChange, tab: propsTab, ...rest } = props;
  const [tab, setTab] = React.useState<ChatListTab>(props.tab ?? 'ssc:normal');

  React.useEffect(() => {
    onTabChange?.(tab);
  }, [tab]);

  React.useEffect(() => {
    setTab(propsTab ?? 'ssc:normal');
  }, [propsTab]);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.header}>
        <Typography variant="h5">
          {language.text.chat_inbox}
          {props.headerContent}
        </Typography>
      </Box>
      <Tabs
        centered
        variant="fullWidth"
        value={tab}
        onChange={(_, value: ChatListTab | undefined) => {
          if (value) setTab(value);
        }}>
        <Tab
          sx={{
            textTransform: 'none'
          }}
          label={
            <Box display="flex" alignItems="center" gap={0.5}>
              <Logo
                variant="blue"
                sx={{
                  height: 16,
                  width: 'auto',
                  ...(tab !== 'ssc:normal' && { filter: 'grayscale(1)' })
                }}
              />
              {language.text.focused}
            </Box>
          }
          value="ssc:normal"
        />
        <Tab
          sx={{
            textTransform: 'none'
          }}
          label={
            <Box display="flex" alignItems="center" gap={0.5}>
              <LinkedIn fontSize="small" />
              {language.text.normal}
            </Box>
          }
          value="li:normal"
        />
        <Tab
          sx={{
            textTransform: 'none'
          }}
          label={
            <Box display="flex" alignItems="center" gap={0.5}>
              <LinkedIn fontSize="small" />
              {language.text.archived}
            </Box>
          }
          value="li:archived"
        />
      </Tabs>
      <Box sx={styles.contactWrapper}>
        {tab === 'ssc:normal' ? (
          <SSCChatList {...rest} />
        ) : (
          <LinkedInChatList
            {...rest}
            category={tab.split(':').pop() as 'normal' | 'archived'}
          />
        )}
      </Box>
    </Box>
  );
}
