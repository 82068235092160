import { LanguageTexts } from './LanguageTexts';
import { MessageBusMessages } from './MessageBusMessages';

export interface GenericLanguageManager {
  text: LanguageTexts;
  getLanguage(): string;
}

let language: GenericLanguageManager = {
  text: {} as any,
  getLanguage: () => 'en'
};
let returnPath = '/';

export interface GenericSchedulerMessageBus {
  emitPersistent<Key extends keyof MessageBusMessages>(
    name: Key,
    payload: MessageBusMessages[Key],
    id?: string
  ): void;
}
let MessageBus = {
  emitPersistent: () => {
    throw new Error(
      '[focus-mode-scheduler] MessageBus dependency not injected'
    );
  }
} as GenericSchedulerMessageBus;

const FocusModeSchedulerConfig = {
  setLanguageManager(manager: GenericLanguageManager) {
    language = manager;
  },
  setReturnPath(path: string) {
    returnPath = path;
  },
  setMessageBus(bus: GenericSchedulerMessageBus) {
    MessageBus = bus;
  }
};

export default FocusModeSchedulerConfig;
export { language, returnPath, MessageBus };
