import { Box, Typography } from '@mui/material';
import SubscriptionActions from '@/data/DataServer/Subscription';
import { language } from '@/index';
import { DelayedSkeleton, FancyButton } from 'ui-utils';
import posthog from 'posthog-js';

export default function Subscription() {
  const { billingLink, status } = SubscriptionActions.useBillingLink();

  return (
    <Box>
      <Typography variant="h4">{language.text.subscription}</Typography>
      <Box>
        {status === 'loading' ? (
          <DelayedSkeleton variant="rounded" width={200} height={24} />
        ) : status === 'error' ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center">
            <Typography variant="body1">
              {language.text.generic_error}
            </Typography>
          </Box>
        ) : billingLink ? (
          <>
            <Typography
              variant="body1"
              sx={{
                maxWidth: 500
              }}>
              {language.text.here_you_can_edit_your_subscription}
            </Typography>
            <FancyButton
              sx={{
                mt: 2
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                posthog.capture('opened-subscription-management-portal');
                window.api.send('url:open_ext', billingLink);
              }}>
              {language.text.edit_subscription}
            </FancyButton>
          </>
        ) : (
          <Typography
            variant="body1"
            sx={{
              maxWidth: 500
            }}>
            {language.text.you_are_using_ssc_without_subscription}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
