// critical errors that prevent the user from using the ssc
export enum AuthError {
  // subscription has not been confirmed - canceled payment
  NO_SUBSCRIPTION = 1,
  // subscription has been paused
  SUBSCRIPTION_PAUSED,
  // subscription has been expired
  SUBSCRIPTION_EXPIRED,
  // free trial expired
  TRIAL_EXPIRED,
  // last payment failed
  PAYMENT_REQUIRED,
  // refresh token invalid -> login again
  INVALID_REFRESH_TOKEN,
  // internal server error
  SERVER_ERROR,
  // invalid linkedin account
  INVALID_LINKEDIN_ACCOUNT,
  // unknown error
  UNKNOWN_ERROR
}

// warning that there is an error but the user can still use the ssc
export enum AuthWarning {
  PAYMENT_FAILED = 1,
  // subscription has been canceled
  SUBSCRIPTION_CANCELED
}
