import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import TemplateActions from '@/data/DataServer/Templates';
import { MessageTemplate } from '@common/types/ApiTypes';
import EditPipelineTemplates from './EditPipelineTemplates';
import { language } from '@/index';
import { Loading, WriteIcon } from 'ui-utils';

export default function MessagesWrapper() {
  const [editOpen, setEditOpen] = React.useState<boolean>(false);
  const [editTemplateID, setEditTemplateID] = React.useState<
    MessageTemplate['templateID'] | null
  >(null);

  const maxPerLine = 4;
  const { templates, status } = TemplateActions.useTemplates();

  if (status === 'loading') return <Loading />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: (theme) => theme.spacing(3, 2),
        height: '100%',
        boxSizing: 'border-box'
      }}>
      <Grid
        container
        justifyContent="flex-start"
        columnSpacing={1.5}
        rowSpacing={1.5}
        sx={{
          maxHeight: 'calc(100% - 12px)',
          overflowY: 'auto',
          alignContent: 'flex-start'
        }}>
        {status === 'success' &&
          templates.map((template) => (
            <Grid item xs={12 / maxPerLine} key={template.templateID}>
              <Box
                sx={{
                  'display': 'flex',
                  'flexDirection': 'column',
                  'justifyContent': 'space-between',
                  'alignItems': 'center',
                  'padding': (theme) => theme.spacing(2),
                  'height': '100%',
                  'minHeight': 130,
                  'boxSizing': 'border-box',
                  'border': (theme) => `1px solid ${theme.palette.grey[900]}`,
                  'borderRadius': (theme) => theme.spacing(1),
                  'cursor': 'pointer',
                  '&:hover': {
                    backgroundColor: (theme) => theme.palette.secondary.main
                  }
                }}
                onClick={() => {
                  setEditOpen(true);
                  setEditTemplateID(template.templateID);
                }}>
                <Box></Box>
                <Typography
                  sx={{
                    textAlign: 'center'
                  }}>
                  {template.name}
                </Typography>
                <Button
                  size="small"
                  sx={{
                    padding: (theme) => theme.spacing(0.5),
                    minWidth: 0,
                    color: (theme) => theme.palette.action.disabled
                  }}>
                  <WriteIcon
                    sx={{
                      fontSize: '1.8em'
                    }}
                  />
                </Button>
              </Box>
            </Grid>
          ))}
        {templates.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="h6" textAlign="center">
              {language.text.no_templates}
            </Typography>
          </Grid>
        )}
      </Grid>
      <EditPipelineTemplates
        onClose={() => setEditOpen(false)}
        open={editOpen}
        templateID={editTemplateID}
      />
    </Box>
  );
}
