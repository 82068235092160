import React from 'react';

/**
 * Timer hook to call a function when timer is done
 * @param time time until timer is done (in s) (restarts when time changes)
 * @param callback calllback when timer is done
 * @returns time left in seconds and a function to restart the timer
 */
const useTimer = (
  time: number | null,
  {
    callback,
    startOnMount
  }: { callback?: null | (() => void); startOnMount?: boolean } = {}
) => {
  const pause = React.useRef(false);

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // store callback in ref to prevent recalling useEffect
  const callbackRef = React.useRef(callback);
  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // timer state in seconds
  const [timer, setTimer] = React.useState<number>(
    startOnMount !== false ? time ?? 0 : 0
  );

  const interval = React.useRef<NodeJS.Timeout | null>(null);

  const restartTimer = (newTime?: number) => {
    if (interval.current) clearInterval(interval.current);
    setTimer(newTime ?? time ?? 0);

    interval.current = setInterval(() => {
      if (pause.current) return;
      if (!mounted.current) return;
      setTimer((timer) => {
        timer--;
        if (timer <= 0) {
          callbackRef.current?.();
          if (interval.current) clearInterval(interval.current);
        }
        return timer;
      });
    }, 1000);

    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  };

  const initialTime = React.useRef(time);
  // start timer
  // only gets recalled when time changes
  React.useEffect(() => {
    if (time === null) return;
    // prevent starting timer on first time ->
    if (time === initialTime.current && startOnMount === false) return;

    return restartTimer();
  }, [time]);

  return {
    remaining: Math.max(timer, 0),
    completed: timer <= 0,
    restart: (newTime?: number) => {
      pause.current = false;
      restartTimer(newTime);
    },
    pause: () => (pause.current = true),
    resume: () => (pause.current = false)
  };
};

export default useTimer;
