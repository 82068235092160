import { Contact } from '../Contact';
import { ChatDetails, Message, MessageTemplate } from '@common/types/ApiTypes';
import {
  CategoryConversation,
  Conversation,
  ConversationContact
} from 'linkedin-domain-types';
import ContactActions from '../../DataServer/Contact';
import queryClient from '@/other/QueryClient';
import ChatActions from '../../DataServer/Chat';
import { InfiniteData } from 'react-query';
import { wait } from '@idot-digital/generic-helpers';
import Auth from '@common/AuthManager/Auth.renderer';
import PrintableError from '@common/PrintableError/PrintableError';
import { chats } from '@digital-sun-solutions/cloud-functions';
import WebviewLinkedIn from '@common/Webview.renderer/WebviewLinkedIn';
import log from 'electron-log';
import Logger from 'electron-log';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import LinkedInChatActions from '../../DataServer/LinkedInChats';
import { AbstractChat } from './AbstractChat';
import { LinkedInChat } from './LinkedInChat';
import { ServerPipelineStepType } from '@common/PipelineManager/PipelineTypes';

export class SSCChat extends AbstractChat {
  public readonly classID = 'ssc-chat';
  protected _conversationID: string | undefined;
  protected ownPublicIdentifier: string;
  protected conversationData: Conversation | CategoryConversation | null = null;
  protected chatDetails: ChatDetails | null = null;

  protected _linkedin_cursor: string | undefined = undefined;

  constructor(options: {
    contact?: Contact;
    conversationID?: string;
    ownPublicIdentifier: string;
    template?: MessageTemplate;
    conversationData?: (Conversation | CategoryConversation) & {
      cursor?: string;
    };
    chatDetails?: ChatDetails;
  }) {
    super();
    this._conversationID = options.conversationID;
    this.ownPublicIdentifier = options.ownPublicIdentifier;

    if (options.template) this._template = options.template;

    if (options.conversationData) {
      this._linkedin_cursor = options.conversationData.cursor ?? undefined;
      delete options.conversationData.cursor;
      this.conversationData = options.conversationData;
    }
    if (options.chatDetails) this.chatDetails = options.chatDetails;

    if (options.contact) this.contact = options.contact;
    else {
      // if no contact is given, load it ourself
      this.getConversationData().then((c) => {
        if (c?.participants.length === 1) {
          ContactActions.getContact(c.participants[0].profileID)
            .then((contact) => {
              if (contact) this.contact = contact;
            })
            .catch(() =>
              Logger.info(
                '[Chat] Could not load contact of id',
                c.participants[0].profileID
              )
            );
        }
      });
    }

    this.afterConstructor();
  }

  /**
   * Fetch the conversation data from the API
   */
  public async getConversationData(): Promise<
    Conversation | CategoryConversation | null
  > {
    if (this.conversationData) return this.conversationData;
    if (!this._conversationID) return null;
    const conversation = await WebviewLinkedIn.getConversation(
      this._conversationID
    );
    this.conversationData = conversation;
    // load contact
    if (
      conversation &&
      !this.contact &&
      conversation.participants.length === 1
    ) {
      const contact = await ContactActions.getContact(
        conversation.participants[0].profileID
      );
      if (contact) this.contact = contact;
    }
    return conversation;
  }

  public async listMessages(
    lastMessage?: Pick<Message, 'createdAt' | 'messageID'>
  ): Promise<Message[]> {
    if (!this._conversationID) return [];

    const cursor = lastMessage
      ? new Date(lastMessage.createdAt).toISOString()
      : undefined;

    const res = await Auth.execRoute((token) =>
      chats.get(
        {
          ...(this.contact
            ? { contactID: this.contact.contactID }
            : {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked outside callback fct
                conversationID: this._conversationID!
              }),
          ...(cursor && { cursor })
        },
        { token }
      )
    );
    if (res.code !== 200) return [];
    const messages = res.data.messages.map((m) => ({
      attachments: m.attachments,
      createdAt: new Date(m.createdAt),
      deleted: m.deleted,
      messageID: m.messageID,
      reactions: m.reactions,
      sendByYou: m.sendByYou,
      text: m.text
    })) as Message[];
    const lastMessageIndex = lastMessage
      ? messages.findIndex((m) => m.messageID === lastMessage.messageID)
      : -1;
    const newMessages =
      lastMessageIndex === -1 ? messages : messages.slice(lastMessageIndex + 1);
    const filteredMessages = newMessages.filter((m) =>
      this.shouldMessageBeDisplayed(m)
    );
    this.internalEmit('historic-messages', filteredMessages);
    return filteredMessages;
  }
  public static getListMessagesQueryKey(
    chat: string | Pick<SSCChat, 'conversationID'> | null = null
  ): string[] {
    const conversationID =
      typeof chat === 'string' ? chat : chat?.conversationID;

    if (conversationID) {
      return ['conversation', 'ssc', 'messages', conversationID];
    }
    return ['conversation', 'ssc', 'messages'];
  }
  public async sendMessage(
    text: string
  ): Promise<Omit<Message, 'id' | 'userid'>> {
    let messageID: string;
    let createdAt: Date;
    if (this._conversationID) {
      const result = await WebviewLinkedIn.sendMessage(
        this._conversationID,
        text
      );
      if (!result) {
        throw new PrintableError('Could not send message: LinkedIn error');
      }

      MessageBus.getInstance().emitPersistent('MessageSend', {
        conversationID: this._conversationID,
        profileID: this.contact?.profileID
      });
      messageID = result.messageID;
      createdAt = result.createdAt;
      this.registerMessage({
        attachments: result.attachments,
        createdAt: result.createdAt,
        deleted: result.deleted,
        messageID: result.messageID,
        reactions: result.reactions,
        sendByYou: true,
        text: result.text
      });
    } else if (!this.contact || this.contact.connected) {
      if (!this.contact)
        throw new PrintableError(
          "Can't send direct message: Contact is undefined"
        );
      const result = await WebviewLinkedIn.sendDirectMessage(
        this.contact.profileID,
        text
      );
      if (!result) {
        throw new PrintableError(
          'Could not send direct message: LinkedIn error'
        );
      }

      MessageBus.getInstance().emitPersistent('MessageSend', {
        conversationID: this._conversationID,
        profileID: this.contact.profileID
      });
      messageID = result.message.messageID;
      createdAt = result.message.createdAt;
      if (!this.contact.conversationID && result.conversationID) {
        await Auth.execRoute((token) =>
          chats.create(
            {
              archived: false,
              unreadCount: 0,
              conversationID: result.conversationID,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked outside callback fct
              profileID: this.contact!.profileID
            },
            { token }
          )
        );
      }
    } else {
      throw new PrintableError(
        `Can't send direct message to ${this.profileID}: Contact is not connected`
      );
    }

    // contact could not be defined if this is a group chat
    if (!this.contact) {
      await this.getConversationData();
      if (!this.conversationData && !this.chatDetails)
        throw new PrintableError(
          "Can't send direct message: Can't get conversationData"
        );
      if (
        this.chatDetails ??
        this.conversationData?.participants.length === 1
      ) {
        const id =
          this.chatDetails?.participant.profileID ??
          this.conversationData?.participants[0].profileID;
        this.contact = (await ContactActions.getContact(id)) ?? undefined;
        if (!this.contact)
          log.error(
            'Chat:sendMessage Could not get contact',
            this.conversationData
          );
      }
    }

    // The message gets added via Realtime API
    // if (this.contact) {
    //   // don't use `this.setMessages` since we need response from cloud
    //   const result = await Auth.execRoute((token) =>
    //     chats.addMessages(
    //       {
    //         // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked before callback fct
    //         conversationID: this.contact!.conversationID!,
    //         messages: [
    //           {
    //             attachments: [],
    //             createdAt,
    //             deleted: false,
    //             // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    //             messageID: messageID.split(',').pop()!.slice(0, -1),
    //             sendByYou: true,
    //             text,
    //             reactions: []
    //           }
    //         ]
    //       },
    //       { token }
    //     )
    //   );
    //
    //   if (result.code !== 200)
    //     log.error('Could not add message to database: ', result);
    // }

    const message = {
      reactions: [],
      attachments: [],
      createdAt,
      deleted: false,
      messageID,
      sendByYou: true,
      text
    };

    this.internalEmit('new-messages', [message]);

    return message;
  }

  public async sendMessageReaction(
    message: Message,
    emoji: string,
    status: boolean
  ): Promise<void> {
    await WebviewLinkedIn.setReaction(
      {
        attachments: message.attachments,
        createdAt: message.createdAt,
        deleted: message.deleted,
        messageID: message.messageID,
        reactions: message.reactions,
        sentFrom: message.sendByYou
          ? this.ownPublicIdentifier
          : this.contact?.publicIdentifier ??
            (
              this.conversationData?.participants[0] as
                | ConversationContact
                | undefined
            )?.publicIdentifier ??
            'unknown',
        text: message.text ?? ''
      },
      emoji,
      status
    );

    const reactionList = (() => {
      const existingReaction = message.reactions.find((r) => r.emoji === emoji);
      if (status) {
        if (existingReaction) {
          if (!existingReaction.viewerReacted) existingReaction.count++;
        } else {
          message.reactions.push({
            emoji,
            count: 1,
            viewerReacted: true
          });
        }
      } else {
        if (existingReaction) {
          if (existingReaction.viewerReacted) {
            existingReaction.count--;
            existingReaction.viewerReacted = false;
            if (existingReaction.count === 0) {
              message.reactions = message.reactions.filter(
                (r) => r.emoji !== emoji
              );
            }
          }
        }
      }
      return message.reactions;
    })();

    if (this.contact?.conversationID) {
      await Auth.execRoute((token) =>
        chats.addMessages(
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked before callback fct
            conversationID: this.contact!.conversationID!,
            messages: [
              {
                ...message,
                text: message.text ?? '',
                reactions: message.reactions
              }
            ]
          },
          {
            token
          }
        )
      );
    }

    // optimistic update
    queryClient.setQueryData(
      SSCChat.getListMessagesQueryKey(this),
      (messages: InfiniteData<Message[]> | null | undefined) => {
        if (!messages)
          return {
            pages: [],
            pageParams: []
          };
        return {
          pageParams: messages.pageParams,
          pages: messages.pages.map((page) =>
            page.map((m) => {
              if (message.messageID === m.messageID) m.reactions = reactionList;

              return m;
            })
          )
        };
      }
    );

    queryClient.invalidateQueries(SSCChat.getListMessagesQueryKey(this));
  }

  public async markAsRead(read: boolean): Promise<void> {
    if (this.conversationData) this.conversationData.read = read;
    if (this.chatDetails) this.chatDetails.unreadCount = read ? 0 : 1;

    await Promise.all([
      // send mark as read to linkedin
      this.conversationID
        ? WebviewLinkedIn.markAsRead(this.conversationID, read)
        : Promise.resolve(),
      // send mark as read to our cloud
      this.contact
        ? Auth.execRoute((token) =>
            chats.setState(
              {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked before callback fct
                profileID: this.contact!.profileID,
                unread: read ? 0 : 1
              },
              { token }
            )
          )
        : Promise.resolve()
    ]);

    wait(300).then(() => {
      queryClient.invalidateQueries(SSCChat.getListMessagesQueryKey(this));
      queryClient.invalidateQueries(ChatActions.listChats.getQueryKey());
      queryClient.invalidateQueries(
        ChatActions.getChat.getQueryKey(this._conversationID)
      );
      queryClient.invalidateQueries(LinkedInChat.getListMessagesQueryKey(this));
      queryClient.invalidateQueries(
        LinkedInChatActions.listChats.getQueryKey()
      );
    });
  }

  public async setArchived(archived: boolean): Promise<void> {
    queryClient.setQueryData(
      LinkedInChatActions.listChats.getQueryKey(
        archived ? 'archived' : 'normal'
      ),
      (old: InfiniteData<SSCChat[]> | null | undefined) => {
        if (!old) return null;
        return {
          pageParams: old.pageParams,
          pages: old.pages.map((page) =>
            page.filter((c) => c.conversationID !== this.conversationID)
          )
        };
      }
    );

    await Promise.all([
      this.conversationID
        ? WebviewLinkedIn.setArchived([this.conversationID], archived)
        : Promise.resolve(),
      this.contact
        ? Auth.execRoute((token) =>
            chats.setState(
              {
                archived,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked before callback fct
                profileID: this.contact!.profileID
              },
              { token }
            )
          )
        : Promise.resolve()
    ]);

    wait(300).then(() => {
      queryClient.invalidateQueries(
        LinkedInChatActions.listChats.getQueryKey('archived')
      );
      queryClient.invalidateQueries(
        LinkedInChatActions.listChats.getQueryKey()
      );
      queryClient.invalidateQueries(
        ChatActions.getChat.getQueryKey(this._conversationID)
      );
    });
  }

  public get participants():
    | ConversationContact[]
    | CategoryConversation['participants'] {
    return this.conversationData?.participants ?? [];
  }
  public get read(): boolean {
    return this.conversationData?.read ?? !this.chatDetails?.unreadCount;
  }
  public get lastActivityAt(): Date | null {
    const date =
      this.conversationData?.lastActivityAt ??
      this.chatDetails?.lastActivityAt ??
      null;
    // copy to prevent mutation
    if (date) return new Date(date);
    return null;
  }
  public get lastMessage(): string | null {
    if (this.chatDetails?.lastMessage) return this.chatDetails.lastMessage;
    return super.lastMessage;
  }
  public get cursor(): string | undefined {
    return this._linkedin_cursor;
  }

  /**
   * Get the profileID for the current chat
   */
  public get profileID(): string {
    return (
      this.contact?.profileID ??
      this.chatDetails?.participant.profileID ??
      this.participants[0].profileID
    );
  }

  public registerMessage(
    message: Omit<Message, 'id' | 'userid'>,
    unread = false
  ) {
    // check if message is already registered
    if (
      this.lastMessage === message.text &&
      Math.abs((this.lastActivityAt?.getTime() ?? 0) - Date.now()) < 200
    ) {
      console.debug(
        '[SSCChat.registerMessage] Message known skipping',
        message.messageID
      );
      return;
    }

    if (
      this.chatDetails &&
      unread &&
      this.lastMessage !== message.text &&
      !message.sendByYou
    ) {
      this.chatDetails.unreadCount++;
    }

    if (
      this.conversationData &&
      !this.conversationData.lastMessages?.some(
        (m) => m.messageID === message.messageID
      )
    ) {
      this.conversationData.lastMessages?.unshift({
        attachments: message.attachments,
        createdAt: message.createdAt,
        deleted: message.deleted,
        messageID: message.messageID,
        reactions: message.reactions,
        sentFrom: message.sendByYou
          ? this.ownPublicIdentifier
          : this.contact?.publicIdentifier ??
            (
              this.conversationData.participants[0] as
                | ConversationContact
                | undefined
            )?.publicIdentifier ??
            'unknown',
        text: message.text ?? ''
      });
      this.conversationData.lastActivityAt = new Date(message.createdAt);
      if (unread && !message.sendByYou) {
        this.conversationData.read = false;
        this.conversationData.unreadCount++;
      }
    }

    // optimistic update
    // add message to cache
    queryClient.setQueryData(
      SSCChat.getListMessagesQueryKey(this),
      (messages: InfiniteData<Message[]> | null | undefined) => {
        if (!messages) return null;
        return {
          pageParams: messages.pageParams,
          pages: messages.pages.map((page, i) => {
            if (
              i === 0 &&
              !page.some((m) => m.messageID === message.messageID)
            ) {
              page.unshift(message);
            }

            return page;
          })
        };
      }
    );
    // move chat to top of list
    queryClient.setQueryData(
      ChatActions.listChats.getQueryKey(),
      (chats: InfiniteData<SSCChat[]> | null | undefined) => {
        if (!chats) return null;
        return {
          pageParams: chats.pageParams,
          pages: chats.pages.map((page, i) => {
            page = page.filter((c) => c.conversationID !== this.conversationID);
            if (i === 0) {
              page.unshift(this);
            }

            return page;
          })
        };
      }
    );

    this.internalEmit('historic-messages', [message]);

    this.sendUpdate();

    if (
      !message.sendByYou &&
      this.contact?.lastPipelineEvent?.stepType ===
        ServerPipelineStepType.WaitForMessageReceived
    ) {
      this.contact.gotoNextPipelineStep();
    }
  }

  public registerReaction(
    messageID: Message['messageID'],
    reaction: Message['reactions'][0]
  ) {
    // update lastMessages
    const message = this.conversationData?.lastMessages?.find(
      (m) => m.messageID === messageID
    );
    if (message) {
      const existingReaction = message.reactions.find(
        (r) => r.emoji === reaction.emoji
      );
      if (existingReaction) {
        if (reaction.viewerReacted) existingReaction.count++;
      } else {
        message.reactions.push(reaction);
      }
    }

    // optimistic update
    queryClient.setQueryData(
      SSCChat.getListMessagesQueryKey(this),
      (messages: InfiniteData<Message[]> | null | undefined) => {
        if (!messages) return null;
        return {
          pageParams: messages.pageParams,
          pages: messages.pages.map((page) =>
            page.map((m) => {
              if (messageID !== m.messageID) return m;

              const existing = m.reactions.find(
                (r) => r.emoji === reaction.emoji
              );
              if (existing) {
                if (reaction.count === 0) {
                  m.reactions = m.reactions.filter(
                    (r) => r.emoji !== reaction.emoji
                  );
                } else {
                  existing.count = reaction.count;
                  existing.viewerReacted = reaction.viewerReacted;
                }
              } else {
                m.reactions.push(reaction);
              }
              return m;
            })
          )
        };
      }
    );

    this.sendUpdate();
  }
}
