import { Client } from './raw/client/Client';
import {
  ApiEvent,
  AudioAttachment,
  CategoryConversation,
  Conversation,
  ConversationContact,
  ConversationId,
  DashProfileUrn,
  Event,
  FileAttachment,
  ImageAttachment,
  LinkedInAccount,
  LinkedInContact,
  LinkedInConversation,
  LinkedInEvent,
  LinkedInMiniProfile,
  LinkedInVectorImage,
  Message,
  MessageConversationUrn,
  MessageMessageUrn,
  ProfileId,
  parseLinkedInCreateEvent,
  parseLinkedInMessengerConversation
} from 'linkedin-domain-types';
import {
  findProperty,
  Options,
  parseCollectionResponse,
  trace
} from './raw/client/Utils';
import Arrays from '@idot-digital/generic-helpers/dist/Arrays';
import { AxiosInstance } from 'axios';
import { LINKEDIN_FILE_HEADER } from './raw/client/Config';

export { parseCollectionResponse, findProperty };

@trace()
export default class LinkedIn {
  static getFileHeader() {
    return LINKEDIN_FILE_HEADER;
  }

  public static logger: Partial<
    Pick<Console, 'debug' | 'warn' | 'error' | 'info'>
  > = {};

  client: Client;
  private self?: LinkedInAccount;

  private defaultOptions: Options = {
    prioritize: false
  };

  constructor(requestInstance?: AxiosInstance) {
    this.client = new Client(undefined, requestInstance);
  }

  /**
   * Gets the options with the default options applied
   * @returns the options to use for the request
   */
  public getOptions<T extends Options>(options?: T): T & Options {
    return { ...this.defaultOptions, ...options } as T & Options;
  }

  /**
   * Sets the default options
   * @param options the options to set
   */
  public setDefaultOptions(options: Options): void {
    this.defaultOptions = options;
  }

  /**
   * Retrieves a conversation by its ID
   * @param conversationID ID of the conversation
   * @param options Optional options used for controlling behavior of the client
   */
  public async getConversation(
    conversationID: Conversation['conversationID'],
    options?: Options
  ): Promise<Conversation | null> {
    options = this.getOptions(options);
    const conversation = await this.client.conversationService.getConversation({
      conversationId: conversationID,
      options
    });
    if (!conversation) return null;
    return this.parseLinkedInConversation(conversation);
  }

  /**
   * Gets an iterator for all conversations of the logged-in user
   */
  public async *getConversationIterator(
    lastConversation?: Conversation,
    options?: Options
  ): AsyncGenerator<Conversation, null> {
    options = this.getOptions(options);
    const conversationIterator =
      this.client.conversationService.getConversations({
        createdBefore: lastConversation?.lastActivityAt,
        options
      });

    for (;;) {
      const conversationObj = await conversationIterator.next();
      const conversation = conversationObj.value;
      if (conversationObj.done) break;

      yield this.parseLinkedInConversation(conversation);
    }
    return null;
  }

  /**
   * Gets a page of conversations of the logged-in user in pages
   */
  public async listConversations(
    lastActivityAt?: Conversation['lastActivityAt'],
    options?: Options
  ): Promise<Conversation[]> {
    options = this.getOptions(options);
    const conversations =
      await this.client.conversationService.getConversationPage({
        createdBefore: lastActivityAt,
        options
      });

    return Array.from(conversations).map((c) =>
      this.parseLinkedInConversation(c)
    );
  }

  /**
   * Gets an iterator for conversations matching the given search query
   * @param keyword the search query
   * @param cursor the cursor to start iterating from
   * @param options Optional options used for controlling behavior of the client
   */
  public async *searchConversations(
    keyword: string,
    cursor?: string,
    options?: Options
  ): AsyncGenerator<CategoryConversation & { cursor: string }, null> {
    options = this.getOptions(options);
    const acc = this.self ?? (await this.getOwnProfile());
    if (!acc) return null;
    this.self = acc;
    const conversationIterator =
      this.client.conversationService.getConversationsWithKeyword({
        keyword,
        mailboxUrn: new DashProfileUrn(acc.profileID),
        options,
        nextCursor: cursor
      });

    for (;;) {
      const conversationObj = await conversationIterator.next();
      const conversation = conversationObj.value;
      if (conversationObj.done) break;

      yield {
        ...parseLinkedInMessengerConversation(conversation),
        cursor: conversationIterator.getCursor()
      };
    }
    return null;
  }

  /**
   * Gets a page of conversations matching the given search query
   * @param keyword the search query
   * @param cursor the cursor to start iterating from
   * @param options Optional options used for controlling behavior of the client
   */
  public async listSearchConversations(
    keyword: string,
    cursor?: string,
    options?: Options
  ): Promise<{
    cursor: string;
    conversations: CategoryConversation[];
  }> {
    options = this.getOptions(options);
    const acc = this.self ?? (await this.getOwnProfile());
    if (!acc)
      return {
        cursor: '',
        conversations: []
      };
    this.self = acc;
    const res =
      await this.client.conversationService.getConversationPageWithKeyword({
        keyword,
        mailboxUrn: new DashProfileUrn(acc.profileID),
        options,
        nextCursor: cursor
      });

    return {
      cursor: res.nextCursor,
      conversations: Array.from(res.conversations).map((c) =>
        parseLinkedInMessengerConversation(c)
      )
    };
  }

  /**
   * Gets an iterator for conversations that are archived
   * @param cursor the cursor to start iterating from
   * @param options Optional options used for controlling behavior of the client
   */
  public async *getArchivedConversations(
    cursor?: string,
    options?: Options
  ): AsyncGenerator<CategoryConversation & { cursor: string }, null> {
    options = this.getOptions(options);
    const acc = this.self ?? (await this.getOwnProfile());
    if (!acc) return null;
    this.self = acc;
    const conversationIterator =
      this.client.conversationService.getConversationsInCategory({
        mailboxUrn: new DashProfileUrn(acc.profileID),
        category: 'ARCHIVE',
        nextCursor: cursor,
        options
      });

    for (;;) {
      const conversationObj = await conversationIterator.next();
      const conversation = conversationObj.value;
      if (conversationObj.done) break;
      yield {
        ...parseLinkedInMessengerConversation(conversation),
        cursor: conversationIterator.getCursor()
      };
    }
    return null;
  }

  /**
   * Gets a page of conversations that are archived
   * @param cursor the cursor to start iterating from
   * @param options Optional options used for controlling behavior of the client
   */
  public async listArchivedConversations(
    cursor?: string,
    options?: Options
  ): Promise<{ cursor: string; conversations: CategoryConversation[] }> {
    options = this.getOptions(options);
    const acc = this.self ?? (await this.getOwnProfile());
    if (!acc)
      return {
        cursor: '',
        conversations: []
      };
    this.self = acc;
    const res =
      await this.client.conversationService.getConversationPageInCategory({
        mailboxUrn: new DashProfileUrn(acc.profileID),
        category: 'ARCHIVE',
        nextCursor: cursor,
        options
      });
    return {
      cursor: res.nextCursor,
      conversations: Array.from(res.conversations).map((c) =>
        parseLinkedInMessengerConversation(c)
      )
    };
  }

  /**
   * Gets an iterator for all messages of a conversation with the given ID
   * @param conversationId ID of the conversation
   * @param lastMessage the last message to start iterating from
   * @param options Optional options used for controlling behavior of the client
   */
  public async *getMessagesIterator(
    conversationId: string,
    lastMessage?: Message,
    options?: Options
  ): AsyncGenerator<Message, null> {
    options = this.getOptions(options);
    const messageIterator = this.client.messageService.getMessages({
      createdBefore: lastMessage?.createdAt,
      conversationId,
      options
    });

    for (;;) {
      const messageObj = await messageIterator.next();
      if (messageObj.done) break;
      const message = messageObj.value;

      const msg = linkedInEventToMessage(message);
      if (!msg) continue;
      yield msg;
    }
    return null;
  }

  /**
   * Gets a page of all messages of a conversation with the given ID
   * @param conversationId ID of the conversation
   * @param lastMessage the last message to start iterating from
   * @param options Optional options used for controlling behavior of the client
   */
  public async listMessages(
    conversationId: string,
    lastMessage?: Pick<Message, 'createdAt'>,
    options?: Options
  ): Promise<Message[]> {
    options = this.getOptions(options);
    const messages = await this.client.messageService.getMessagePage({
      createdBefore: lastMessage?.createdAt,
      conversationId,
      options
    });

    return Arrays.filterNull(
      Array.from(messages).map((m) => linkedInEventToMessage(m))
    );
  }

  public async getProfile(
    profileID: string,
    options?: Options
  ): Promise<LinkedInContact | null> {
    options = this.getOptions(options);
    const profile = await this.client.profileService.getProfile(
      profileID,
      options
    );
    if (!profile) return null;
    const id = profile.entityUrn.split(':').pop();
    if (!id) return null;

    const birthday = profile.birthDateOn;

    return {
      publicIdentifier: profile.publicIdentifier,
      profileID: id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      profilePictureUrl: resolveProfilePictureUrls(
        profile.profilePicture?.displayImageReference?.vectorImage
      ),
      infoText: profile.headline,
      birthday: birthday
        ? {
            month: birthday.month ?? 0,
            day: birthday.day ?? 0
          }
        : undefined
    };
  }

  public async getFullProfile(
    profileID: string,
    options?: Options
  ): Promise<
    (LinkedInContact & { conversationID?: string | undefined }) | null
  > {
    options = this.getOptions(options);
    const profile = await this.client.profileService.getFullProfile(
      profileID,
      options
    );
    if (!profile) return null;
    const id = profile.entityUrn.split(':').pop();
    const conversationID = profile.conversationID?.split(':').pop();
    if (!id) return null;

    return {
      publicIdentifier: profile.publicIdentifier,
      profileID: id,
      firstName: profile.firstName,
      lastName: profile.lastName,
      profilePictureUrl: resolveProfilePictureUrls(
        profile.profilePicture?.displayImageReference?.vectorImage
      ),
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- headline being null happend in prod...
      infoText: profile.headline ?? '',
      conversationID
    };
  }

  /**
   * Gets an iterator for all received invitations
   */
  public async *getInvitesIterator(
    startIndex?: number,
    options?: Options
  ): AsyncGenerator<LinkedInAccount, null> {
    options = this.getOptions(options);
    const invitesIterator = this.client.inviteService.getReceivedInvitations({
      start: startIndex,
      options
    });

    for (;;) {
      const inviteObj = await invitesIterator.next();
      if (inviteObj.done) break;
      const invite = inviteObj.value;

      const id = invite.fromMemberId;
      const member = invite.fromMember;
      if (!member || !id) {
        continue;
      }
      yield this.getContact(member);
    }
    return null;
  }

  /**
   * Gets a page of all received invitations
   */
  public async listInvites(
    startIndex?: number,
    options?: Options
  ): Promise<(LinkedInContact & { index: number })[]> {
    options = this.getOptions(options);
    const invites = await this.client.inviteService.getReceivedInvitationsPage({
      start: startIndex,
      options
    });

    return Arrays.filterNull(
      Array.from(invites).map((invite, index) => {
        const id = invite.fromMemberId;
        const member = invite.fromMember;
        if (!member || !id) {
          return null;
        }
        const contact = this.getContact(member) as LinkedInContact & {
          index: number;
        };
        contact.index = index + (startIndex ?? 0);
        return contact;
      })
    );
  }

  /**
   * Gets an iterator for all contacts of the logged-in user
   */
  public async *getNetworkParticipantsIterator(
    startIndex?: number,
    sortType:
      | 'RECENTLY_ADDED'
      | 'FIRSTNAME_LASTNAME'
      | 'LASTNAME_FIRSTNAME' = 'RECENTLY_ADDED',
    options?: Options
  ): AsyncGenerator<LinkedInContact, null> {
    options = this.getOptions(options);
    const networkIterator = this.client.profileService.getOwnNetwork(
      startIndex,
      sortType,
      options
    );
    for (;;) {
      const { value, done } = await networkIterator.next();
      if (done) break;

      const profile = value.connectedMemberResolutionResult;
      if (!profile) {
        continue;
      }

      yield {
        publicIdentifier: profile.publicIdentifier,
        profileID: profile.entityUrn.split(':').pop() ?? '',
        firstName: profile.firstName,
        lastName: profile.lastName,
        profilePictureUrl: resolveProfilePictureUrls(
          profile.profilePicture?.displayImageReference?.vectorImage
        ),
        infoText: profile.headline
      };
    }

    return null;
  }

  /**
   * Gets a page of all contacts of the logged-in user
   */
  public async listNetworkParticipants(
    startIndex?: number,
    sortType:
      | 'RECENTLY_ADDED'
      | 'FIRSTNAME_LASTNAME'
      | 'LASTNAME_FIRSTNAME' = 'RECENTLY_ADDED',
    options?: Options
  ): Promise<(LinkedInContact & { index: number })[]> {
    options = this.getOptions(options);
    const participants = await this.client.profileService.getOwnNetworkPage(
      startIndex,
      sortType,
      options
    );
    return Arrays.filterNull(
      Array.from(participants).map((value, index) => {
        const profile = value.connectedMemberResolutionResult;
        if (!profile) return null;

        return {
          publicIdentifier: profile.publicIdentifier,
          profileID: profile.entityUrn.split(':').pop() ?? '',
          firstName: profile.firstName,
          lastName: profile.lastName,
          profilePictureUrl: resolveProfilePictureUrls(
            profile.profilePicture?.displayImageReference?.vectorImage
          ),
          infoText: profile.headline,
          index: index + (startIndex ?? 0),
          isConnection: true
        };
      })
    );
  }

  /**
   * Gets an iterator for all users, that visited the logged-in user's profile
   */
  public async *getProfileVisitorsIterator(
    options?: Options
  ): AsyncGenerator<LinkedInAccount, null> {
    options = this.getOptions(options);
    const visitorsIterator =
      this.client.profileService.getProfileVisitors(options);

    for (;;) {
      const { value, done } = await visitorsIterator.next();
      if (done) break;
      yield this.getContact(value);
    }
    return null;
  }

  /**
   * Gets an iterator for all users, that visited the logged-in user's profile
   */
  public async listProfileVisitors(
    options?: Options
  ): Promise<LinkedInAccount[]> {
    options = this.getOptions(options);
    const visitors =
      await this.client.profileService.getProfileVisitorsPage(options);

    return visitors.map((v) => this.getContact(v));
  }

  /**
   * Sends a given message to a conversation with the given ID
   * @param conversationId ID of the conversation
   * @param text Text of the message
   * @param options Optional options used for controlling behavior of the client
   */
  public async sendMessage(
    conversationId: string,
    text: string,
    options?: Options
  ): Promise<Message | null> {
    options = this.getOptions(options);
    const msg = await this.client.messageService.sendMessage(
      conversationId,
      text,
      options
    );
    if (!msg) return null;
    const acc = this.self ?? (await this.getOwnProfile());
    if (!acc) return null;
    return parseLinkedInCreateEvent(msg, acc);
  }

  /**
   * Sends a test request to LinkedIn to check if the login is still valid
   * @param options Optional options used for controlling behavior of the client
   * @returns true if the login is still valid, false otherwise
   */
  public async checkLogin(options?: Options): Promise<boolean> {
    options = this.getOptions(options);
    return this.client.loginService.testLogin(options);
  }

  /**
   * Sends a connection request to a user with the given ID and a given message
   */
  public async sendConnectionRequest(
    profileID: string,
    message: string,
    options?: Options
  ): Promise<void> {
    options = this.getOptions(options);
    const profileUrn = new DashProfileUrn(profileID);
    await this.client.inviteService.sendInvitation(
      profileUrn,
      message,
      options
    );
  }

  /**
   * Sets archived status of a conversation
   * @param conversation_ids Conversations to set archived status of
   * @param archived whether to archive the conversation
   * @param options Optional options used for controlling behavior of the client
   * @returns `true` if the request was successful, `false` otherwise
   */
  public async setArchived(
    conversation_ids: ConversationId[],
    archived = true,
    options?: Options
  ): Promise<boolean> {
    options = this.getOptions(options);
    const profile = this.self ?? (await this.getOwnProfile());
    if (!profile) return false;
    return this.client.conversationService.setConversationCategory({
      conversationUrns: conversation_ids.map(
        (id) =>
          new MessageConversationUrn(
            `(${new DashProfileUrn(profile.profileID).getFullUrn()},${id})`
          )
      ),
      category: 'ARCHIVE',
      state: archived ? 'addCategory' : 'removeCategory',
      options
    });
  }

  /**
   * Parses a LinkedInConversation received from the LinkedIn API to a Conversation object
   * @param conversation LinkedInConversation to parse
   * @param options Optional options used for controlling behavior of the client used to fetch profile headlines
   * @private
   */
  public parseLinkedInConversation(
    conversation: LinkedInConversation
  ): Conversation {
    const lastMessages: Message[] = Arrays.filterNull(
      [...conversation.events].map((evt) => linkedInEventToMessage(evt))
    );

    return {
      conversationID: conversation.conversationId,
      participants: conversation.participants
        .filter((p) => p.miniProfile)
        .map(
          (participant) =>
            ({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- filtered above
              ...this.getContact(participant.miniProfile!)
            }) as ConversationContact
        ),
      archived: conversation.archived,
      lastActivityAt: new Date(conversation.lastActivityAt),
      read: conversation.read,
      unreadCount: conversation.unreadCount,
      lastMessages
    };
  }

  /**
   * Parses a LinkedInMiniProfile to a Contact.
   *
   * It also adds the infoText property to the Contact,
   * when it is accessed for the first time a request to the LinkedIn API is made.
   *
   * @param from LinkedInMiniProfile to convert from
   * @param options Optional options used for controlling behavior of the client
   *
   * @returns Contact parsed from the LinkedInMiniProfile
   */
  public parseLinkedInMiniProfile(
    from: LinkedInMiniProfile,
    options?: Options
  ): LinkedInContact {
    options = this.getOptions(options);
    const contact = {
      publicIdentifier: from.publicIdentifier,
      profileID: from.dashEntityUrn.split(':').pop() ?? '',
      firstName: from.firstName,
      lastName: from.lastName,
      profilePictureUrl: resolveProfilePictureUrls(from.picture)
    };

    Object.defineProperty(contact, 'infoText', {
      get: async () =>
        (
          await this.client.profileService.getFullProfile(
            from.publicIdentifier,
            options
          )
        )?.headline ?? ''
    });

    return contact as LinkedInContact;
  }

  /**
   * Converts a LinkedInMiniProfile to a Contact.
   * It also adds the infoText property to the Contact,
   * when it is accessed for the first time a request to the LinkedIn API is made.
   * @param from LinkedInMiniProfile to convert from
   * @param options Optional options used for controlling behavior of the client
   */
  private getContact(from: LinkedInMiniProfile): LinkedInAccount {
    const contact = {
      publicIdentifier: from.publicIdentifier,
      profileID: from.dashEntityUrn.split(':').pop() ?? '',
      firstName: from.firstName,
      lastName: from.lastName,
      profilePictureUrl: resolveProfilePictureUrls(from.picture)
    };

    return contact as LinkedInAccount;
  }

  /**
   * Get the profile of the logged-in user
   */
  public async getOwnProfile(
    options?: Options
  ): Promise<LinkedInAccount | null> {
    options = this.getOptions(options);
    const profile = await this.client.profileService.getMe(options);
    if (!profile) return null;
    const acc = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      publicIdentifier: profile.publicIdentifier,
      profileID: profile.entityUrn.split(':').pop() ?? '',
      profilePictureUrl: resolveProfilePictureUrls(profile.picture)
    };
    this.self = acc;
    return acc;
  }

  /**
   * Checks whether a given profile by public identifier is a connection of the logged-in user
   * When passing the profile instead of just the identifier, it only makes one request to the LinkedIn API
   * @param profile profile ID and name of the profile to check
   * @param options the options to use for the request
   * @returns true if the profile is a connection, false otherwise
   */
  public isConnection(
    profile:
      | string
      | Pick<LinkedInContact, 'firstName' | 'lastName' | 'profileID'>,
    options?: Options
  ): Promise<boolean>;
  public async isConnection(
    publicIdentifier:
      | string
      | Pick<LinkedInContact, 'firstName' | 'lastName' | 'profileID'>,
    options?: Options
  ): Promise<boolean> {
    options = this.getOptions(options);
    return this.client.profileService.isConnected(publicIdentifier, options);
  }

  /**
   * Set the read state of a conversation (default set read to true, but can also set unread)
   */
  public async markAsRead(
    conversationId: Conversation['conversationID'],
    read = true,
    options?: Options
  ) {
    options = this.getOptions(options);
    await this.client.conversationService.markConversationAsRead({
      conversationId,
      state: read,
      options
    });
  }

  /**
   * Sets the status for an emoji reaction on a message
   * **Note:** This function is currently quite expensive,
   * as it makes two requests to the LinkedIn API
   *
   * @param message ID of the message to react to
   * @param emoji emoji to react with
   * @param status status of the reaction
   * @param options Optional options used for controlling behavior of the client
   * @returns `true` if successful, `false` otherwise
   */
  public async setReaction(
    message: Message,
    emoji: string,
    status = true,
    options?: Options
  ): Promise<boolean> {
    options = this.getOptions(options);
    const user = await this.getOwnProfile(options);
    if (!user) return false;

    const urn = `(${new DashProfileUrn(user.profileID).getFullUrn()},${(() => {
      let id = message.messageID.split(',').pop() ?? '';
      if (id.endsWith(')')) id = id.slice(0, -1);
      return id;
    })()})`;

    return this.client.messageService.setEmojiReaction({
      messageUrn: new MessageMessageUrn(urn),
      emoji,
      status,
      options
    });
  }
  /**
   * Limit of LinkedIn on how many reactions can be added to a message
   */
  public static readonly MAX_REACTIONS_PER_MESSAGE = 20;

  /**
   * Gets the currently set headers for the LinkedInClient
   * If the headers are disabled, it returns the headers that would have been set.
   *
   * @param url the url to get the headers for (optional)
   *
   * @returns the headers
   */
  public getHeaders(url: string): { [header: string]: string } {
    return this.client.requestService.getHeaders(url);
  }

  /**
   * Logs out of LinkedIn
   */
  public async logout(options?: Options): Promise<void> {
    options = this.getOptions(options);
    await this.client.loginService.logout(options);
  }

  /**
   * Send an initial message to a profile
   */
  public async sendDirectMessage(
    profileID: ProfileId,
    message: string,
    options?: Options
  ) {
    options = this.getOptions(options);
    const res = await this.client.messageService.sendDirectMessage(
      new DashProfileUrn(profileID),
      message,
      options
    );

    if (!res) return null;

    const conversationID = res.conversationUrn.split(':').pop() ?? '';
    const sentMessage: Message = {
      attachments: [],
      createdAt: new Date(res.createdAt),
      deleted: false,
      messageID: res.eventUrn.split(':').pop() ?? '',
      sentFrom: this.self?.profileID ?? '',
      text: message,
      reactions: []
    };

    return {
      conversationID,
      message: sentMessage
    };
  }

  /**
   * Opens a realtime connection to the LinkedIn API
   * Make sure to close the connection when you are done using it
   * e.g. by logging out or calling `closeRealtimeConnection`
   */
  public openRealtimeConnection() {
    this.client.realtimeService.open();
  }

  /**
   * Tells the realtime service to close the connection
   * If no connection is open, it does nothing
   */
  public closeRealtimeConnection() {
    this.client.eventService.emit({
      type: Event.CLOSE
    });
  }

  /**
   * Adds a listener to the eventing engine
   */
  public addListener<T extends Event, U extends ApiEvent<T>>(
    event: T,
    callback: (event: U) => void
  ) {
    this.client.eventService.addListener<T, U>(event, callback);
  }
}

//------------------------------ TEMP ------------------------------

function linkedInEventToMessage(event: LinkedInEvent): Message | null {
  const audioAttachments: AudioAttachment[] =
    event.eventContent.mediaAttachments
      ?.filter((attachments) => attachments.audioMetadata)
      .map((attachment) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- filtered above
        url: attachment.audioMetadata!.url,
        type: 'audio'
      })) ?? [];
  const fileAttachments: (FileAttachment | ImageAttachment)[] =
    event.eventContent.attachments?.map((a) => ({
      type: a.mediaType.startsWith('image/') ? 'image' : 'file',
      url: a.reference
    })) ?? [];

  if (!event.from?.miniProfile) return null;

  return {
    messageID: event.eventId,
    text:
      (event.eventContent.body || event.eventContent.attributedBody?.text) ??
      '',
    sentFrom: event.from.miniProfile.publicIdentifier,
    createdAt: new Date(event.createdAt),
    deleted: !!event.eventContent.recalledAt,
    attachments: [...audioAttachments, ...fileAttachments],
    reactions: event.reactionSummaries.map((reaction) => ({
      emoji: reaction.emoji,
      count: reaction.count,
      viewerReacted: reaction.viewerReacted
    }))
  };
}
function resolveProfilePictureUrls(
  vectorImage: LinkedInVectorImage | undefined
): { [resoltion: number]: string } {
  if (!vectorImage) return {};
  return Object.fromEntries(
    vectorImage.artifacts.map((element) => [
      element.height,
      vectorImage.rootUrl + element.fileIdentifyingUrlPathSegment
    ])
  );
}
