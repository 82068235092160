import { CommentPosted } from "./events/CommentPosted.js";
import { ConnectionRequestSend } from "./events/ConnectionRequestSend.js";
import { FocusModeFinished } from "./events/FocusModeFinished.js";
import { FocusModeStart } from "./events/FocusModeStart.js";
import { MessageSend } from "./events/MessageSend.js";
import { OnboardingStepCompleted } from "./events/OnboardingStepCompleted.js";
import { ProfileCategorized } from "./events/ProfileCategorized.js";
import { ProfilesMarked } from "./events/ProfilesMarked.js";
export function convertToBinary(name, data) {
    switch (name) {
        case "CommentPosted":
            return CommentPosted.toBinary(data);
        case "ConnectionRequestSend":
            return ConnectionRequestSend.toBinary(data);
        case "FocusModeFinished":
            return FocusModeFinished.toBinary(data);
        case "FocusModeStart":
            return FocusModeStart.toBinary(data);
        case "MessageSend":
            return MessageSend.toBinary(data);
        case "OnboardingStepCompleted":
            return OnboardingStepCompleted.toBinary(data);
        case "ProfileCategorized":
            return ProfileCategorized.toBinary(data);
        case "ProfilesMarked":
            return ProfilesMarked.toBinary(data);
        default:
            throw new Error(`Unknown event name: ${name}`);
    }
}
export function convertFromBinary(name, data) {
    switch (name) {
        case "CommentPosted":
            return CommentPosted.fromBinary(data);
        case "ConnectionRequestSend":
            return ConnectionRequestSend.fromBinary(data);
        case "FocusModeFinished":
            return FocusModeFinished.fromBinary(data);
        case "FocusModeStart":
            return FocusModeStart.fromBinary(data);
        case "MessageSend":
            return MessageSend.fromBinary(data);
        case "OnboardingStepCompleted":
            return OnboardingStepCompleted.fromBinary(data);
        case "ProfileCategorized":
            return ProfileCategorized.fromBinary(data);
        case "ProfilesMarked":
            return ProfilesMarked.fromBinary(data);
        default:
            throw new Error(`Unknown event name: ${name}`);
    }
}
