import React from 'react';
import {
  EventHandler,
  EventName,
  EventPayload,
  MessagePersistence
} from './MessageBusUtilTypes';
import AbstractMessageBus from './AbstractMessageBus';
import MainConfig from '@common/config/MainConfig';
import { encodeToBinary } from 'message-bus-types';
import { SendData } from '@common/types/ipc';

type Event<Name extends EventName = EventName> = {
  eventName: Name;
  payload: EventPayload<Name>;
  id: string;
};

export default class MessageBus extends AbstractMessageBus {
  private static instance?: MessageBus;
  private ws: WebSocket | null = null;
  private eventSaveQueue: Event[] = [];
  private eventsInFlight: {
    evt: Event;
    timestamp: Date;
  }[] = [];
  private authToken: string | null = null;

  constructor() {
    super();
    if (!window.location.hash.startsWith('#debugger')) {
      console.debug('MessageBus: Trying to connect to ws');
    } else {
      console.log('MessageBus: Debug mode');
    }

    this.on('auth-renderer:logged-in', ({ sessionToken }) => {
      console.debug("MessageBus: Renderer is ready, let's connect to ws");
      this.connectToWs(sessionToken);
      setInterval(() => {
        const eventsInFlight: typeof this.eventsInFlight = [];
        for (const event of this.eventsInFlight) {
          if (new Date().getTime() - event.timestamp.getTime() > 10_000) {
            this.eventSaveQueue.push(event.evt);
          } else {
            eventsInFlight.push(event);
          }
        }
        this.eventsInFlight = eventsInFlight;
      }, 60_000);
    });
    // if on renderer thread, listen to ipc messages from main and push them to local listeners
    window.api.handle('messagebus:emit', (_, event) => {
      let id = event.id;
      if (!id) id = this.generateMessageID();
      this.pushEventToListeners({
        eventName: event.name,
        payload: event.payload as unknown as EventHandler<EventName>,
        id,
        persistence: event.persistence
      });
    });
  }

  private connectToWs(token: string, retryCount = 0) {
    if (this.ws !== null) {
      this.authToken = token;
      return;
    }
    const ws = new WebSocket(MainConfig.messageBusUrl + '?token=' + token);

    ws.onopen = () => {
      console.debug('[MessageBus] Socket is open');
    };

    ws.onclose = (e) => {
      this.ws = null;
      console.debug('Socket is closed. Reconnect will be attempted.', e.reason);
      setTimeout(
        () => {
          this.connectToWs(this.authToken ?? token, retryCount + 1);
        },
        Math.pow(2, retryCount) * 100
      );
    };

    ws.onmessage = (msg) => {
      if (msg.data instanceof ArrayBuffer) {
        return;
      } else {
        if (msg.data === 'READY') {
          this.ws = ws;
          console.debug('[MessageBus] Socket is ready');
          return;
        }
        this.eventsInFlight = this.eventsInFlight.filter(
          (evt) => evt.evt.id !== msg.data
        );
      }
    };

    ws.onerror = (err) => {
      console.error('Socket encountered error: ', err, 'Closing socket');
      this.ws = null;
      ws.close();
    };
  }

  public static getInstance(): MessageBus {
    if (!MessageBus.instance) MessageBus.instance = new MessageBus();
    return MessageBus.instance;
  }

  protected relayMessages<Name extends EventName>(
    eventName: Name,
    payload: EventPayload<Name>,
    persistence: MessagePersistence,
    id: string
  ): void {
    // if on renderer thread, send message over ipc to main
    window.api.send('messagebus:emit', {
      name: eventName,
      payload,
      id,
      persistence
    } as SendData<'messagebus:emit'>[0]);

    if (persistence === MessagePersistence.PERSISTENT) {
      this.eventSaveQueue.push({ eventName, payload, id });
      this.persistEvents();
    }
  }

  public static use<Name extends EventName>(
    channel: Name,
    handler: EventHandler<Name>
  ) {
    React.useEffect(() => {
      const messageBus = MessageBus.getInstance();
      messageBus.on(channel, handler);
      return () => {
        messageBus.removeHandler(channel, handler);
      };
    }, []);
  }
  public use<Name extends EventName>(
    channel: Name,
    handler: EventHandler<Name>
  ) {
    MessageBus.use(channel, handler);
  }

  private persistEvents() {
    const evt = this.eventSaveQueue.pop();
    if (evt === undefined) {
      return;
    }

    /* eslint-disable  */
    const binary = encodeToBinary({
      name: evt.eventName as any,
      payload: evt.payload as any,
      id: evt.id
    });
    /* eslint-enable  */
    if (this.ws === null) {
      setTimeout(() => {
        this.persistEvents();
      }, 1000);
      this.eventSaveQueue.unshift(evt);
      return;
    }

    try {
      this.ws.send(binary);
      this.eventsInFlight.push({
        evt,
        timestamp: new Date()
      });
    } catch (error) {
      console.error('Failed to send event to message bus', error);
      this.eventSaveQueue.push(evt);
    }

    setImmediate(() => {
      this.persistEvents();
    });
  }
}
