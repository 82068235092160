import { CONTENT_SCRIPT_CSS } from '@/WebviewStyles';
import {
  ContentScriptEvent,
  LinkedInWebviewElement
} from '@common/Webview.renderer/Base/ContentScriptTypes';
import { WebviewInjectCode } from '@common/Webview.renderer/InjectCode';
import { WebviewMessageListener } from '@common/Webview.renderer/Base/LinkedInWebview';
import WebviewStorage from '@common/Webview.renderer/Base/WebviewStorage';
import { usePreloadPath } from '@/data/Misc/PreloadPath';
import { RenderProcessGoneDetails } from 'electron';
import React from 'react';
import LinkedInActions from '../LinkedInActions';
import LinkedInLoadingManager from '@common/LoadingManager/LinkedInLoadingManager.renderer';
import Auth from '@common/AuthManager/Auth.renderer';
import { chats } from '@digital-sun-solutions/cloud-functions';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import WebviewLinkedIn, { InternalWebviewLinkedIn } from '../WebviewLinkedIn';
import { CSEventData, CSEventType, CSSaveData } from 'webview-preload';
import log from 'electron-log';
import posthog from 'posthog-js';

export interface WebviewMessage {
  channel: string;
  args: any;
  timestamp: string;
}

const MAX_SAVED_MESSAGES = 200;
const webviewMessages: WebviewMessage[] = [];

window.webview = {
  get(prefix?: string) {
    if (!prefix) return webviewMessages;
    return webviewMessages.filter((m) => m.channel.startsWith(prefix));
  }
};

export const useWebview = ({
  ipcMessageListener,
  webAuthEnabled,
  link,
  background
}: {
  ipcMessageListener: WebviewMessageListener;
  webAuthEnabled: boolean;
  link: string;
  background?: boolean;
}) => {
  // state that stores the DOM element of the webview
  const [webview, setWebview] = React.useState<LinkedInWebviewElement | null>(
    null
  );
  const [webviewReady, setWebviewReady] = React.useState(false);

  // reference to the webview DOM element
  const webviewRef = React.useRef(webview);
  webviewRef.current = webview;

  const webviewSavedData = React.useRef<CSSaveData>({});

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      // save webview for later use when unmounting
      WebviewStorage.save(webviewRef.current);
      LinkedInActions.internal.unregisterLatestWebview(webviewRef.current);

      if (webviewReady) {
        if (!webviewRef.current) return;
        webviewRef.current.send('ssc:listeners-unmounting', undefined);

        // open devtools of non existent webviews crash application on interaction
        webviewRef.current.closeDevTools();
        console.debug(`[WebviewHook][${webviewRef.current.id}] DOM Unmount`);
        webviewRef.current.domReady = false;
      }
    };
  }, []);
  // get path of preload script from main process
  const { data: preloadPath } = usePreloadPath();

  React.useEffect(() => {
    if (!preloadPath || !webview) return;

    function domReadyListener() {
      setWebviewReady(true);
      webview?.insertCSS(CONTENT_SCRIPT_CSS);
      webview?.send('ssc:listeners-mounted', undefined);
      webview?.executeJavaScript(WebviewInjectCode);
      if (webview) {
        console.debug(`[WebviewHook][${webview.id}] DOM Ready`);
        webview.domReady = true;
      }
    }

    async function _ipcMessageListener<Event extends CSEventType>(
      e: ContentScriptEvent<Event>
    ) {
      const data = e.args[0];
      const channel = e.channel;
      const now = new Date();
      webviewMessages.push({
        channel,
        args: e.args[0],
        timestamp: `${now.getHours().toString().padStart(2, '0')}:${now
          .getMinutes()
          .toString()
          .padStart(2, '0')}:${now
          .getSeconds()
          .toString()
          .padStart(2, '0')}:${now
          .getMilliseconds()
          .toString()
          .padStart(3, '0')}`
      });
      if (webviewMessages.length > MAX_SAVED_MESSAGES) webviewMessages.shift();
      switch (channel) {
        case 'webview:crashed':
          log.error('Webview crashed - logging out of linkedin');
          posthog.capture('webview_crashed', {
            link,
            message: data
          });
          MessageBus.getInstance().emit('webview:crashed', undefined);
          break;
        case 'ssc:ping':
          webview?.send('ssc:pong', 'OK');
          break;
        case 'ssc:save-data':
          const saveData = data as CSEventData<'ssc:save-data'>;
          webviewSavedData.current = {
            ...webviewSavedData.current,
            ...saveData
          };
          break;
        case 'ssc:get-data':
          webview?.send('ssc:data', webviewSavedData.current);
          break;
        case 'linkedin:login-status':
          const isLoggedIn = data as CSEventData<'linkedin:login-status'>;
          window.api.send('linkedin:login-status', isLoggedIn);
          break;
        case 'linkedin:filter-known-messages':
          const messages =
            data as CSEventData<'linkedin:filter-known-messages'>;
          const knownMessages = await Auth.execRoute((token) =>
            chats.areMessagesKnown({ messageIDs: messages }, { token })
          );
          if (knownMessages.code === 200)
            webview?.send(
              'linkedin:filtered-known-messages',
              knownMessages.data
            );
          else {
            log.error('Failed to check if messages are known', knownMessages);
            webview?.send('linkedin:filtered-known-messages', []);
          }
          break;
        case 'linkedin:is-conversation-interesting':
          const conversationID =
            data as CSEventData<'linkedin:is-conversation-interesting'>;
          await LinkedInLoadingManager.waitForInit();
          const isConversationInteresting =
            LinkedInLoadingManager.filterInterestingConversations([
              { conversationID }
            ]).length > 0;
          webview?.send('linkedin:is-conversation-interesting', {
            interesting: isConversationInteresting,
            conversationID
          });
          break;
        case 'linkedin:mark-chats-as-unread':
          const conversationIDs =
            data as CSEventData<'linkedin:mark-chats-as-unread'>;
          for (const conversationID of conversationIDs) {
            await WebviewLinkedIn.markAsRead(conversationID, false);
            await Auth.execRoute((token) =>
              chats.setState(
                {
                  conversationID,
                  unread: 1
                },
                { token }
              )
            );
          }
          break;
        case 'linkedin:filter-known-profiles':
          const profileIDs =
            data as CSEventData<'linkedin:filter-known-profiles'>;
          const filtered =
            LinkedInLoadingManager.filterInterestingProfiles(profileIDs);
          webview?.send('linkedin:known-profiles', filtered);
          break;
        case 'ssc:checking-new-messages':
          const isCheckingMessages =
            data as CSEventData<'ssc:checking-new-messages'>;
          MessageBus.getInstance().emit('checking-messages', {
            state: isCheckingMessages ? 'start' : 'stop'
          });
          break;
        case 'linkedin:api':
          InternalWebviewLinkedIn.__resolveOpenCall__(
            ...(data as CSEventData<'linkedin:api'>)
          );
          break;
      }
      ipcMessageListener(channel, data, webview);
    }

    function crashListener(details: RenderProcessGoneDetails) {
      log.error('Webview crashed', details);
    }

    webview.addEventListener('dom-ready', domReadyListener);
    webview.addEventListener('ipc-message', _ipcMessageListener as any);
    webview.addEventListener('render-process-gone', crashListener as any);

    if (webviewReady) webview.send('ssc:listeners-mounted', undefined);

    return () => {
      webview?.removeEventListener('ipc-message', _ipcMessageListener as any);
      webview?.removeEventListener('dom-ready', domReadyListener);
      webview?.removeEventListener('render-process-gone', crashListener as any);
    };
  }, [preloadPath, webview, ipcMessageListener]);

  React.useEffect(() => {
    if (!preloadPath || webview || webviewRef.current) return;
    const { webview: newWebview } = WebviewStorage.get({
      link,
      preload: preloadPath,
      disabledWebAuth: !webAuthEnabled
    });
    webviewRef.current = newWebview;
    setWebviewReady(false);
    setWebview(newWebview);
  }, [preloadPath, webview]);

  React.useEffect(() => {
    if (!preloadPath || !webview) return;
    const { webview: newWebview } = WebviewStorage.get({
      link,
      preload: preloadPath,
      disabledWebAuth: !webAuthEnabled
    });
    setWebview(newWebview);
    setWebviewReady(false);
  }, [webAuthEnabled]);

  const [webviewOvertaken, setWebviewOvertaken] = React.useState(false);
  React.useEffect(() => {
    if (!webview || background) return;
    LinkedInActions.internal.registerLatestWebview({
      webview,
      onOvertake: () => setWebviewOvertaken(true),
      onRelease: async (link: string) => {
        if (webview.getURL() !== link) await webview?.loadURL(link);
        setWebviewOvertaken(false);
      }
    });
  }, [webview]);

  window[background ? 'openBackgroundDevTools' : 'openDevTools'] = () => {
    if (!webview) return;
    // expose message dev function on window object in devtools
    webview.send('ssc:expose', undefined);
    webview.openDevTools();
  };

  return { webview, webviewReady, webviewOvertaken };
};
