import { Route, Routes } from 'react-router-dom';
import LinkedInLogin from './LinkedInLogin';
import LinkedInLogout from './LinkedInLogout';
import SSCLogin from './SSCLogin/SSCLogin';
import { LOGIN_PATHS } from '@/Paths';

export default function Login() {
  return (
    <Routes>
      <Route path={`${LOGIN_PATHS.SSC}/*`} element={<SSCLogin />} />
      <Route path={`${LOGIN_PATHS.LinkedIn}/*`} element={<LinkedInLogin />} />
      <Route
        path={`${LOGIN_PATHS.LinkedInLogout}/*`}
        element={<LinkedInLogout />}
      />
    </Routes>
  );
}
