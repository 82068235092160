import { Node } from '@tiptap/core';

import { ReactNodeViewRenderer } from '@tiptap/react';
import VariableBlockView from './VariableBlockView';
import { language } from '../../../../Config';

export type VariableTypes = 'fullname' | 'lastname' | 'firstname';

export const VARIABLE_TYPES: VariableTypes[] = [
  'fullname',
  'lastname',
  'firstname'
];

export function getVariableName(varType: VariableTypes): string {
  switch (varType) {
    case 'fullname':
      return language.text.fullname;
    case 'lastname':
      return language.text.lastname;
    case 'firstname':
      return language.text.firstname;
  }
}

export interface VariableBlockOptions {}

interface VariableBlockStorage {}

export interface VariableBlockAttributes {
  id: VariableTypes;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    variable: {
      /**
       * Insert a variable block
       */
      setVariable: (options: VariableBlockAttributes) => ReturnType;
    };
  }
}

export const VariableBlock = Node.create<
  VariableBlockOptions,
  VariableBlockStorage
>({
  name: 'ssc-variable',
  atom: true,
  selectable: true,

  inline() {
    return true;
  },

  group() {
    return 'inline';
  },

  draggable: true,

  addAttributes() {
    return {
      id: {
        default: 'firstname'
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'ssc-variable',
        getAttrs: (dom: HTMLElement | string) => ({
          id: typeof dom === 'string' ? dom : dom.textContent
        })
      }
    ];
  },

  addCommands() {
    return {
      setVariable:
        (options: VariableBlockAttributes) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options
          });
        }
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(VariableBlockView);
  },

  renderHTML({ node }) {
    return ['ssc-variable', {}, `${node.attrs.id}`];
  }
});
