import { TaskBuilder, TaskOptionsConfig } from 'focus-mode-scheduler';
import ChatTutorialTask from './ChatTutorialTask';
import { NextTask } from 'focus-mode-scheduler/src/Task/TaskBuilder';
import { ChatTutorial } from '@common/config/Tutorials.renderer';
import SetupActions from '@/data/DataServer/Setup';
import { TutorialContact } from '@/data/Classes/TutorialContact';
import { MessageTemplate } from '@common/types/ApiTypes';
import TemplateActions from '@/data/DataServer/Templates';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { BindProps } from 'ui-utils';
import ChatTutorialTaskUI from './ChatTutorialTaskUI';
import AccountActions from '@/data/DataServer/Account';

export default class ChatTutorialTaskBuilder extends TaskBuilder {
  protected task = ChatTutorialTask;

  protected remainingChatTutorials: ChatTutorial[] | null = null;
  protected async loadChatTutorials() {
    if (this.remainingChatTutorials) return;
    this.remainingChatTutorials =
      await SetupActions.getRemainingChatTutorials();
  }

  async getTask(): Promise<NextTask<ChatTutorialTask>> {
    await this.loadChatTutorials();

    const self = await AccountActions.getUserData();
    if (!self) {
      console.error('[ChatTutorialTaskBuilder] Could not get self -> skipping');
      return null;
    }

    const chatTutorial = this.remainingChatTutorials?.shift();
    if (!chatTutorial) return null;
    const contact = new TutorialContact(chatTutorial.profile);

    let templates: MessageTemplate[] | null = null;
    if (chatTutorial.initial?.templates) {
      templates = chatTutorial.initial.templates.map((t, i) => ({
        ...t,
        variantID: i + 1,
        templateID: chatTutorial.id
      }));
    } else {
      templates = await TemplateActions.getBuildRelationTemplates();
    }
    const template = templates?.[0] ?? null;
    const chat = await contact.getChat(template);

    const steps = Pipelines.getCategorizationSteps();

    const activeStep = chatTutorial.initial?.stepID
      ? steps.find((s) => s.id === chatTutorial.initial?.stepID) ?? steps[0]
      : steps[0];

    const task = new ChatTutorialTask(
      contact,
      chat,
      template,
      templates,
      steps,
      activeStep,
      chatTutorial,
      self
    );

    return {
      task,
      UI: BindProps(ChatTutorialTaskUI, { task })
    };
  }

  public getOptionsConfig(): Promise<TaskOptionsConfig | null> {
    return Promise.resolve(null);
  }
}
