import React from 'react';
import { Box } from '@mui/material';

export interface HUDTopProps {
  topContent?: React.ReactNode;
}

const MAIN_SIDE_WIDTH = 60;
const MAIN_HEIGHT = 96;

export default function HUDTop(props: HUDTopProps) {
  const { topContent } = props;

  return (
    <Box
      sx={{
        zIndex: 11,
        position: 'fixed',
        top: 0,
        width: '100%',
        height: MAIN_HEIGHT + 'px',
        background: 'transparent'
      }}>
      <Box
        sx={{
          'position': 'relative',
          'width': `calc(95% - ${MAIN_SIDE_WIDTH * 2}px)`,
          'height': '100%',
          'margin': 'auto',
          'background': (theme) => theme.palette.hud.main,
          'borderBottomLeftRadius': (theme) => theme.spacing(4),
          'borderBottomRightRadius': (theme) => theme.spacing(4),
          '&:after': {
            display: 'block',
            content: '" "',
            width: 0,
            height: 0,
            borderBottomColor: (theme) => theme.palette.hud.main,
            borderRightColor: (theme) => theme.palette.hud.main,
            borderLeftColor: 'transparent',
            borderTopColor: 'transparent',
            borderStyle: 'solid',
            borderBottomWidth: MAIN_SIDE_WIDTH + 'px',
            borderRightWidth: MAIN_SIDE_WIDTH + 'px',
            borderLeftWidth: MAIN_SIDE_WIDTH + 'px',
            borderTopWidth: MAIN_SIDE_WIDTH + 'px',
            position: 'absolute',
            transform: `rotate(${180}deg)`,
            right: '-112px',
            top: `${-34.5}px`
          },
          '&:before': {
            display: 'block',
            content: '" "',
            width: 0,
            height: 0,
            borderLeftColor: (theme) => theme.palette.hud.main,
            borderTopColor: (theme) => theme.palette.hud.main,
            borderBottomColor: 'transparent',
            borderRightColor: 'transparent',
            borderStyle: 'solid',
            borderBottomWidth: MAIN_SIDE_WIDTH + 'px',
            borderRightWidth: MAIN_SIDE_WIDTH + 'px',
            borderLeftWidth: MAIN_SIDE_WIDTH + 'px',
            borderTopWidth: MAIN_SIDE_WIDTH + 'px',
            position: 'absolute',
            transform: 'rotate(90deg)',
            left: '-112px',
            top: '-34.5px'
          }
        }}>
        {topContent}
      </Box>
    </Box>
  );
}
