import AppActions from '@/data/DataServer/App';
import { language } from '@/index';
import { Dates } from '@/other/Dates';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { FancyButton, useRefreshed } from 'ui-utils';

export default function AskToRestart() {
  const startTime = useRefreshed(
    () => AppActions.getStartTime(),
    2 * 60 * 1000
  );

  const [shouldAskToRestart, setShouldAskToRestart] = React.useState(false);

  React.useEffect(() => {
    setShouldAskToRestart((current) => current || !Dates.onSameDay(startTime));
  }, [startTime]);

  return (
    <Dialog open={shouldAskToRestart}>
      <DialogTitle>{language.text.restart_ssc}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {language.text.periodic_restart_ssc_explanation}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setShouldAskToRestart(false)}
          color="primary"
          variant="outlined">
          {language.text.remind_in_2_min}
        </Button>
        <FancyButton
          variant="contained"
          color="primary"
          onClick={() => window.api.send('app:restart')}>
          {language.text.restart}
        </FancyButton>
      </DialogActions>
    </Dialog>
  );
}
