import React from 'react';
import { Editor } from '@tiptap/react';
import {
  ChatContextType,
  ChatInfoVariant,
  useChatContext
} from '@/Routes/ManualMode/Chat/ChatContext';
import { Box, Divider, Popover, Tooltip, Typography } from '@mui/material';
import { Message } from '@common/types/ApiTypes';
import { language } from '@/index';
import log from 'electron-log';
import { FancyButton, TextEditor } from 'ui-utils';
import { AbstractChat } from '@/data/Classes/Chat/AbstractChat';

export interface ChatInfo {
  info: string;
  variant: ChatInfoVariant;
}

export type ChatTextFieldProps = {
  afterSend?: (message: Omit<Message, 'id' | 'userid'>) => void;
  autofocus?: boolean;
  defaultMessage?: string;
  disabled?: boolean;
  disabledMessage?: string;
  getEditor?: (editor: Editor) => void;
  info?: ChatInfo | null;
} & (
  | {
      chat: AbstractChat;
      onSend?: (text: string) => Promise<boolean>;
    }
  | {
      onSend: (text: string) => Promise<boolean>;
      chat?: AbstractChat;
    }
);

export default function ChatTextField(props: ChatTextFieldProps) {
  const { registerChatMessageBox } =
    (useChatContext() as ChatContextType | undefined) ?? {};

  const [draft, setDraft] = React.useState<string>(
    props.disabled ? props.disabledMessage ?? '' : props.defaultMessage ?? ''
  );

  const [value, setValue] = React.useState<string>('');
  const [editor, setEditor] = React.useState<Editor | null>(null);
  const userEdited = React.useRef<boolean>(false);

  const [infos, setInfos] = React.useState<ChatInfo[]>([]);

  const [
    replaceBlocksBeforeSendWarningOpen,
    setReplaceBlocksBeforeSendWarningOpen
  ] = React.useState<boolean>(false);
  const [
    replaceBlocksBeforeSendWarningLoading,
    setReplaceBlocksBeforeSendWarningLoading
  ] = React.useState<boolean>(false);

  const editorRef = React.useRef<HTMLDivElement | null>(null);

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // load draft from local storage
  // this useEffect must be before the useEffect that saves the draft to not overwrite draft before it is loaded
  React.useEffect(() => {
    if (!editor || !!props.defaultMessage || !props.chat?.conversationID)
      return;
    const draft = localStorage.getItem(
      `chat-message-${props.chat.conversationID}`
    );
    if (draft) {
      setDraft(draft);
    }
  }, [editor]);
  // save current message to local storage (save draft when user leaves chat)
  React.useEffect(() => {
    // must wait for editor to be set (since loading draft is also waiting for editor to be loaded)
    if (!editor || !props.chat?.conversationID) return;
    if (value.length > 0)
      localStorage.setItem(`chat-message-${props.chat.conversationID}`, value);
    else localStorage.removeItem(`chat-message-${props.chat.conversationID}`);
  }, [value, editor]);

  // register message box to be able to set the content of the message box from outside
  React.useEffect(() => {
    registerChatMessageBox?.({
      setContent: (
        content: string,
        options?: { soft?: boolean; preventEditedReset?: boolean }
      ) => {
        if (!editor) return;
        // don't set content if soft is true and content is already set
        if (options?.soft && userEdited.current) {
          return;
        }
        if (!options?.preventEditedReset) {
          userEdited.current = false;
        }

        // TODO: content can have html tags -> potential security issue
        let parsedContent = content.replace(/\n/g, '</p><p>');
        parsedContent = `<p>${parsedContent}</p>`;
        editor.commands.setContent(parsedContent, true);
        editor.commands.setTextSelection({ from: 0, to: 0 });
        editor.commands.scrollIntoView();
        setValue(content);
      },
      focus: () => {
        if (!editor) return;
        editor.commands.focus();
      },
      displayInfo: (info: string, variant: ChatInfoVariant = 'info') => {
        setInfos((infos) => [...infos, { info, variant }]);
        setTimeout(() => {
          if (!mounted.current) return;
          setInfos((infos) => infos.slice(1));
        }, 1000 * 5);
      }
    });
  }, [editor]);

  async function send(text: string) {
    // clear draft from local storage
    if (props.chat)
      localStorage.removeItem(`chat-message-${props.chat.conversationID}`);
    if (props.onSend) {
      const result = await props.onSend(text);
      return result;
    } else {
      if (!props.chat?.conversationID) return false;
      const message = await props.chat.sendMessage(text);
      props.afterSend?.(message);
      return true;
    }
  }

  return (
    <>
      {(props.info || infos[0]) && (
        <>
          <Divider />
          <Box p={(theme) => theme.spacing(1, 2.5)}>
            <Typography
              color={(() => {
                switch ((props.info ?? infos[0]).variant) {
                  case 'warning':
                    return (theme) => theme.palette.warning.main;
                  case 'error':
                    return (theme) => theme.palette.error.main;
                  case 'success':
                    return (theme) => theme.palette.success.main;
                  case 'info':
                    return 'initial';
                }
              })()}>
              {(props.info ?? infos[0]).info}
            </Typography>
          </Box>
        </>
      )}
      <TextEditor
        maxHeight="15vh"
        ref={editorRef}
        sx={{
          opacity: props.disabled ? 0.5 : 1,
          fontSize: (theme) => theme.typography.body2.fontSize
        }}
        disabled={props.disabled}
        autofocus={props.autofocus}
        margin="noTop"
        value={draft}
        onChange={({ text }) => {
          userEdited.current = true;
          if (value !== text) setValue(text);
        }}
        getEditor={(editor) => {
          setEditor(editor);
          props.getEditor?.(editor);
        }}
        showEmojiInput
        // TODO: enable once file messages are implemented
        // showFileInput
        // showVoiceInput
        showSendButton
        sendOnEnter={'ctrl'}
        onSubmit={async ({ text }) => {
          if (
            text.includes('</ssc-variable>') ||
            text.includes('</ssc-placeholder>')
          ) {
            setReplaceBlocksBeforeSendWarningOpen(true);
            return false;
          }
          return send(text);
        }}
        sendButton={({ disabled, onClick, icon }) => (
          <FancyButton
            fType={{
              pulsating: 'default',
              promise: true
            }}
            onClick={onClick}
            endIcon={icon}
            disabled={disabled}
            variant="text"
            sx={{
              background: (theme) =>
                `${theme.palette.background.default} !important` /* override hover style */
            }}>
            {language.text.send}
          </FancyButton>
        )}
      />
      {/* warning that not replaces variables are still in text */}
      <Popover
        open={replaceBlocksBeforeSendWarningOpen}
        anchorEl={editorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <Box p={2}>
          <Typography variant="body1">
            {language.text.not_all_variables_replaced_in_message}
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end" gap={2}>
            <Tooltip title={language.text.on_send_placeholders_are_removed}>
              <FancyButton
                fType={{
                  promise: true
                }}
                color="error"
                variant="outlined"
                onClick={async () => {
                  const text = value
                    .replace(
                      /<ssc-variable>[a-zA-Z0-9_\-]*<\/ssc-variable>/gi,
                      ''
                    )
                    .replace(/<ssc-placeholder>.*<\/ssc-placeholder>/gi, '');
                  try {
                    setReplaceBlocksBeforeSendWarningLoading(true);
                    const clear = await send(text);
                    if (clear) editor?.commands.setContent('');
                  } catch (e) {
                    log.error(
                      'Error while sending message with unreplaced tiptap nodes',
                      { rawText: value, replacedText: text },
                      e
                    );
                    throw e;
                  } finally {
                    setReplaceBlocksBeforeSendWarningOpen(false);
                    setReplaceBlocksBeforeSendWarningLoading(false);
                  }
                }}>
                {language.text.confirm_send_with_variables}
              </FancyButton>
            </Tooltip>
            <FancyButton
              disabled={replaceBlocksBeforeSendWarningLoading}
              variant="contained"
              color="primary"
              onClick={() => setReplaceBlocksBeforeSendWarningOpen(false)}>
              {language.text.ok}
            </FancyButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
}
