import {
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  MenuProps
} from '@mui/material';
import { Check } from '@mui/icons-material';
import ContactActions from '@/data/DataServer/Contact';
import { language } from '@/index';
import { Contact } from '@/data/Classes/Contact';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_PATHS, MANUAL_MODE_PATHS, SETTINGS_PATHS } from '@/Paths';

export interface AddTagMenuProps extends Omit<MenuProps, 'onChange'> {
  contact: Pick<Contact, 'tags' | 'addTag' | 'removeTag'> | undefined | null;
  onChange?: () => void;
  onBeforeLeave?: (path: string) => boolean;
}

export default function TagMenu(props: AddTagMenuProps) {
  const { contact, ...menuProps } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { tags, isLoading, error } = ContactActions.useTags();

  const [tagsLoading, setTagsLoading] = React.useState<string[]>([]);

  return (
    <Menu {...menuProps} onClick={(e) => e.stopPropagation()}>
      <MenuList>
        {isLoading ? (
          <MenuItem>
            <em>{language.text.loading}...</em>
          </MenuItem>
        ) : error ? (
          <MenuItem>
            <em>{language.text.error}</em>
          </MenuItem>
        ) : (
          [
            ...(tags.length
              ? tags.map((tag) => {
                  const selected = contact?.tags.some(
                    (cTag) => cTag.name === tag.name
                  );
                  return (
                    <MenuItem
                      disabled={tagsLoading.includes(tag.name)}
                      key={tag.name}
                      onClick={() => {
                        (async () => {
                          try {
                            setTagsLoading((prev) => [...prev, tag.name]);
                            if (selected) {
                              await props.contact?.removeTag(tag.name);
                            } else {
                              await props.contact?.addTag({
                                name: tag.name,
                                color: tag.color
                              });
                            }
                            props.onChange?.();
                          } finally {
                            setTagsLoading((prev) =>
                              prev.filter((t) => t !== tag.name)
                            );
                          }
                        })();
                      }}>
                      {tagsLoading.includes(tag.name) ? (
                        <>
                          <ListItemIcon>
                            <CircularProgress size={24} />
                          </ListItemIcon>
                          {tag.name}
                        </>
                      ) : selected ? (
                        <>
                          <ListItemIcon>
                            <Check />
                          </ListItemIcon>
                          {tag.name}
                        </>
                      ) : (
                        <ListItemText inset>{tag.name}</ListItemText>
                      )}
                    </MenuItem>
                  );
                })
              : [
                  <MenuItem key="empty" disabled>
                    <em>{language.text.no_tags}</em>
                  </MenuItem>
                ]),
            ...(pathname.startsWith(`/${APP_PATHS.Manual}`)
              ? [
                  <Divider key="pre-manage-divider" />,
                  <MenuItem
                    key="manage-tags"
                    onClick={() => {
                      const path = `/${APP_PATHS.Manual}/${MANUAL_MODE_PATHS.Settings}/${SETTINGS_PATHS.Tags}`;
                      if (props.onBeforeLeave?.(path) ?? true) navigate(path);
                    }}>
                    {language.text.manage_tags}
                  </MenuItem>
                ]
              : [])
          ]
        )}
      </MenuList>
    </Menu>
  );
}
