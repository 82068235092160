import React from 'react';
import LinkedInWebview from './Base/LinkedInWebview';
import LinkedInActions from './LinkedInActions';
import { LinkedInWebviewElement } from './Base/ContentScriptTypes';
import { Box, Typography } from '@mui/material';
import { wait } from '@idot-digital/generic-helpers';
import { useLocation } from 'react-router-dom';
import { APP_PATHS, LOGIN_PATHS } from '@/Paths';

// in ms
const TIMEOUT_FOR_WEBVIEW_MOUNT = 10_000;

/**
 * Mounts a webview if someone wants to overtake a webview but none are currently mounted
 */
export default function MountWebview(props: { show?: boolean }) {
  const { pathname } = useLocation();

  const hideOnPath =
    pathname.startsWith(`/${APP_PATHS.Login}/${LOGIN_PATHS.LinkedIn}`) ||
    pathname.startsWith(`/${APP_PATHS.Updater}`);

  const linkedinLoaded = React.useRef(false);
  const webviewReady = React.useRef(false);

  const webview = React.useRef<LinkedInWebviewElement | null>(null);

  React.useEffect(() => {
    LinkedInActions.internal.registerMountWebview(
      async () => {
        for (let count = 0; count < TIMEOUT_FOR_WEBVIEW_MOUNT / 100; count++) {
          if (
            webview.current &&
            webview.current.isConnected &&
            linkedinLoaded.current &&
            webviewReady.current
          )
            break;

          await wait(100);
        }
        if (!webview.current) throw new Error('Timeout while mounting webview');
        return webview.current;
      },
      () => undefined
    );
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: props.show ? 10000 : -100,
        pointerEvents: props.show ? 'all' : 'none',
        opacity: props.show ? 1 : 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      tabIndex={-1}
      className="backgroundWebview">
      {!hideOnPath ? (
        <LinkedInWebview
          ref={webview}
          hideReloadButton
          onLinkedInLoaded={() => (linkedinLoaded.current = true)}
          onWebviewReady={() => (webviewReady.current = true)}
          features={[]}
        />
      ) : (
        <Box
          sx={{
            p: 2,
            borderRadius: 1,
            boxShadow: 1,
            background: (theme) => theme.palette.background.default
          }}>
          <Typography variant="h4">No webview mounted</Typography>
        </Box>
      )}
    </Box>
  );
}
