import { language } from '@/index';
import { Task, TaskConfig } from 'focus-mode-scheduler';
import { TaskIncomingEventMap } from 'focus-mode-scheduler/src/Task/Task';

export default class WritePostTask extends Task {
  public static get config(): TaskConfig {
    return {
      id: 'write-post',
      info: {
        title: language.text.write_post,
        description: language.text.write_post_description
      },
      hide: false,
      constraintBreachedDialogContent:
        Task.DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS
    };
  }
  protected _header = {
    title: language.text.write_post
  };

  protected handleEvent<Type extends keyof TaskIncomingEventMap>(
    type: Type
  ): void {
    switch (type) {
      case 'skip':
      case 'complete':
        this.internalEmit('finished', undefined);
        break;
    }
  }
}
