import { Box, Typography } from '@mui/material';

export interface HUDTitleProps {
  title?: string;
  subtitle?: string;
}

export default function HUDTitle(props: HUDTitleProps) {
  const { title, subtitle } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
      <Typography
        variant="h4"
        sx={{
          color: (theme) => theme.palette.secondary.main,
          fontSize: {
            md: '1.4rem',
            lg: '1.7rem',
            xl: '1.9rem'
          },
          fontWeight: 'bold',
          m: 1,
          mb: subtitle ? 0 : undefined,
          textAlign: 'center'
        }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="body1"
          sx={{
            color: (theme) => theme.palette.primary.contrastText,
            fontSize: {
              md: '0.8rem',
              lg: '0.9rem',
              xl: '1rem'
            },
            maxWidth: {
              md: '450px',
              lg: '580px'
            },
            mx: 'auto',
            mb: 1,
            textAlign: 'center'
          }}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}
