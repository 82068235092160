import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import LanguageManager from '@common/LanguageManager/LanguageManager';
import { language } from '@/index';
import db from '@/data/Misc/local_db';
import { FancyButton } from 'ui-utils';

export default function LanguageSettings() {
  const languages = React.useMemo(() => language.getAvailableLanguages(), []);

  const [selectedLanguage, setSelectedLanguage] = React.useState(
    language.getLanguage()
  );

  return (
    <Box>
      <Typography variant="h4">{language.text.language}</Typography>
      <RadioGroup
        onChange={(_, lang) => {
          if (!LanguageManager.isValidLanguage(lang)) return;
          setSelectedLanguage(lang);
        }}
        value={selectedLanguage}>
        {languages.map((lang) => (
          <FormControlLabel
            key={lang}
            value={lang}
            control={<Radio />}
            label={language.getLanguageString(lang)}
          />
        ))}
      </RadioGroup>
      <Dialog
        open={selectedLanguage !== language.getLanguage()}
        onClose={() => setSelectedLanguage(language.getLanguage())}>
        <DialogTitle>{language.text.are_you_sure}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.you_need_to_restart_to_update_language}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setSelectedLanguage(language.getLanguage())}
            variant="contained"
            color="primary">
            {language.text.cancel}
          </Button>
          <FancyButton
            fType={{ promise: true }}
            onClick={async () => {
              language.setLanguage(selectedLanguage);
              await db.set('language', selectedLanguage);
              window.api.send('app:restart');
              return new Promise(() => {});
            }}
            variant="contained"
            color="primary">
            {language.text.save_and_restart}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
