import { language } from '@/index';
import { Box, Typography } from '@mui/material';
import StatsHelper from '../../Stats/StatsHelper';
import StatsActions from '@/data/DataServer/Stats';
import { DelayedSkeleton } from 'ui-utils';
import { PriorityHigh } from '@mui/icons-material';
import SetGoal from './SetGoal';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/electron/renderer';

export default function Goals() {
  const { enqueueSnackbar } = useSnackbar();
  const { today, status } = StatsActions.useToday();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">{language.text.goals}</Typography>
      {status === 'loading' && (
        <DelayedSkeleton quantity={4} width="25%" height="150px" />
      )}
      {status === 'error' && (
        <>
          <PriorityHigh color="error" sx={{ fontSize: '5rem' }} />
          <Typography>{language.text.server_error}</Typography>
        </>
      )}
      {status === 'success' && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            gap: 2
          }}>
          {StatsHelper.getStatTypes().map((type) => {
            const goal = today[type]?.goal ?? null;
            if (goal === null) return null;
            return (
              <SetGoal
                key={type}
                goal={type}
                defaultValue={goal}
                onFinish={(goal) => {
                  StatsActions.setGoal(type, goal).catch((error: unknown) => {
                    enqueueSnackbar(language.text.server_error, {
                      variant: 'error'
                    });
                    Sentry.captureException(error);
                  });
                }}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
}
