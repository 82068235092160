import React from 'react';
import RouteButton from './RouteButton';
import {
  Box,
  BoxProps,
  IconButton,
  List,
  SxProps,
  Theme,
  useMediaQuery
} from '@mui/material';
import AccountBadge from './AccountBadge';
import { ChevronLeft, PriorityHigh, Update } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Ava from '@/components/Ava/Ava';

import { language } from '@/index';
import { APP_PATHS, LOADER_PATHS, MANUAL_MODE_PATHS } from '@/Paths';
import { AvaIcon, Logo, useRefreshed } from 'ui-utils';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import PostLoaderHelper from '@/Routes/Loader/PostLoader/PostLoaderHelper';
import { getManualModeRoutes } from '../ManualModeRouter';
import { useFeatureFlags } from '@/globals/featureFlags';

const ROOT_ROUTE = `/${APP_PATHS.Manual}`;
const LOCAL_STORAGE_KEY = 'manualAppbarCollapsed';

function getMargin(index: number, collapsed: boolean) {
  return collapsed ? 0 : (index + 1) * 6;
}

export default function Appbar(props: BoxProps) {
  const navigate = useNavigate();
  const featureFlags = useFeatureFlags();
  const ROUTES = React.useMemo(
    () => getManualModeRoutes(featureFlags),
    [featureFlags]
  );

  const avaRef = React.useRef<HTMLElement | undefined>();

  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const [isCollapsed, setCollapsed] = React.useState(
    localStorage.getItem(LOCAL_STORAGE_KEY) === 'true' || false
  );

  const collapsed = isCollapsed || small;

  // save collapsed state to local storage
  React.useEffect(() => {
    if (isCollapsed)
      localStorage.setItem(LOCAL_STORAGE_KEY, collapsed.toString());
    else localStorage.removeItem(LOCAL_STORAGE_KEY);
  }, [isCollapsed]);

  const showPostLoader = useRefreshed(
    PostLoaderHelper.isDue.bind(PostLoaderHelper),
    30_000
  );

  return (
    <Box
      {...props}
      sx={
        {
          'maxWidth': collapsed ? (theme) => theme.spacing(9.625) : '40vw',
          'minWidth': collapsed ? (theme) => theme.spacing(9.625) : '16vw',
          'overflow': 'overlay',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          ...props.sx
        } as SxProps<Theme>
      }>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        }}>
        <Logo
          variant={'blue'}
          sx={{
            height: (theme) => theme.spacing(collapsed ? 6 : 8),
            width: (theme) => theme.spacing(collapsed ? 6 : 8),
            margin: (theme) => theme.spacing(4, 1, 2, 0),
            cursor: 'pointer',
            zIndex: 12,
            position: 'sticky',
            top: (theme) => theme.spacing(4)
          }}
          onClick={() => navigate(ROOT_ROUTE)}
        />
        <AccountBadge onlyAvatar={collapsed} />
        <List
          sx={{
            flexGrow: 1
          }}>
          {ROUTES.map((route, index) =>
            route.hidden ? null : (
              <RouteButton
                key={route.path}
                marginLeft={getMargin(index, collapsed)}
                icon={<route.icon />}
                label={route.name}
                link={route.path}
                onClick={
                  route.path === MANUAL_MODE_PATHS.LinkedIn
                    ? (active) => {
                        if (!active) {
                          return true;
                        }
                        // go to root of webview
                        const webview =
                          document.querySelector<LinkedInWebviewElement>(
                            'webview'
                          );
                        // src changes when navigating to a new page
                        // data-src is set by us as the original src
                        const src = webview?.getAttribute('data-src');
                        if (src) {
                          webview?.loadURL(src);
                        }
                        return false;
                      }
                    : undefined
                }
                onlyIcon={collapsed}
                rootPath={ROOT_ROUTE}
              />
            )
          )}
          {featureFlags.advanced_load_enabled && showPostLoader && (
            <RouteButton
              marginLeft={getMargin(ROUTES.length, collapsed)}
              icon={
                !collapsed ? (
                  <PriorityHigh color="error" />
                ) : (
                  <Update color={'error'} />
                )
              }
              label={language.text.load_posts}
              link={`/${APP_PATHS.Loader}/${LOADER_PATHS.Post}`}
              onlyIcon={collapsed}
            />
          )}
        </List>

        {featureFlags.show_ava && (
          <>
            {collapsed && (
              <Box
                onClick={() => {
                  avaRef.current?.click();
                }}
                sx={{
                  cursor: 'pointer',
                  width: (theme) => theme.spacing(7.25),
                  height: (theme) => theme.spacing(7.25),
                  px: 1
                }}>
                <AvaIcon variant="square" width="100%" />
              </Box>
            )}
            <Ava avaRef={avaRef} hide={collapsed}>
              {language.text.ava_hint_manual}
            </Ava>
          </>
        )}
        {/* only show expand button when not collapsed due to small width */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 5,
            ...(small && {
              visibility: 'none',
              pointerEvents: 'none'
            })
          }}>
          <IconButton
            sx={{
              minWidth: 0,
              transition: (theme) => theme.transitions.create('transform'),
              transform: collapsed ? 'rotate(-180deg)' : null,
              marginRight: (theme) => theme.spacing(2)
            }}
            onClick={() => {
              if (small) return;
              setCollapsed(!collapsed);
            }}>
            <ChevronLeft
              sx={{
                height: (theme) => theme.spacing(3.25),
                width: (theme) => theme.spacing(3.25)
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}
