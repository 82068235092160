import log from 'electron-log';
import Task from './Task/Task';
import { TaskOptionsConfig } from './components/TaskOptions';
import { ConstraintPreset, Constraints } from './components/Constraints';
import TaskBuilder, { NextTask } from './Task/TaskBuilder';
import { EventEmitter } from '@idot-digital/generic-helpers';
import { Navigate } from 'react-router-dom';
import { MessageBus, returnPath } from './Config';
import { FocusMode } from './lib';
import * as Sentry from '@sentry/electron/renderer';

interface SchedulerEvents {
  'finished': undefined;
  'task-changed': {
    UI: React.FC;
    info: TaskBuilder['info'];
    header: Task['header'];
    dialogs: TaskBuilder['constraintBreachedDialogContent'];
  };
  'static-ui-changed': React.FC<{ loading?: boolean }> | null;
  'start-loading': undefined;
  'finish-loading': undefined;
}

export default class FocusModeScheduler extends EventEmitter<SchedulerEvents> {
  protected queue: TaskBuilder[] = [];
  protected startTime = new Date();
  protected currentTask: Task | null = null;
  protected preloadedTask: Promise<NextTask> | null = null;

  protected _constraints = new Constraints(() => {
    this.finishCurrentCategory();
  });
  public get constraints(): Pick<
    Constraints,
    | 'use'
    | 'getConstraint'
    | 'useCurrentConstraint'
    | 'increaseConstraint'
    | 'setItemsDone'
    | 'registerItemDone'
    | 'setItemsConstraint'
    | 'setMinutesConstraint'
    | 'constraintsMap'
    | 'emit'
  > {
    return this._constraints;
  }

  constructor(
    protected requestContraint: (
      config: TaskOptionsConfig,
      defaultValue?: ConstraintPreset
    ) => Promise<ConstraintPreset | null>,
    protected onError?: (error: string) => void
  ) {
    super();
  }

  protected getStateInfoToLog(): unknown[] {
    return [
      this.currentTask?.id ?? 'UNDEFINED',
      {
        queue: this.queue.map((task) => task.id),
        startTime: this.startTime
      }
    ];
  }

  protected async getContraintOfBuilder(
    builder: TaskBuilder,
    task: Task
  ): Promise<
    { type: 'success'; constraint: ConstraintPreset | null } | { type: 'skip' }
  > {
    if (builder.defaultConstraint)
      return {
        type: 'success',
        constraint: builder.defaultConstraint
      };

    const config = await builder.getOptionsConfig();
    if (!config)
      return {
        type: 'success',
        constraint: null
      };

    const preset = this._constraints.getDefaultConstraint(task) ?? undefined;
    // make sure that constraints are not higher than the max values or lower than the min values
    if (preset?.type === 'items') {
      if (config.items?.max)
        preset.items = Math.min(preset.items, config.items.max);
      if (config.items?.min)
        preset.items = Math.max(preset.items, config.items.min);
    } else if (preset?.type === 'time') {
      if (config.time?.max)
        preset.time = Math.min(preset.time, config.time.max);
      if (config.time?.min)
        preset.time = Math.max(preset.time, config.time.min);
    }
    // show options UI
    const constraint = await this.requestContraint(config, preset);
    // if requestConstraint returns null -> task should be skipped
    if (!constraint)
      return {
        type: 'skip'
      };
    else
      return {
        type: 'success',
        constraint
      };
  }

  protected preloadNext() {
    if (this.queue.length <= 0) return;

    const next = this.queue[0];
    this.preloadedTask = next.getTask();
  }

  protected async getNextInstanceOfCurrent(): Promise<NextTask> {
    if (this.preloadedTask) return this.preloadedTask;
    const builder = this.queue.at(0);
    if (!builder) return null;
    return builder.getTask();
  }

  protected async finishCurrentCategory() {
    // task is done -> remove from queue
    this.queue.shift();
    // reset preloaded data since we skip the task
    this.preloadedTask = null;
    // reset constraint
    this._constraints.setContraint(this.queue[0], null);
    return this.scheduleNext();
  }
  protected isScheduling = false;
  protected async scheduleNext(
    skipCategory = false,
    itemsDone = 0
  ): Promise<Task | null> {
    try {
      this.isScheduling = true;

      // finished all tasks
      if (this.queue.length === 0) {
        this.currentTask = null;
        this.internalEmit('task-changed', {
          UI: Navigate.bind(Navigate, { to: returnPath }),
          info: {
            title: '',
            description: ''
          },
          header: null,
          dialogs: Task.DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS
        });
        this.internalEmit('finish-loading', undefined);
        this.isScheduling = false;
        return null;
      }

      const currentBuilder = this.queue[0];
      const taskTypeChanged = this.currentTask?.id !== this.queue[0].id;

      if (taskTypeChanged) {
        this.internalEmit('static-ui-changed', currentBuilder.getStaticUI());
      }

      // LOADING SCREEN: wait 0.05-0.25s before showing loading screen -> gives the user the feeling that the progress of the completed task is being saved
      setTimeout(
        () => {
          if (this.isScheduling) this.internalEmit('start-loading', undefined);
        },
        500 + Math.random() * 300
      );

      // LOAD NEXT TASK: get preloaded task or load new task
      const newTask = await this.getNextInstanceOfCurrent();

      if (!newTask) {
        // task is done -> remove from queue
        log.log(
          'FocusModeScheduler: Task finished -> remove from queue',
          ...this.getStateInfoToLog()
        );
        if (
          taskTypeChanged
            ? !currentBuilder.info.hideNoInstancesInfo
            : !currentBuilder.info.hideNoMoreInstancesInfo
        ) {
          await this._constraints.displayNoInstances(
            currentBuilder.info.title,
            !taskTypeChanged
          );
        }
        return this.finishCurrentCategory();
      }

      const shouldSkip = await this._constraints.registerItemDone(itemsDone);
      if (shouldSkip) {
        log.log('FocusModeQueue: skipping task', ...this.getStateInfoToLog());
        return this.finishCurrentCategory();
      }

      // SKIP: task has no more time left or is being skipped
      // CONSTRAINT BREACHED: check if constraint is breached
      const constraintBreached = this._constraints.checkContraintBreached();
      if (skipCategory || constraintBreached) {
        if (skipCategory)
          log.log('FocusModeQueue: skipping task', ...this.getStateInfoToLog());
        if (constraintBreached)
          log.log(
            'FocusModeQueue: constraint breached',
            ...this.getStateInfoToLog()
          );

        return this.finishCurrentCategory();
      }

      const { task, UI } = newTask;

      // OPTIONS UI: show options when task type changed and is not onboarding and has options
      if (taskTypeChanged) {
        const res = await this.getContraintOfBuilder(currentBuilder, task);
        if (res.type === 'skip') this.finishCurrentCategory();
        else this._constraints.setContraint(task, res.constraint);
      }

      this.currentTask = task;
      this.internalEmit('task-changed', {
        UI,
        info: currentBuilder.info,
        header: task.header,
        dialogs: currentBuilder.constraintBreachedDialogContent
      });
      this.internalEmit('finish-loading', undefined);
      this.isScheduling = false;
      log.log('FocusModeQueue: executing task', ...this.getStateInfoToLog());

      this.preloadNext();
      return this.currentTask;
    } catch (e) {
      log.error(
        `Error while getting next task:`,
        e,
        ...this.getStateInfoToLog()
      );
      console.error(
        `Error while getting next task:`,
        e,
        ...this.getStateInfoToLog()
      );
      Sentry.captureException(e);
      this.isScheduling = false;
      this.onError?.(`error-scheduling-${this.queue[0]?.id}`);
      return null;
    }
  }

  protected isCustom = false;
  public get isCustomMode() {
    return this.isCustom;
  }
  public async startQueue(
    template: Pick<FocusMode, 'tasks' | 'id'> & {
      custom?: boolean;
    }
  ) {
    MessageBus.emitPersistent('FocusModeStart', { templateID: template.id });
    this.currentTask = null;
    this.queue = [...template.tasks];
    this.isCustom = template.custom ?? false;
    if (this.isScheduling) return;
    await this.scheduleNext();
  }

  public async skipCategory() {
    await this.completeCurrent('skip', true);
  }
  public async skipTask() {
    await this.completeCurrent('skip', false);
  }

  public async completeTask(itemsDone: number) {
    await this.completeCurrent('complete', false, itemsDone);
  }

  private async completeCurrent(
    type: 'skip' | 'complete',
    skipCategory = false,
    itemsDone?: number
  ) {
    if (!this.currentTask)
      throw new Error('[Scheduler.completeCurrent] No task to complete');
    log.log('FocusModeQueue: finished task', ...this.getStateInfoToLog());
    const promise = new Promise<boolean>((res, rej) => {
      if (!this.currentTask)
        throw new Error('[Scheduler.completeCurrent] No task to complete');
      this.currentTask.once('finish-aborted', () => res(false));
      this.currentTask.once('finished', () => res(true));
      this.currentTask.once('error', rej);
    });
    this.currentTask.emit(type, undefined);
    try {
      // wait for task to finish
      const finished = await promise;
      if (!finished) return;
    } catch (e) {
      log.error('Error while completing task', e);
    }

    if (this.queue.length === 0) return;
    if (this.isScheduling) return;

    await this.scheduleNext(skipCategory, itemsDone);
  }

  public unmount() {
    this._constraints.unmount();
  }
}
