import { Box, Typography } from '@mui/material';
import StatsHelper, { Stat } from '../../Routes/ManualMode/Stats/StatsHelper';
import { East, NorthEast, SouthEast } from '@mui/icons-material';
import { Styles } from '@/types';
import { CircularProgressIndicator } from 'ui-utils';
import { Colors } from '@idot-digital/generic-helpers';

export interface StatCompareBlockProps {
  stat: Stat;
  main?: boolean;
  goal?: number;
}

const defaultStyles = {
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    p: 2,
    borderRadius: 2,
    boxShadow: 4,
    backgroundColor: (theme) => theme.palette.background.default,
    zIndex: 0,
    fontSize: '1rem'
  },
  rightWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 1
  },
  value: {
    fontSize: '3.5em',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1
  },
  icon: {
    position: 'absolute',
    inset: 0,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    color: (theme) => theme.palette.text.disabled,
    objectFit: 'contain',
    pointerEvents: 'none',
    zIndex: -1,
    p: 1,
    boxSizing: 'border-box'
  },
  title: {
    fontSize: '1.25em'
  },
  changeWrapper: {
    display: 'flex',
    gap: '.75em',
    alignItems: 'center'
  },
  changeIconWrapper: {
    borderRadius: '50%',
    height: (theme) => `calc(${theme.spacing(1)} + 1.5em)`,
    width: (theme) => `calc(${theme.spacing(1)} + 1.5em)`
  },
  changePositive: {
    backgroundColor: (theme) => Colors.opacify(theme.palette.success.main, 0.2),
    color: (theme) => theme.palette.success.main
  },
  changeNegative: {
    backgroundColor: (theme) => Colors.opacify(theme.palette.error.main, 0.2),
    color: (theme) => theme.palette.error.main
  },
  changeNeutral: {
    backgroundColor: (theme) => Colors.opacify(theme.palette.primary.main, 0.2),
    color: (theme) => theme.palette.primary.main
  },
  changeIcon: {
    color: 'inherit',
    fontSize: '1.5em',
    p: 0.5
  },
  goalProgress: {
    height: '5em',
    width: '5em',
    p: {
      fontSize: '1.5em'
    }
  }
} satisfies Styles;

const highlightStyles = {
  ...defaultStyles,
  wrapper: {
    ...defaultStyles.wrapper,
    minHeight: '30vh',
    minWidth: 'min(60vh, 70vw)',
    mx: 'auto',
    backgroundColor: (theme) => theme.palette.background.default,
    fontSize: '2rem',
    borderColor: (theme) => theme.palette.primary.light,
    borderStyle: 'solid',
    borderWidth: 4,
    gap: 4
  },
  title: {
    ...defaultStyles.title,
    fontSize: '1em'
  },
  goalProgress: {
    ...defaultStyles.goalProgress,
    height: '3.5em',
    width: '3.5em'
  }
} satisfies Styles;

export default function StatCompareBlock(props: StatCompareBlockProps) {
  const assets = StatsHelper.getStatsAsset(props.stat.type);
  const goalProgress = props.goal ? props.stat.current / props.goal : 0;

  if (!assets) return null;

  const styles = props.main ? highlightStyles : defaultStyles;

  return (
    <Box sx={styles.wrapper}>
      <CircularProgressIndicator
        value={Math.min(goalProgress, 1)}
        label={`${Math.round(goalProgress * 100)}%`}
        sx={styles.goalProgress}
      />
      {/* <assets.icon sx={styles.icon} /> */}
      <Box sx={styles.rightWrapper}>
        <Typography variant="caption" sx={styles.title}>
          {assets.title}
        </Typography>
        <Box sx={styles.changeWrapper}>
          <Box
            sx={{
              ...styles.changeIconWrapper,
              ...(props.stat.change > 0
                ? styles.changePositive
                : props.stat.change < 0
                  ? styles.changeNegative
                  : styles.changeNeutral)
            }}>
            {(() => {
              const ChangeIcon =
                props.stat.change > 0
                  ? NorthEast
                  : props.stat.change < 0
                    ? SouthEast
                    : East;
              return <ChangeIcon sx={styles.changeIcon} />;
            })()}
          </Box>
          <Typography
            sx={styles.title}
            color={
              props.stat.change > 0
                ? 'success'
                : props.stat.change < 0
                  ? 'error'
                  : undefined
            }
            variant="caption">
            {props.stat.change > 0 ? '+' : ''}
            {Math.round(props.stat.change * 100)}%
          </Typography>
        </Box>
        <Typography sx={styles.value}>{props.stat.current}</Typography>
      </Box>
    </Box>
  );
}
