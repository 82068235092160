import { v4 as uuid } from 'uuid';
import {
  EventName,
  EventPayload,
  EventHandler,
  MessagePersistence,
  FullEventData
} from './MessageBusUtilTypes';

export default abstract class AbstractMessageBus {
  // eslint-disable-next-line @typescript-eslint/ban-types -- if specified further typescript will complain
  protected listeners = new Map<EventName, Set<Function>>();
  protected debugListeners = new Set<(payload: FullEventData) => void>();
  // eslint-disable-next-line @typescript-eslint/ban-types -- if specified further typescript will complain
  protected onceMap = new Map<Function, boolean>();

  /**
   * Registeres a listener that catches all messages
   * @param callback The callback handling the messages.
   * @returns Unregister function. Call it, when the listener should be deleted
   */
  public debugGetAll(callback: (payload: FullEventData) => void): () => void {
    this.debugListeners.add(callback);
    return () => {
      this.debugListeners.delete(callback);
    };
  }

  public on<Name extends EventName>(
    eventName: Name,
    callback: EventHandler<Name>
  ): void {
    const set = this.listeners.get(eventName) ?? new Set();
    set.add(callback);
    this.listeners.set(eventName, set);
    this.onceMap.set(callback, false);
  }

  public once<Name extends EventName>(
    eventName: Name,
    callback: EventHandler<Name>
  ): void {
    const set = this.listeners.get(eventName) ?? new Set();
    set.add(callback);
    this.listeners.set(eventName, set);
    this.onceMap.set(callback, true);
  }

  public removeHandler<Name extends EventName>(
    eventName: Name,
    // eslint-disable-next-line @typescript-eslint/ban-types -- keep it generic to prevent typescript errors during usage
    callback: Function
  ): void {
    const set = this.listeners.get(eventName);
    if (!set) return;
    set.delete(callback);
    if (set.size === 0) this.listeners.delete(eventName);
  }

  protected pushEventToListeners(event: FullEventData): void {
    this.debugListeners.forEach((callback) => callback(event));
    const set = this.listeners.get(event.eventName);
    if (!set) return;
    set.forEach((callback) => {
      callback(event.payload);
      if (this.onceMap.get(callback)) {
        this.removeHandler(event.eventName, callback);
      }
    });
  }

  public emit<Name extends EventName>(
    eventName: Name,
    payload: EventPayload<Name>,
    persistence = MessagePersistence.TEMPORARY,
    id?: string
  ): void {
    if (id === undefined) {
      id = this.generateMessageID();
    }
    // push messages to local listeners
    this.pushEventToListeners({
      eventName,
      payload: payload as unknown as EventHandler<EventName>,
      id,
      persistence
    });
    this.relayMessages(eventName, payload, persistence, id);
  }
  public emitPersistent<Name extends EventName>(
    eventName: Name,
    payload: EventPayload<Name>,
    id?: string
  ): void {
    this.emit(eventName, payload, MessagePersistence.PERSISTENT, id);
  }

  /**
   * This method should be implemented to relay the messages somewhere else. For example through IPC to another
   * thread or via a websocket or http to a server. It will automatically be called by the emit method.
   * @param eventName
   * @param payload
   */
  protected abstract relayMessages<Name extends EventName>(
    eventName: Name,
    payload: EventPayload<Name>,
    persistence: MessagePersistence,
    id: string
  ): void;

  protected generateMessageID(): string {
    return uuid();
  }
}
