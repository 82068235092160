import { Button } from '@mui/material';
import { Download } from '@mui/icons-material';
import { Message } from '@common/types/ApiTypes';
import { language } from '@/index';

export interface ChatAttachmentProps {
  attachment: Message['attachments'][0];
  onLoad?: (height: number) => void;
}

export default function ChatAttachment(props: ChatAttachmentProps) {
  if (props.attachment.type === 'image')
    return (
      <img
        src={props.attachment.url}
        style={{
          cursor: 'pointer',
          height: '500px',
          maxWidth: '100%',
          objectFit: 'contain',
          objectPosition: 'center'
        }}
        onClick={() => window.api.send('url:open_ext', props.attachment.url)}
        onLoad={(e) => {
          props.onLoad?.((e.target as HTMLElement).clientHeight);
        }}
      />
    );

  if (props.attachment.type === 'audio')
    return (
      <audio
        controls
        src={props.attachment.url}
        style={{ width: '100%', minWidth: 150 }}
      />
    );

  if (props.attachment.type === 'file')
    return (
      <Button
        onClick={() => window.api.send('url:open_ext', props.attachment.url)}
        variant="contained"
        color="inherit"
        startIcon={<Download />}>
        {language.text.download_file}
      </Button>
    );

  return null;
}
