import React from 'react';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import {
  PlaceholderBlockOptions,
  SetPlaceholderBlockOptions
} from './PlaceholderBlocks';
import { NodeViewWrapper } from '@tiptap/react';
import { Delete } from '@mui/icons-material';

export interface PlaceholderBlockViewProps {
  node: {
    attrs: SetPlaceholderBlockOptions;
  };
  extension: {
    options: PlaceholderBlockOptions;
  };
  deleteNode: () => void;
  selected: boolean;
  updateAttributes: (attrs: Partial<SetPlaceholderBlockOptions>) => void;
}

const PlaceholderBlockView = React.forwardRef<
  HTMLSpanElement,
  PlaceholderBlockViewProps
>((props, ref) => {
  return (
    <NodeViewWrapper ref={ref} as="span" style={{ verticalAlign: '7%' }}>
      <Box
        sx={{
          display: 'inline-flex',
          verticalAlign: 'top',
          alignItems: 'center',
          backgroundColor: props.selected
            ? 'secondary.main'
            : 'secondary.light',
          borderRadius: 1,
          px: 0.25,
          maxWidth: '95%'
        }}
        data-drag-handle>
        <Box
          sx={{ position: 'relative', maxWidth: '100%', overflow: 'hidden' }}>
          <Typography
            variant="body1"
            sx={{
              ...(props.extension.options.editable
                ? {
                    opacity: 0,
                    pointerEvents: 'none',
                    minWidth: 80,
                    height: (theme) => theme.spacing(4)
                  }
                : { px: 0.25 }),
              whiteSpace: 'pre',
              maxWidth: '100%',
              m: '0 !important'
            }}>
            {props.node.attrs.text}
          </Typography>
          {/* make textfield grow and shrink according to text */}
          {props.extension.options.editable && (
            <TextField
              value={props.node.attrs.text}
              onChange={(e) => props.updateAttributes({ text: e.target.value })}
              sx={{
                fontSize: 'em',
                position: 'absolute',
                inset: 0,
                maxWidth: '100%'
              }}
              variant="standard"
              size="small"
              hiddenLabel
            />
          )}
        </Box>
        {props.extension.options.editable && (
          <IconButton
            onClick={() => props.deleteNode()}
            size="small"
            sx={{ p: 0.5 }}>
            <Delete />
          </IconButton>
        )}
      </Box>
    </NodeViewWrapper>
  );
});

export default PlaceholderBlockView;
