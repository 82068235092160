import React from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import Lottie, { LottieRef } from 'lottie-react';
import { language, messageBus } from '../../Config';
import processAnimation from './animations/processing_coffee_ava.json';
import useQuote from './QuoteLoader';
import Background from '../../backgrounds/Background';
import Loading from './Loading';

export interface QuoteLoadingProps {
  show?: boolean;
  play?: boolean;
  loadStatusText?: string;
  sx?: SxProps<Theme>;
}

export default function QuoteLoading(props: QuoteLoadingProps) {
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const { quote, isLoading } = useQuote();

  const [type, setType] = React.useState<'first' | 'startup'>('first');
  const [status, setStatus] = React.useState('');
  const statusTimeout = React.useRef<NodeJS.Timeout | null>(null);
  const onNewStatus = React.useCallback((text: string) => {
    if (statusTimeout.current) clearTimeout(statusTimeout.current);
    setStatus(text);
    statusTimeout.current = setTimeout(() => {
      if (!mounted.current) return;
      setStatus('');
    }, 60_000);
  }, []);
  messageBus.use('load:type', (data) => setType(data.type));
  messageBus.use('load:info', (info) => {
    try {
      onNewStatus(
        typeof language.text[info.status] === 'function'
          ? (
              language.text[info.status] as unknown as (
                ...args: NonNullable<typeof info.data>
              ) => string
            )(...info.data!)
          : language.text[info.status]
      );
    } catch (e) {
      console.error('Error in loading status:', e);
    }
  });
  React.useEffect(() => {
    if (props.loadStatusText) onNewStatus(props.loadStatusText);
  }, [props.loadStatusText]);

  const animationRef = React.useRef<LottieRef['current']>(null!);
  React.useEffect(() => {
    if (props.play === undefined) return;
    if (props.play) animationRef.current?.play();
    else animationRef.current?.pause();
  }, [props.play]);

  return (
    <Background
      variant="frame"
      sx={{
        'position': 'absolute',
        'inset': 0,
        'background': (theme) => theme.palette.background.default,
        'opacity': props.show ? 1 : 0,
        'pointerEvents': props.show ? 'all' : 'none',
        'transition': (theme) =>
          theme.transitions.create('opacity', {
            duration: theme.transitions.duration.enteringScreen
          }),
        'zIndex': 10,
        'display': 'flex',
        'animation': 'zoom-bg 8s ease-in-out infinite',
        ...props.sx,
        '@keyframes zoom-bg': {
          '0%': {
            backgroundSize: '100% 100%'
          },
          '50%': {
            backgroundSize: '103% 103%'
          },
          '100%': {
            backgroundSize: '100% 100%'
          }
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%'
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            margin: 'auto',
            textAlign: 'center'
          }}>
          <Box
            sx={{
              maxWidth: '50vh'
            }}>
            <Lottie
              lottieRef={animationRef}
              animationData={processAnimation}
              loop={true}
              style={{
                width: '100%'
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: (theme) => theme.spacing(0)
            }}>
            <Typography
              sx={{
                fontSize: '20px',
                my: 1,
                textAlign: 'center',
                minHeight: '30px',
                maxWidth: 'min(800, 60vw)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
              {status}
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  md: '39px',
                  lg: '44px'
                },
                fontWeight: 'bold',
                marginBottom: (theme) => theme.spacing(3),
                textAlign: 'center'
              }}>
              {language.text.get_a_coffee}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  md: '20px',
                  lg: '24px'
                },
                maxWidth: '500px',
                margin: 'auto',
                mb: 0,
                textAlign: 'center'
              }}>
              {type === 'first'
                ? language.text.first_time_getting_data_might_take_a_while
                : language.text.we_are_processing}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'relative',
            maxWidth: '620px',
            margin: 'auto',
            marginBottom: (theme) => theme.spacing(5),
            alignSelf: 'center'
          }}>
          <Typography
            sx={{
              'position': 'relative' /* for pseudos */,
              'color': (theme) => theme.palette.text.primary,
              'fontSize': '2rem',
              'fontWeight': 'normal',
              'lineHeight': '1.5',
              'margin': 0,
              'border': '4px solid',
              'borderColor': (theme) => theme.palette.primary.main,
              'backgroundColor': (theme) => theme.palette.background.default,
              'borderRadius': '20px',
              'p': 3,
              ' &::after': {
                content: "''",
                position: 'absolute',
                border: '4px solid',
                borderColor: (theme) => theme.palette.primary.main,
                borderRadius: '0 50px 0 0',
                width: '60px',
                height: '60px',
                bottom: '-64px',
                left: '50px',
                borderBottom: 'none',
                borderLeft: 'none',
                zIndex: 3
              },
              ' &::before': {
                content: "''",
                position: 'absolute',
                width: '80px',
                border: '6px solid',
                borderColor: (theme) => theme.palette.background.default,
                bottom: '-4px',
                left: '50px',
                zIndex: 2
              }
            }}>
            {isLoading ? <Loading component="span" /> : quote?.quote ?? ''}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              marginLeft: (theme) => theme.spacing(19),
              marginTop: (theme) => theme.spacing(2)
            }}>
            &mdash;{quote?.author ?? ''}
          </Typography>
        </Box>
      </Box>
    </Background>
  );
}
