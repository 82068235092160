import React from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import useIPC from './hooks/IPCHook';
import { useIdleTimer } from 'react-idle-timer';
import { APP_PATHS, UPDATER_PATHS } from './Paths';

/**
 * Handle IPC messages from main process
 * e.g. show snackbar, open url
 */
export default function IPCHandler(props: { children?: React.ReactNode }) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useIdleTimer({
    // detect idle time after 2 minutes
    timeout: 1000 * 60 * 2,
    onPresenceChange: (presence) => window.api.send(`app:user-${presence.type}`)
  });

  useIPC('snackbar:add', (_, options) => enqueueSnackbar(options));

  useIPC('url:open', (_, url) => navigate(url));

  return <>{props.children ?? null}</>;
}
