import React from 'react';
import { language } from '@/index';
import Auth from '@common/AuthManager/Auth.renderer';
import { AuthError } from '@common/AuthManager/AuthErrors';
import MainConfig from '@common/config/MainConfig';
import { subscription } from '@digital-sun-solutions/cloud-functions';
import { Cancel } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { FancyButton } from 'ui-utils';

/** Error types handling
NO_SUBSCRIPTION
  get resubscription link from api

SUBSCRIPTION_PAUSED
  edit subscription on stripe subscription page

SUBSCRIPTION_EXPIRED
  get resubscription link from api

TRIAL_EXPIRED
  get resubscription link from api

PAYMENT_REQUIRED
  get resubscription link from api

INVALID_REFRESH_TOKEN
  logout

SERVER_ERROR
  ?

INVALID_LINKEDIN_ACCOUNT
  logout

UNKNOWN_ERROR
  logout
 */

export interface SSCLoginErrorProps {
  error: AuthError;
  backToLogin: () => void;
  onError: (error: AuthError) => void;
}

export default function SSCLoginError(props: SSCLoginErrorProps) {
  const { title, description, link } = getErrorText(props.error);

  const [linkOpened, setLinkOpened] = React.useState(false);

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: 8,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        maxWidth: 900,
        mx: 'auto'
      }}>
      <Cancel color="error" sx={{ fontSize: '5rem' }} />
      <Typography variant="h6" textAlign="center">
        {title}
      </Typography>
      <Typography variant="body1" textAlign="center">
        {description}
      </Typography>
      <Box display="flex" gap={2}>
        {!linkOpened && link === 'edit' && (
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => {
              window.api.send(
                'url:open_ext',
                MainConfig.subscriptionManagementLink
              );
              setLinkOpened(true);
            }}>
            {language.text.edit_subscription}
          </Button>
        )}
        {!linkOpened && (link === 'resubscribe' || link === 'subscribe') && (
          <FancyButton
            fType={{ promise: true }}
            size="large"
            variant="contained"
            color="primary"
            onClick={async () => {
              const refreshToken = Auth.getRefreshToken();
              if (!refreshToken) return;
              const res = await subscription.getResubscribeUrl(
                { refreshToken },
                {}
              );
              if (!mounted.current) return;
              if (res.code === 400) {
                Auth.reset();
                if (
                  res.data === 'TOKEN_INVALID' ||
                  res.data === 'TOKEN_EXPIRED'
                ) {
                  props.onError(AuthError.INVALID_REFRESH_TOKEN);
                } else {
                  props.onError(AuthError.UNKNOWN_ERROR);
                }
              } else if (res.code !== 200) {
                props.onError(AuthError.UNKNOWN_ERROR);
              } else {
                await window.api.send('url:open_ext', res.data);
                if (mounted.current) setLinkOpened(true);
              }
            }}>
            {link === 'resubscribe'
              ? language.text.resubscribe
              : language.text.subscribe}
          </FancyButton>
        )}
        {!linkOpened && link === 'linkedin_logout' && (
          <FancyButton
            fType={{ promise: true }}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => window.api.send('ssc:logout', { omitSSC: true })}>
            {language.text.linkedin_logout}
          </FancyButton>
        )}
        <Button
          size="large"
          variant="contained"
          color={linkOpened ? 'primary' : 'error'}
          onClick={() => {
            Auth.reset();
            props.backToLogin();
          }}>
          {link === 'linkedin_logout'
            ? language.text.ssc_logout
            : linkOpened
              ? language.text.back_to_login
              : language.text.logout}
        </Button>
      </Box>
    </Box>
  );
}

function getErrorText(error: AuthError): {
  title: string;
  description: string;
  link: 'edit' | 'resubscribe' | 'subscribe' | 'linkedin_logout' | 'none';
} {
  switch (error) {
    case AuthError.NO_SUBSCRIPTION:
      return {
        title: language.text.no_subscription,
        description: language.text.no_subscription_description,
        link: 'resubscribe'
      };
    case AuthError.SUBSCRIPTION_PAUSED:
      return {
        title: language.text.subscription_paused,
        description: language.text.subscription_paused_description,
        link: 'edit'
      };
    case AuthError.SUBSCRIPTION_EXPIRED:
      return {
        title: language.text.subscription_expired,
        description: language.text.subscription_expired_description,
        link: 'resubscribe'
      };
    case AuthError.TRIAL_EXPIRED:
      return {
        title: language.text.trial_expired,
        description: language.text.trial_expired_description,
        link: 'subscribe'
      };
    case AuthError.PAYMENT_REQUIRED:
      return {
        title: language.text.payment_required,
        description: language.text.payment_error_description,
        link: 'resubscribe'
      };
    case AuthError.INVALID_REFRESH_TOKEN:
      return {
        title: language.text.invalid_refresh_token,
        description: language.text.invalid_refresh_token_description,
        link: 'none'
      };
    case AuthError.SERVER_ERROR:
      return {
        title: language.text.server_error,
        description: language.text.unknown_server_error_description,
        link: 'none'
      };
    case AuthError.INVALID_LINKEDIN_ACCOUNT:
      return {
        title: language.text.ssc_login_linkedin_account_missmatch,
        description:
          language.text.ssc_login_linkedin_account_missmatch_explanation,
        link: 'linkedin_logout'
      };
    case AuthError.UNKNOWN_ERROR:
    default:
      return {
        title: language.text.unknown_error,
        description: language.text.unknown_error_description,
        link: 'none'
      };
  }
}
