import { Objects } from '@idot-digital/generic-helpers';
import posthog from 'posthog-js';
import { create } from 'zustand';

const FEATURES = {
  focusMode_audienceHolder_manualSelection: false,
  chat_reactions: false,
  focusMode_summary_with_stats: false,
  manualMode_stats_page: false,
  ssc_login_10_days_challenge: false,
  show_ava: false,
  full_version_released: false,
  advanced_load_enabled: false
} satisfies { [key: string]: boolean };

export type FeatureFlags = {
  [key in keyof typeof FEATURES]: boolean;
};

type FeatureFlagsState = {
  reload: () => void;
} & FeatureFlags;

export const useFeatureFlags = create<FeatureFlagsState>(() => ({
  ...FEATURES,
  reload: () => {
    posthog.reloadFeatureFlags();
  }
}));

posthog.onFeatureFlags(() => {
  const features = Object.fromEntries(
    Objects.keys(FEATURES).map((key) => [key, posthog.isFeatureEnabled(key)])
  ) as FeatureFlags;
  window.featureFlags = features;
  useFeatureFlags.setState(features);
});

export function checkFeatureFlag(flag: keyof typeof FEATURES) {
  return useFeatureFlags.getState()[flag];
}

window.reloadFeatureFlags = () => {
  posthog.reloadFeatureFlags();
};
