import React from 'react';
import {
  FormControl,
  FormControlProps,
  InputLabel,
  MenuItem,
  Select,
  SelectProps
} from '@mui/material';

export type SelectValue = string | number | readonly string[] | undefined;
type MenuValue<Value extends SelectValue> = Value extends (infer T)[]
  ? T
  : Value;

export interface SelectWrapperProps<Value extends SelectValue> {
  label?: string;
  variant?: FormControlProps['variant'];
  value: Value;
  onChange?: (value: Value) => void;
  options?: {
    value: MenuValue<Value>;
    label: React.ReactNode | React.ReactNode[];
    divider?: boolean;
  }[];
  children?: React.ReactNode[] | React.ReactNode;
  sx?: SelectProps['sx'];
  rootSx?: FormControlProps['sx'];
  disabled?: boolean;
  hideUnderline?: boolean;
  fullWidth?: boolean;
  renderValue?: (value: Value) => React.ReactNode;
}

let currentID = 0;

export default function SelectWrapper<Value extends SelectValue>(
  props: SelectWrapperProps<Value>
) {
  const id = React.useRef(currentID++).current;

  if (props.label)
    return (
      <FormControl variant={props.variant ?? 'filled'} sx={props.rootSx}>
        <InputLabel id={`select-wrapper-${id}`}>{props.label}</InputLabel>
        <Select
          renderValue={props.renderValue}
          multiple={props.value instanceof Array}
          fullWidth={props.fullWidth}
          disabled={props.disabled}
          labelId={`select-wrapper-${id}`}
          value={props.value}
          label={props.label}
          sx={{
            ...props.sx,
            ...(props.hideUnderline && {
              '::before, ::after': {
                borderBottom: 'none !important'
              }
            })
          }}
          onChange={(e) => {
            props.onChange?.(e.target.value as Value);
          }}>
          {props.options?.map((option, index) => (
            <MenuItem value={option.value} key={index} divider={option.divider}>
              {option.label}
            </MenuItem>
          ))}
          {props.children}
        </Select>
      </FormControl>
    );

  return (
    <Select
      variant={props.variant ?? 'filled'}
      disabled={props.disabled}
      value={props.value}
      sx={{
        ...props.sx,
        ...(props.hideUnderline && {
          '::before, ::after': {
            borderBottom: 'none !important'
          }
        })
      }}
      onChange={(e) => {
        props.onChange?.(e.target.value as Value);
      }}>
      {props.options?.map((option, index) => (
        <MenuItem value={option.value} key={index}>
          {option.label}
        </MenuItem>
      ))}
      {props.children}
    </Select>
  );
}
