import LanguageManager from '@common/LanguageManager/LanguageManager';
import initLanguageManager from '@common/LanguageManager/initLanguage.renderer';
import Auth from '@common/AuthManager/Auth.renderer';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import log from 'electron-log';
import SetupActions from './data/DataServer/Setup';
import AccountActions from './data/DataServer/Account';
import AffiliateActions from './data/DataServer/Affiliate';
import ChatActions from './data/DataServer/Chat';
import ContactActions from './data/DataServer/Contact';
import LinkedInChatActions from './data/DataServer/LinkedInChats';
import StatsActions from './data/DataServer/Stats';
import SubscriptionActions from './data/DataServer/Subscription';
import TemplateActions from './data/DataServer/Templates';
import * as Sentry from '@sentry/electron/renderer';
import AppActions from './data/DataServer/App';
import { Contact, MarkedProfile } from './data/Classes/Contact';
import { SSCChat } from './data/Classes/Chat/SSCChat';
import { LinkedInChat } from './data/Classes/Chat/LinkedInChat';

export default async function declareGlobalVars() {
  // make version available on global `this` object (for debugging purposes)
  try {
    globalThis.APP_VERSION = await window.api.send('app:version');
  } catch (e) {
    globalThis.APP_VERSION = '0.0.0';
    log.error('error while getting APP_VERSION from main thread', e);
  }

  // make RELEASE_CHANNEL available on global `this` object (used for feature flags)
  try {
    globalThis.RELEASE_CHANNEL = await window.api.send('app:release-channel');
  } catch (e) {
    globalThis.RELEASE_CHANNEL = 'PROD';
    log.error('error while getting RELEASE_CHANNEL from main thread', e);
  }

  // init language manager
  await initLanguageManager();

  window.language = LanguageManager.getInstance();

  window.MessageBus = MessageBus.getInstance();

  window.auth = Auth;

  //@ts-expect-error -- I don't want to go through the pain of declaring the type for window object and this is only used for debugging in the browser console
  window.actions = {
    app: AppActions,
    account: AccountActions,
    affiliate: AffiliateActions,
    chat: ChatActions,
    contact: ContactActions,
    linkedInChat: LinkedInChatActions,
    setup: SetupActions,
    stats: StatsActions,
    subscription: SubscriptionActions,
    templates: TemplateActions
  };
  //@ts-expect-error -- I don't want to go through the pain of declaring the type for window object and this is only used for debugging in the browser console
  window.classes = {
    Contact,
    MarkedProfile,
    SSCChat,
    LinkedInChat
  };

  window.dev = {};

  MessageBus.getInstance().on('error', ({ error, errorInfo }) => {
    Sentry.captureException(error, { data: { errorInfo } });
  });

  Sentry.setTag('ui_version', await AppActions.getCurrentVersionHash());

  MessageBus.getInstance().emit('renderer:ready', null);

  try {
    window.electron.setWindowZoom(Number(localStorage.getItem('zoom') ?? '1'));
  } catch (e) {
    // user does not have new main thread/preload script version
  }
}
