import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import {
  GlobalStyles,
  PaletteColor,
  PaletteColorOptions,
  ThemeProvider
} from '@mui/material';
import { QueryClientProvider } from 'react-query';
import { HashRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { responsiveFontSizes, createTheme } from '@mui/material/styles';
import updateLocale from 'dayjs/plugin/updateLocale';
import posthog from 'posthog-js';
import dayjs from 'dayjs';
import de from 'dayjs/locale/de';
import { grey } from '@mui/material/colors';
import * as Sentry from '@sentry/electron/renderer';
import log from 'electron-log';
import { GenericComponents, WaitFor } from 'ui-utils';

import queryClient from './other/QueryClient';
import declareGlobalVars from '@/globalVars';
import MessageBus from '@common/MessageBus/MessageBus.renderer';
import MainRouter from './MainRouter';
import IPCHandler from './IPCHandler';
import BetaFlag from './components/BetaFlag/BetaFlag';
import LanguageManager from '@common/LanguageManager/LanguageManager';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import MainConfig from '@common/config/MainConfig';
import ErrorPage from './Routes/Error/ErrorPage';
import AudienceHolderSnackBar, {
  AudienceHolderSnackBarProps
} from './components/AudienceHolderSnackBar/AudienceHolderSnackBar';

// load fonts from assets
import './fonts.css';

Sentry.init({
  ...MainConfig.logging.sentry,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkCaptureBodies: true,
      networkDetailAllowUrls: ['https://api.social-selling-cockpit.co']
    })
  ],
  enabled: !window.electron.isDev
});

//Initialize Posthog for analytics
posthog.init(MainConfig.logging.posthog.token, {
  api_host: MainConfig.logging.posthog.apiHost,
  autocapture: false,
  capture_pageview: false,
  capture_pageleave: false,
  persistence: 'localStorage+cookie'
});

// when CTRL+clicking on a link (opening in new tab) the action will be prevented by the main thred and a message sent to open the link in the webview itself
// https://stackoverflow.com/a/74985512
// open external url in webview
window.api.handle('webview:openURL', (_, data) => {
  const webview = document.querySelector<LinkedInWebviewElement>('webview');
  if (webview) webview.src = data;
});

// log all uncaught errors
window.onerror = (message, source, lineno, colno, error): void => {
  log.error(
    `Error in Renderer: ${typeof message === 'string' ? message : '[EVENT]'} at ${source ?? '[UNKNOWN SOURCE]'}:${lineno ?? '[UNKNOWN LINE]'}:${colno ?? '[UNKNOWN COL]'}, name: ${error?.name ?? '[UNDEFINED ERROR]'}, cause: ${(error?.cause as string | undefined) ?? '[UNDEFINED ERROR]'}, message: ${error?.message ?? '[UNDEFINED ERROR]'}, stack: ${error?.stack ?? '[UNDEFINED ERROR]'}`
  );
};

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: PaletteColor;
    neutral: PaletteColor;
    hud: PaletteColor;
    linkedin: PaletteColor;
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions;
    neutral: PaletteColorOptions;
    hud: PaletteColorOptions;
    linkedin: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    neutral: true;
    hud: true;
    linkedin: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
    neutral: true;
    hud: true;
    linkedin: true;
  }
}

declare module 'notistack' {
  interface VariantOverrides {
    audienceHolder: AudienceHolderSnackBarProps;
  }
}

// make week start on monday
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1
});
dayjs.updateLocale('de', {
  weekStart: 1
});
dayjs.locale(de);

const { palette } = createTheme();
const globalTheme = createTheme({
  palette: {
    primary: {
      'main': '#0062ff',
      'contrastText': '#fff',
      'light': '#499eeb',
      'dark': '#2735ef',
      '100': '#fafafb'
    },
    secondary: {
      main: '#FADA6D',
      dark: '#dfc15f',
      contrastText: '#44444F'
    },
    tertiary: palette.augmentColor({
      color: {
        main: '#9933CC'
      }
    }),
    neutral: palette.augmentColor({
      color: {
        main: grey[300],
        dark: grey[500]
      }
    }),
    linkedin: palette.augmentColor({
      color: {
        main: '#2e65c5'
      }
    }),
    text: {
      primary: '#171725',
      secondary: '#707070',
      disabled: '#E3E7F2'
    },
    background: {
      // needs to be 6 digits to be able to add transparency
      default: '#ffffff',
      // background of linkedin
      paper: '#f3f2ef'
    },
    error: {
      main: '#be4b00',
      dark: '#d44c4c',
      light: '#FC5A5A',
      contrastText: '#fff'
    },
    warning: {
      main: '#f2be00'
    },
    success: {
      main: '#78dc74',
      dark: '#14950e'
    },
    divider: 'rgba(0,0,0,0.08)',
    action: {
      disabled: '#707070',
      selected: '#fbfdff'
    },
    grey: {
      '100': '#E4E4E4',
      '200': '#5E6C84'
    },
    hud: palette.augmentColor({
      color: {
        light: '#E9EDF0',
        main: '#1E2334'
      }
    })
  },
  shape: {
    // linkedin default is "0.8rem", would be 12.8px
    // we use custom border radius of 1rem = 16px
    borderRadius: 8
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '4rem'
    },
    h2: {
      fontFamily: 'Poppins'
    },
    h3: {
      fontFamily: 'Poppins'
    },
    h4: {
      fontFamily: 'Poppins'
    },
    h5: {
      fontFamily: 'Poppins'
    },
    h6: {
      fontFamily: 'Poppins'
    },
    caption: {
      fontFamily: 'Poppins'
    }
  }
});

let theme = createTheme(
  {
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            // disable button text to be all uppercase
            textTransform: 'none'
          }
        }
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true
        },
        styleOverrides: {
          root: {
            // disable button text to be all uppercase
            textTransform: 'none'
          }
        }
      },
      MuiInput: {
        defaultProps: {
          lang: 'de'
        }
      }
    }
  },
  globalTheme
);

theme = responsiveFontSizes(theme);

export const language = LanguageManager.getInstance();
GenericComponents.setLanguageManager(language);
// TODO: fix types
//@ts-expect-error -- Types should be correct
GenericComponents.setMessageBus(MessageBus.getInstance());

const declaseGlobalsPromise = declareGlobalVars();

const root = document.getElementById('root');
if (root && !root.hasChildNodes())
  createRoot(root).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          {/* custom scrollbar styles */}
          <GlobalStyles
            styles={{
              '*': {
                '&::-webkit-scrollbar': {
                  width: theme.spacing(1),
                  height: theme.spacing(1)
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.grey[200],
                  borderRadius: `min(${theme.spacing(0.5)}, ${
                    theme.shape.borderRadius
                  }px)`
                }
              }
            }}
          />

          <HashRouter>
            <SnackbarProvider
              style={{
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight
              }}
              Components={{
                audienceHolder: AudienceHolderSnackBar
              }}
              maxSnack={3}>
              <WaitFor
                errorComponent={ErrorPage}
                errorMessage="Error while loading global variables (declaseGlobalsPromise)"
                promise={declaseGlobalsPromise}
                // use arrow function to make sure that the component is only rendered when the promise is resolved
                component={() => (
                  <>
                    {/* <ConversationRefetcher /> */}
                    <BetaFlag />
                    <IPCHandler />
                    <MainRouter />
                  </>
                )}
              />
            </SnackbarProvider>
          </HashRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
