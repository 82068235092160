import React from 'react';
import { Avatar, AvatarProps, Box, SxProps, Theme } from '@mui/material';
import { Flame, useRefSize } from 'ui-utils';
import { Contact } from '@/data/Classes/Contact';
import { ProfileInfo } from '@common/types/ApiTypes';

export interface ContactAvatarProps {
  contact: Pick<Contact, 'name'> &
    Partial<Pick<Contact, 'firstname' | 'lastname' | 'priority'>> &
    (
      | Pick<Contact, 'getProfilePictureURL'>
      | { pictures: ProfileInfo['pictures'] }
    );
  rootSx?: SxProps<Theme>;
  borderSizeModifier?: number;
  hidePriority?: boolean;
}

const BORDER_WIDTH = 4;
const CIRCUMFERENCE = 2 * Math.PI * 4;
const FLAME_THRESHOLD = 0.7;

export default function ContactAvatar(
  props: ContactAvatarProps & Omit<AvatarProps, 'id'>
) {
  const { contact, rootSx, borderSizeModifier, hidePriority, ...rest } = props;

  const borderWidth = React.useMemo(
    () => (borderSizeModifier ?? 1) * BORDER_WIDTH,
    [borderSizeModifier]
  );

  const initials = React.useMemo(() => {
    const firstname =
      'firstname' in contact && contact.firstname
        ? contact.firstname
        : contact.name.split(' ')[0];
    const lastname =
      'lastname' in contact && contact.lastname
        ? contact.lastname
        : contact.name.split(' ').at(-1) ?? '';
    if (!firstname) return lastname.slice(0, 2) || '';
    if (!lastname) return firstname.slice(0, 2) || '';
    return `${firstname[0].toLocaleUpperCase()}${lastname[0].toLocaleUpperCase()}`;
  }, [contact]);

  const ref = React.useRef<HTMLImageElement>(null);
  const { height } = useRefSize(ref);

  const image = React.useMemo<string | null>(() => {
    if (!height) return null;

    if ('pictures' in contact) {
      if (!contact.pictures) return '';
      const sizes = Object.keys(contact.pictures as object)
        .map((key) => parseInt(key))
        // filter out invalid keys
        .filter((key) => !isNaN(key))
        // sort by largest first
        .sort((a, b) => b - a);

      // get first size larger than height
      const fittingSize = sizes.reduce<number | null>((prev, curr) => {
        if (prev === null) return curr;
        if (curr < height && curr < prev) return prev;
        if (curr > height && curr > prev) return prev;
        return curr;
      }, null);

      return (contact.pictures as { [resolution: number]: string })[
        fittingSize ?? sizes[0]
      ];
    } else return contact.getProfilePictureURL(height);
  }, [height, contact]);

  const priority = contact.priority ? contact.priority / 100 : null;

  const flamePositions = React.useMemo(() => {
    if (priority === null || priority < FLAME_THRESHOLD) return [];
    const partitions = Math.floor((priority - FLAME_THRESHOLD) / 0.1);
    const partitionSize = 180 / partitions;
    const padding = 10;
    const innerPartitionSize = partitionSize - 2 * padding;
    const getOffset = (i: number) => 180 + i * partitionSize + padding;
    return Array.from({ length: partitions }, (_, i) => {
      const angle = Math.random() * innerPartitionSize + getOffset(i);
      const angleInRadians = angle * (Math.PI / 180);
      const x = 50 + 50 * Math.cos(angleInRadians);
      const y = 50 + 50 * Math.sin(angleInRadians);
      return { x, y };
    });
  }, [priority]);

  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 0,
        m: 'auto',
        ...rootSx
      }}>
      <Avatar ref={ref} src={image ?? undefined} alt={contact.name} {...rest}>
        {initials}
      </Avatar>
      {!hidePriority && priority !== null && (
        <Box
          sx={{
            position: 'absolute',
            top: -borderWidth,
            left: -borderWidth,
            height: `calc(100% + ${2 * borderWidth}px)`,
            aspectRatio: 1,
            zIndex: -1
          }}>
          <svg
            viewBox="0 0 10 10"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              height: '100%',
              width: '100%'
            }}>
            <defs>
              <linearGradient id="gradient" x1="0%" y1="100%" x2="0%" y2="0%">
                <stop offset="0%" stopColor="#ffb11c" />
                <stop offset="100%" stopColor="#f00202" />
                {/* <stop offset="40%" stopColor="#FF4D9E" />
                <stop offset="60%" stopColor="#FF866B" />
                <stop offset="80%" stopColor="#FFC353" />
                <stop offset="100%" stopColor="#F9F871" /> */}
              </linearGradient>
            </defs>
            <circle
              cx="5"
              cy="5"
              r="4"
              strokeWidth="2"
              fill="transparent"
              stroke="url(#gradient)"
              strokeLinecap="round"
              strokeDasharray={`${priority * CIRCUMFERENCE} ${(1 - priority) * CIRCUMFERENCE}`}
              strokeDashoffset={
                ((1 - priority) / 2) * -CIRCUMFERENCE + CIRCUMFERENCE / 4
              }
            />
          </svg>
          {flamePositions.map((pos, i) => (
            <Flame
              key={i}
              sx={{
                position: 'absolute',
                transform: `translate(-50%, -50%) scale(${borderSizeModifier ?? 1})`,
                transformOrigin: '50% 50%',
                left: `${pos.x}%`,
                top: `${pos.y}%`
              }}
              animationDelay={i * 0.3}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
