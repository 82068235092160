import { SSCChat } from '@/data/Classes/Chat/SSCChat';
import { Contact } from '@/data/Classes/Contact';
import { MessageTemplate, PipelineEvent } from '@common/types/ApiTypes';
import { language } from '@/index';
import { Pipelines } from '@common/PipelineManager/Pipelines';
import { PipelineStep } from '@common/PipelineManager/PipelineTypes';
import { Task, TaskHeader } from 'focus-mode-scheduler';
import {
  TaskConfig,
  TaskIncomingEventMap
} from 'focus-mode-scheduler/src/Task/Task';

export interface ChatTaskIncomingEventMap {
  'skip-until-date':
    | { date: Date; type: 'complete' }
    | { type: 'close' }
    | { type: 'skip' };
}
export interface ChatTaskOutgoingEventMap {
  'get-skip-until-date': undefined;
}

export default class ChatTask extends Task<
  ChatTaskIncomingEventMap,
  ChatTaskOutgoingEventMap
> {
  public static get config(): TaskConfig {
    return {
      id: 'chat',
      info: {
        title: language.text.chat,
        description: language.text.chat_task_description,
        avaContent: language.text.ava_hint_focus_chat
      },
      hide: false,
      constraintBreachedDialogContent:
        Task.DEFAULT_CONTRAINT_BREACHED_DIALOG_TEXTS
    };
  }

  protected _template: MessageTemplate | null = null;
  protected _pipeline_steps: PipelineStep[] | null = null;
  protected _activeStep: PipelineStep | null = null;

  protected _header: TaskHeader;

  constructor(
    public readonly chat: SSCChat,
    public readonly contact: Contact,
    public readonly templates: MessageTemplate[],
    public readonly pipeline_history: PipelineEvent[],
    public readonly type: 'unanswered' | 'followup'
  ) {
    super();
    this._template = templates[0] ?? null;

    this._header = {
      title:
        this.type === 'unanswered'
          ? language.text.chat
          : language.text.chat_followup,
      skip: {
        instance: {
          enable: true,
          text: language.text.skip_person,
          // when skipping via header we automatically open the skip until so we don't need another confirmation
          omitConfirmationDialog: true
        },
        category: {
          // when skipping via header we automatically open the skip until so we don't need another confirmation
          omitConfirmationDialog: true
        }
      }
    };

    this._pipeline_steps = Pipelines.getCurrentStepsToDisplay(
      contact.lastPipelineEvent?.currentStep
    );

    this._activeStep = this.contact.lastPipelineEvent?.currentStep
      ? Pipelines.getStepToDisplay(
          this.contact.lastPipelineEvent.currentStep,
          this.pipeline_history.map((h) => h.currentStep)
        ) ?? null
      : null;
  }

  protected async finishSkipUntil(date: Date) {
    await this.contact.skipUntil(date);
    this.internalEmit('finished', undefined);
  }

  private shouldSkipSkipUntil = false;
  public omitSkipUntil() {
    this.shouldSkipSkipUntil = true;
  }

  protected currentMessage = '';
  public setCurrentMessage(message: string) {
    this.currentMessage = message;
  }
  protected async sendMessage() {
    if (this.currentMessage) {
      await this.chat.sendMessage(this.currentMessage);
      await this.contact.gotoNextPipelineStep();
    }
  }

  protected async handleEvent<
    Type extends keyof (TaskIncomingEventMap & ChatTaskIncomingEventMap)
  >(type: Type, data: (TaskIncomingEventMap & ChatTaskIncomingEventMap)[Type]) {
    switch (type) {
      case 'skip-until-date':
        const date = data as ChatTaskIncomingEventMap['skip-until-date'];
        if (date.type === 'complete') await this.finishSkipUntil(date.date);
        else if (date.type === 'close')
          this.internalEmit('finish-aborted', undefined);
        else if (date.type === 'skip') this.internalEmit('finished', undefined);
        break;
      case 'skip':
        if (this.shouldSkipSkipUntil) this.internalEmit('finished', undefined);
        else this.internalEmit('get-skip-until-date', undefined);
        break;
      case 'complete':
        await this.sendMessage();
        this.internalEmit('finished', undefined);
        break;
    }
  }

  public get steps() {
    return this._pipeline_steps;
  }
  public get activeStep() {
    return this._activeStep;
  }

  public async setStep(step: PipelineStep): Promise<void> {
    await this.contact.setPipelineStep(step);
    this._activeStep = step;
  }
}
