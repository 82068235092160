import { useNavigate } from 'react-router-dom';
import getSetupSteps, { SetupStep, SetupStepHandler } from './setupSteps';
import React from 'react';
import { Loading } from '@/components/Loading/LoadingTypes';
import { wait } from '@idot-digital/generic-helpers';
import SetupActions from '@/data/DataServer/Setup';
import log from 'electron-log';
import LoadingManager from '@common/LoadingManager/LoadingManager.renderer';

export interface SetupInterface {
  completeAvaTurotial: () => Promise<void>;
  termsAccepted: (accepted: boolean) => Promise<void>;
  videoCompleted: () => Promise<void>;
  websiteFinished: () => Promise<void>;
  videoWatchedButRewatch: () => Promise<void>;
  completeConfirmations: () => Promise<void>;
  linkedinLoadingComplete: () => Promise<void>;
  loadingComplete: () => Promise<void>;
  endSuccessScreen: () => Promise<void>;

  currentStep: SetupStep;

  loading: Loading;
}

export interface SetupProviderProps {
  children: React.ReactNode;
  onFinished: () => void;
  basePath: string;
}

export const SetupContext = React.createContext<SetupInterface>(undefined!);

export function useSetup() {
  return React.useContext(SetupContext);
}

export function SetupProvider(props: SetupProviderProps) {
  const { data: doneSetupSteps, isSuccess: setupStepsLoaded } =
    SetupActions.useSetupSteps({
      onError: (e) => {
        log.error(`Error while loading setup steps:`, e);
      },
      refetchOnWindowFocus: false
    });
  const Steps = React.useRef<readonly SetupStep[]>([]);
  const navigate = useNavigate();
  const [step, setStepState] = React.useState<SetupStep>(null!);
  const stepRef = React.useRef<SetupStep | null>(null);
  const setStep = (step: SetupStep) => {
    stepRef.current = step;
    setStepState(step);
  };

  const [loading, setLoading] = React.useState<Loading>(Loading.NONE);

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const didSetup = React.useRef(false);

  // use in ref to use in useCallback
  const stepIndex = React.useRef(-1);

  const gotoNextStep = React.useCallback(async () => {
    stepIndex.current++;
    const newStep = Steps.current[stepIndex.current];
    // check if setup is completed
    if (stepIndex.current >= Steps.current.length || !newStep) {
      if (didSetup.current) {
        setStep(null!);
        // disable setup completed screen, since we don't much setup anymore
        props.onFinished();
        // navigate(`/${props.basePath}/success`);
      } else {
        props.onFinished();
      }
    } else if (doneSetupSteps?.includes(newStep.id)) {
      gotoNextStep();
    } else {
      // display loading screen between two steps of same type
      if (stepRef.current?.loadAfter || newStep.loadBefore) {
        const lastID = stepRef.current?.id;

        const loadingPromise = Promise.all([
          stepRef.current?.loadAfter instanceof Function
            ? stepRef.current.loadAfter()
            : Promise.resolve(),
          newStep.loadBefore instanceof Function
            ? newStep.loadBefore()
            : Promise.resolve()
        ]);
        let alreadyLoaded = false;
        loadingPromise.then(() => {
          alreadyLoaded = true;
        });

        // time before loading screen
        await wait(newStep.loadTimeout ?? stepRef.current?.loadTimeout ?? 250);
        if (!mounted.current) return;
        if (!alreadyLoaded)
          setLoading(
            stepRef.current?.loadingType || newStep.loadingType || Loading.SHORT
          );

        await loadingPromise;

        if (!mounted.current) return;
        // other step was selected in the meantime
        if (lastID !== stepRef.current?.id) return;
      }
      setLoading(Loading.NONE);
      setStep(newStep);
      // if only loading screen, don't show success screen at end
      if (newStep.handler !== 'loading') didSetup.current = true;
      navigate(`/${props.basePath}/${newStep.handler}`);
    }
  }, [setLoading, setStep, navigate, props.basePath]);

  const handleCompleted = React.useCallback(
    async (expectedHandler: SetupStepHandler, saveToStorage = true) => {
      if (expectedHandler !== stepRef.current?.handler) return;
      if (saveToStorage) {
        await SetupActions.setSetupStep(stepRef.current.id, true);
      }
      gotoNextStep();
    },
    [gotoNextStep, step]
  );

  // export value of context
  const value = React.useMemo<SetupInterface>(
    () => ({
      completeAvaTurotial: async () => {
        await handleCompleted('ava-tutorial');
      },
      termsAccepted: async (accepted: boolean) => {
        if (!accepted) return window.api.send('app:quit');
        await handleCompleted('term');
      },
      videoCompleted: async () => {
        await handleCompleted('video');
      },
      videoWatchedButRewatch: async () => {
        await handleCompleted('video', false);
      },
      websiteFinished: async () => {
        await handleCompleted('url');
      },
      completeConfirmations: async () => {
        await handleCompleted('confirmations');
      },
      linkedinLoadingComplete: async () => {
        await handleCompleted('linkedin-loading', false);
      },
      loadingComplete: async () => {
        await handleCompleted('loading', false);
      },
      endSuccessScreen: () => {
        props.onFinished();
        return Promise.resolve();
      },

      currentStep: step,

      loading
    }),
    [handleCompleted, step]
  );

  // prevent setup from starting twice
  const setupStarted = React.useRef(false);
  // initialize setup
  React.useEffect(() => {
    if (setupStarted.current || !setupStepsLoaded) return;
    setupStarted.current = true;
    const steps = getSetupSteps();
    Steps.current = steps;
    stepIndex.current = -1;
    gotoNextStep();
    LoadingManager.load();
  }, [setupStepsLoaded]);

  //@ts-expect-error -- non typed dev feature
  window.setup = value;

  return (
    <SetupContext.Provider value={value}>
      {props.children}
    </SetupContext.Provider>
  );
}
