import React from 'react';
import { Button } from '@mui/material';
import { OptionQuestionOption } from '@common/PipelineManager/PipelineTypes';
import { OptionalTooltip } from 'ui-utils';

export interface QuestionChoiceProps {
  current?: OptionQuestionOption | undefined | null;
  choices: OptionQuestionOption[] | undefined;
  onChoiceChange: (choice: OptionQuestionOption) => void;
}

export default function QuestionChoice(props: QuestionChoiceProps) {
  const { current, choices, onChoiceChange } = props;
  const [value, setValue] = React.useState(current);

  const selectChoice = (choice: OptionQuestionOption) => {
    setValue(choice);
    onChoiceChange(choice);
  };

  return (
    <>
      {choices &&
        choices.map((choice) => (
          <OptionalTooltip key={choice.id} title={choice.description || null}>
            <Button
              onClick={() => selectChoice(choice)}
              disableElevation={false}
              variant="contained"
              sx={{
                'cursor': 'pointer',
                'minWidth': '100%',
                'mb': 3,
                'backgroundColor':
                  value?.id === choice.id ? 'primary.main' : 'neutral.light',
                'color':
                  value?.id === choice.id
                    ? 'primary.contrastText'
                    : 'neutral.contrastText',
                '&:hover': {
                  backgroundColor:
                    value?.id === choice.id ? 'primary.dark' : 'neutral.main'
                },
                'fontSize': '16px',
                'alignSelf': 'flex-end'
              }}>
              {choice.text}
            </Button>
          </OptionalTooltip>
        ))}
    </>
  );
}
