import React from 'react';
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  MenuItem,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import ContactAvatar from '@/components/Avatar/ContactAvatar';
import { FiberManualRecord } from '@mui/icons-material';
import { Styles } from '@/types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { applyIfNotNull } from '@common/Functions';
import useError from '@/hooks/ErrorHook';
import { Contact } from '@/data/Classes/Contact';
import { language } from '@/index';
import { ContactType } from '@common/types/enums';
import { AvatarGrid, Loading, TextEditorHelper } from 'ui-utils';
import { Dates } from '@idot-digital/generic-helpers';
import { AbstractChat } from '@/data/Classes/Chat/AbstractChat';

export interface ChatListItemProps {
  onSelect: () => void | Promise<void>;
  selected: boolean;
  chat: AbstractChat;
  type?: ContactType;
}

const styles = {
  avatar: {
    width: (theme) => theme.spacing(5.5),
    height: (theme) => theme.spacing(5.5),
    fontSize: '2rem'
  }
} satisfies Styles<string>;

export default function ChatListItem(props: ChatListItemProps) {
  const { chat, type } = props;

  const moreRef = React.useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [unreadLoading, setUnreadLoading] = React.useState(false);
  const [archiveLoading, setArchiveLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { handleError } = useError();

  const mainContact = React.useMemo(() => {
    if (chat.contact) return chat.contact;
    if (chat.participants.length === 1) return chat.participants[0];
    return null;
  }, [chat]);

  return (
    <>
      <ListItemButton
        onClick={() => {
          const res = props.onSelect();
          if (res instanceof Promise) {
            setLoading(true);
            res.finally(() => setLoading(false));
          }
        }}
        selected={props.selected}
        sx={{
          'padding': (theme) => theme.spacing(2),
          'display': 'relative',
          '&:hover .hoverWrapper': {
            button: {
              opacity: 1
            },
            span: {
              opacity: 0
            }
          }
        }}>
        <Tooltip
          placement="left"
          title={
            type
              ? type === ContactType.AUDIENCE_HOLDER
                ? language.text.audience_holder
                : type === ContactType.PERSONAL
                  ? language.text.personal_contact
                  : type === ContactType.CUSTOMER
                    ? language.text.customer
                    : type === ContactType.NO_MATCH
                      ? language.text.no_match
                      : type === ContactType.POTENTIAL_CUSTOMER
                        ? language.text.potential_customer
                        : type === ContactType.UNCATEGORIZED
                          ? language.text.not_yet_categorized
                          : ''
              : language.text.not_yet_categorized
          }>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: 0,
              height: '100%',
              width: (theme) => theme.spacing(0.75),
              // provide a bigger hover area for tooltip
              pr: 4,
              zIndex: 1
            }}>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                background: (theme) =>
                  type
                    ? type === ContactType.AUDIENCE_HOLDER
                      ? theme.palette.secondary.main
                      : type === ContactType.PERSONAL
                        ? theme.palette.tertiary.main
                        : type === ContactType.CUSTOMER
                          ? theme.palette.success.main
                          : type === ContactType.NO_MATCH
                            ? theme.palette.error.main
                            : type === ContactType.POTENTIAL_CUSTOMER
                              ? theme.palette.primary.main
                              : type === ContactType.UNCATEGORIZED
                                ? 'transparent'
                                : 'transparent'
                    : 'transparent'
              }}
            />
          </Box>
        </Tooltip>

        <ListItemAvatar sx={{ position: 'relative' }}>
          {mainContact ? (
            <ContactAvatar
              contact={
                mainContact instanceof Contact
                  ? mainContact
                  : {
                      firstname: mainContact.firstName,
                      lastname: mainContact.lastName,
                      name: mainContact.firstName + ' ' + mainContact.lastName,
                      pictures: mainContact.profilePictureUrl
                    }
              }
              sx={{
                ...styles.avatar,
                mr: 2,
                ...(loading ? { opacity: 0.5 } : {})
              }}
            />
          ) : (
            <AvatarGrid sx={loading ? { opacity: 0.5 } : {}}>
              {chat.participants.map((p) => (
                <ContactAvatar
                  key={p.profileID}
                  contact={{
                    name: p.firstName + ' ' + p.lastName,
                    firstname: p.firstName,
                    lastname: p.lastName,
                    pictures: p.profilePictureUrl
                  }}
                  sx={styles.avatar}
                />
              ))}
            </AvatarGrid>
          )}

          {loading && (
            <Loading
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 2
              }}
            />
          )}
        </ListItemAvatar>
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center">
            {/* height is calulcated by: fontSize * line-height of typography */}
            <Box position="relative" flexGrow="1" height="1.35em">
              <Typography
                sx={{
                  fontSize: '.9em',
                  fontWeight: 500,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%'
                }}>
                {(chat.participants
                  .map((p) => p.firstName + ' ' + p.lastName)
                  .join(', ') ||
                  chat.contact?.name) ??
                  ''}
              </Typography>
            </Box>
            <Box
              position="relative"
              minWidth={(theme) => theme.spacing(3)}
              className="hoverWrapper">
              <Typography
                variant="caption"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '.8em',
                  opacity: 1,
                  transition: (theme) =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest
                    })
                }}>
                {applyIfNotNull(
                  chat.lastActivityAt,
                  Dates.getRelativeTimeString.bind(Dates),
                  undefined,
                  {
                    short: true
                  }
                )}
              </Typography>
              <IconButton
                ref={moreRef}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpen(true);
                }}
                size="small"
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: (theme) => theme.spacing(-1),
                  opacity: 0,
                  transition: (theme) =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest
                    })
                }}>
                <MoreHorizIcon />
              </IconButton>
            </Box>
          </Box>
          <Box position="relative" width="100%" height="1.2em">
            <Typography
              variant="body2"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '0.8em',
                width: chat.read
                  ? '100%'
                  : (theme) => `calc(100% - ${theme.spacing(5)})`,
                color: (theme) => theme.palette.text.secondary,
                position: 'absolute',
                left: 0,
                top: 0
              }}>
              {applyIfNotNull(
                chat.lastMessage,
                TextEditorHelper.replaceSpecialChars
              )}
            </Typography>
            {!chat.read && (
              <Box
                sx={{
                  float: 'right',
                  position: 'relative',
                  width: (theme) => theme.spacing(3),
                  height: (theme) => theme.spacing(3)
                }}>
                <FiberManualRecord
                  color="primary"
                  sx={{
                    width: (theme) => theme.spacing(4),
                    height: (theme) => theme.spacing(4),
                    position: 'absolute',
                    top: (theme) => theme.spacing(-0.5),
                    right: (theme) => theme.spacing(-0.5)
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    inset: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Typography
                    variant="caption"
                    color={(theme) => theme.palette.primary.contrastText}
                    textAlign="center"
                    lineHeight={1}
                    fontSize=".66em">
                    {/* don't show "0" -> chat was manually set to be unread */}
                    {chat.unreadCount || ''}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </ListItemButton>
      <Divider
        sx={{
          width: (theme) => `calc(100% - ${theme.spacing(4)})`,
          margin: (theme) => theme.spacing(0, 2)
        }}
      />
      <Popover
        anchorEl={moreRef.current}
        open={open}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={() => setOpen(false)}>
        <List dense>
          <MenuItem
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- promise loading is handled with state
            onClick={handleError(
              async () => {
                setUnreadLoading(true);
                await chat.markAsRead(!chat.read);
              },
              null,
              () => {
                setUnreadLoading(false);
                setOpen(false);
              }
            )}
            sx={{ position: 'relative' }}>
            {unreadLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                position="absolute"
                top={0}
                left={0}
                height="100%">
                <Loading
                  sx={{
                    height: '1.25rem !important',
                    width: '1.25rem !important'
                  }}
                />
              </Box>
            ) : null}
            <Typography
              component="span"
              visibility={unreadLoading ? 'hidden' : undefined}>
              {chat.read
                ? language.text.mark_as_unread
                : language.text.mark_as_read}
            </Typography>
          </MenuItem>

          <MenuItem
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- promise loading is handled with state
            onClick={handleError(
              async () => {
                setArchiveLoading(true);
                await chat.setArchived(!chat.archived);
              },
              null,
              () => {
                setArchiveLoading(false);
                setOpen(false);
              }
            )}
            sx={{ position: 'relative' }}>
            {archiveLoading ? (
              <Box
                display="flex"
                justifyContent="center"
                width="100%"
                position="absolute"
                top={0}
                left={0}
                height="100%">
                <Loading
                  sx={{
                    height: '1.25rem !important',
                    width: '1.25rem !important'
                  }}
                />
              </Box>
            ) : null}
            <Typography
              component="span"
              visibility={archiveLoading ? 'hidden' : undefined}>
              {chat.archived ? language.text.unarchive : language.text.archive}
            </Typography>
          </MenuItem>
        </List>
      </Popover>
    </>
  );
}
