/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// without these this file is unreadable and I'm not able to do the correct types without immense effort
import LinkedIn from 'linkedin-api';
import LinkedInActions from './LinkedInActions';
import { Objects } from '@idot-digital/generic-helpers';
import { LINKEDIN_FUNCTION_KEYS } from '@common/Webview.common/Config';
import Logger from 'electron-log';

const CALL_TIMEOUT = 30_000;

let currentID = 0;
const openCalls: {
  method: keyof LinkedIn;
  args: any[];
  resolve: (res: any) => void;
  reject: (err: Error) => void;
  id: number;
  timeout: NodeJS.Timeout;
}[] = [];

export type WebviewLinkedIn = Pick<
  LinkedIn,
  (typeof LINKEDIN_FUNCTION_KEYS)[number]
>;

const InternalWebviewLinkedIn: WebviewLinkedIn & {
  __resolveOpenCall__: (
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- we use the type defintion to specify likely outputs
    ...args: [method: string, ...args: any[], res: any | `[ERROR]${string}`]
  ) => void;
  loggedIn: boolean;
} = {
  __resolveOpenCall__: (
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- we use the type defintion to specify likely outputs
    ...args: [method: string, ...args: any[], res: any | `[ERROR]${string}`]
  ) => {
    const res = args.pop();
    const [method, ...fctArgs] = args;
    const call = openCalls.find(
      (c) => c.method === method && Objects.deepEqual(c.args, fctArgs)
    );
    if (!call) {
      console.info(
        '[WebviewLinkedIn] could not resolve call',
        method,
        fctArgs,
        res
      );
      return;
    }
    if (typeof res === 'string' && res.startsWith('[ERROR]')) {
      Logger.error(
        `[WebviewLinkedIn] Error in '${method}' with args`,
        fctArgs,
        res
      );
      call.reject(new Error(res));
    } else {
      console.debug('[WebviewLinkedIn] resolving call', method, fctArgs, res);
      call.resolve(res);
    }
    clearTimeout(call.timeout);
    openCalls.splice(openCalls.indexOf(call), 1);
  },
  loggedIn: false
} as any;

for (const method of LINKEDIN_FUNCTION_KEYS) {
  //@ts-expect-error -- typescript does not like dynamic keys
  InternalWebviewLinkedIn[method] = (...args: any[]) =>
    // eslint-disable-next-line @typescript-eslint/no-misused-promises, no-async-promise-executor
    new Promise(async (resolve, reject) => {
      console.debug('[WebviewLinkedIn] called', method, args);
      if (!InternalWebviewLinkedIn.loggedIn) {
        reject(new Error('Not logged in'));
        return;
      }

      const webview = await LinkedInActions.getWebview({
        forceBackground: !['getOwnProfile', 'logout'].includes(method)
      });
      console.debug('[WebviewLinkedIn] got webview', webview);
      if (!webview) {
        reject(new Error('Could not get a webview'));
        return;
      }
      openCalls.push({
        method,
        args,
        resolve,
        reject,
        id: currentID++,
        timeout: setTimeout(() => {
          console.error('Timeout for call', method, args);
          reject(new Error('Timeout'));
        }, CALL_TIMEOUT)
      });
      webview.webview.send('linkedin:api', [method, ...args]);
    });
}

// eslint-disable-next-line @typescript-eslint/no-misused-promises
window.api.handle('linkedin:api', async (_, method, args) => {
  //@ts-expect-error -- typescript does not like dynamic keys
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const res = await InternalWebviewLinkedIn[method](...args);
  window.api.send('linkedin:api', method, args, res);
});

const WebViewLinkedIn = InternalWebviewLinkedIn as WebviewLinkedIn & {
  loggedIn: boolean;
};

export { InternalWebviewLinkedIn };
export default WebViewLinkedIn;

//@ts-expect-error -- only for debugging
WebViewLinkedIn.openCalls = openCalls;

window.WebviewLinkedIn = WebViewLinkedIn;
