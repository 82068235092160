import { STATS_PATHS } from '@/Paths';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate
} from 'react-router-dom';
import RealtimeStats from './RealtimeStats/RealtimeStats';
import { Box, Button, ButtonGroup, IconProps } from '@mui/material';
import React from 'react';
import { language } from '@/index';
import { History, Today } from '@mui/icons-material';
import HistoricStats from './Historic/HistoricStats';
import { BindProps, FocusIcon } from 'ui-utils';

const getRoutes = () =>
  [
    {
      path: STATS_PATHS.FocusMode,
      component: BindProps(RealtimeStats, { type: 'focusmode' }),
      label: language.text.last_focus_mode_stats,
      icon: FocusIcon
    },
    {
      path: STATS_PATHS.Today,
      component: BindProps(RealtimeStats, { type: 'today' }),
      label: language.text.today_stats,
      icon: Today
    },
    {
      path: STATS_PATHS.Historic,
      component: HistoricStats,
      label: language.text.historic_stats,
      icon: History
    }
  ] as {
    path: string;
    component: React.FC;
    label: string;
    icon?: React.FC<Pick<IconProps, 'sx'>>;
  }[];

export default function Stats() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const ROUTES = React.useRef(getRoutes());

  return (
    <Box minHeight="100%" display="flex" flexDirection="column">
      <Box
        sx={{
          p: 2,
          mx: 'auto',
          maxWidth: ROUTES.current.length * 400,
          width: '100%',
          boxSizing: 'border-box'
        }}>
        <ButtonGroup fullWidth>
          {ROUTES.current.map((route) => (
            <Button
              key={route.path}
              startIcon={route.icon ? <route.icon /> : undefined}
              variant={
                pathname.split('/').includes(route.path)
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() => navigate(route.path)}>
              {route.label}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Routes>
        <Route index element={<Navigate to={ROUTES.current[0].path} />} />
        {ROUTES.current.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>
    </Box>
  );
}
