import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ManualModeProvider } from './ManualModeContext';
import ManualModeFrame from './ManualModeFrame';
import ManualModeRouter from './ManualModeRouter';
import SetupActions from '@/data/DataServer/Setup';
import { GenericVideoModal } from 'ui-utils';
import posthog from 'posthog-js';
import { APP_PATHS } from '@/Paths';
import { language } from '@/index';
import { Box, Button } from '@mui/material';
import AskToRestart from '@/components/AskToRestart/AskToRestart';

const ONBOARDING_STEP = 'manual:open';

export default function ManualMode() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { video } = SetupActions.useOnboardingVideo(ONBOARDING_STEP);
  const [videoOpen, setVideoOpen] = React.useState(false);
  React.useEffect(() => {
    if (video) {
      posthog.capture(`onboarding-${ONBOARDING_STEP}-started`);
      setVideoOpen(true);
    } else {
      setVideoOpen(false);
    }
  }, [video]);

  const linkedinActive = pathname.includes('linkedin');
  return (
    <>
      <ManualModeProvider>
        <ManualModeFrame linkedinActive={linkedinActive}>
          <ManualModeRouter linkedinActive={linkedinActive} />
        </ManualModeFrame>
      </ManualModeProvider>
      <AskToRestart />
      <GenericVideoModal
        onClose={() => {
          SetupActions.completeOnboardingStep(ONBOARDING_STEP);
          posthog.capture(`onboarding-${ONBOARDING_STEP}-cancel`);
        }}
        open={videoOpen}
        videoID={video ? video.videoID : ''}
        title={video ? video.title : ''}
        onEnd={() => {
          SetupActions.completeOnboardingStep(ONBOARDING_STEP);
          posthog.capture(`onboarding-${ONBOARDING_STEP}-completed`);
        }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 2
          }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              SetupActions.completeOnboardingStep(ONBOARDING_STEP);
              posthog.capture(`onboarding-${ONBOARDING_STEP}-cancel`);
              navigate(`/${APP_PATHS.Focus}`);
              setVideoOpen(false);
            }}>
            {language.text.start_focus_mode}
          </Button>
          <Button
            variant="contained"
            color="neutral"
            onClick={() => {
              SetupActions.completeOnboardingStep(ONBOARDING_STEP);
              posthog.capture(`onboarding-${ONBOARDING_STEP}-cancel`);
              setVideoOpen(false);
            }}>
            {language.text.pause_tutorial_continue_in_manual_mode}
          </Button>
        </Box>
      </GenericVideoModal>
    </>
  );
}
