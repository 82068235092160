import { convertFromBinary, convertToBinary } from './gen/bundle.js';
import { encodeToBinary as encodeToBinaryInternal, decodeFromBinary as decodeFromBinaryInternal, decodeFromBinaryPartial } from './lib.js';
export function encodeToBinary(event) {
    return encodeToBinaryInternal(event, convertToBinary);
}
export function decodeFromBinary(data) {
    return decodeFromBinaryInternal(data, convertFromBinary);
}
export default {
    convertToBinary,
    convertFromBinary,
    encodeToBinary,
    decodeFromBinary,
    decodeFromBinaryPartial
};
