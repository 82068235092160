import { auth } from '@digital-sun-solutions/cloud-functions';
import log from 'electron-log';
import posthog from 'posthog-js';

import { getSelf } from '@/data/LinkedIn/Account';
import { LanguageObject } from '@common/LanguageManager/LanguageTypes';
import PrintableError from '../PrintableError/PrintableError';
import { SessionInfo } from '@common/types/ApiTypes';
import { AuthError, AuthWarning } from './AuthErrors';
import { language } from '@/index';
import '@/windowTypes.d.ts';
import MessageBus from '@common/MessageBus/MessageBus.renderer';

const REFRESH_TOKEN_KEY = 'auth:refresh-token';

class AuthManager {
  private static instance: AuthManager | null = null;
  public static getInstance() {
    if (!AuthManager.instance) AuthManager.instance = new AuthManager();
    return AuthManager.instance;
  }

  constructor() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (refreshToken) this.refreshToken = refreshToken;

    window.api.handle('ssc:logout', () =>
      localStorage.removeItem(REFRESH_TOKEN_KEY)
    );

    window.api.handle('ssc:saveToken', (_, ...args) => {
      const token = args[0] ?? this.refreshToken;
      if (!token) return;
      localStorage.setItem(REFRESH_TOKEN_KEY, token);
    });
  }

  private refreshToken: string | null = null;
  private sessionToken: string | null = null;

  private sessionInfo: Partial<SessionInfo> = {};
  private linkedInIdentifier: string | null = null;

  private resettingSessionToken: ReturnType<
    AuthManager['resetSessionToken']
  > | null = null;

  private async getLinkedInIdentifier() {
    try {
      if (this.linkedInIdentifier) return this.linkedInIdentifier;
      const self = await getSelf(true);
      if (self) {
        this.linkedInIdentifier = self.profileID;
        return self.profileID;
      }
      return null;
    } catch (e) {
      log.error('[Auth.renderer] Error getting linkedinIdentifier', e);
      return null;
    }
  }

  private async resetSessionToken(
    info?: Partial<{ linkedInIdentifier: string }>
  ): Promise<{
    success: boolean;
    error: AuthError | undefined;
    warning: AuthWarning | undefined;
  }> {
    const linkedInIdentifier =
      info?.linkedInIdentifier ??
      this.linkedInIdentifier ??
      (await this.getLinkedInIdentifier()) ??
      undefined;

    if (this.resettingSessionToken) {
      return await this.resettingSessionToken;
    }

    if (!this.refreshToken)
      return {
        success: false,
        error: AuthError.INVALID_REFRESH_TOKEN,
        warning: undefined
      };

    let resolve: (
      value: Awaited<ReturnType<AuthManager['resetSessionToken']>>
    ) => void = () => undefined;
    this.resettingSessionToken = new Promise((r) => (resolve = r));

    let result: {
      success: boolean;
      error: AuthError | undefined;
      warning: AuthWarning | undefined;
    } | null = null;
    try {
      const res = await auth.getSessionToken(
        {
          refreshToken: this.refreshToken,
          sessionInfo: {
            linkedInIdentifier,
            currentVersion: {
              node: globalThis.APP_VERSION,
              ui: globalThis.VERSION_HASH
            },
            today0: new Date().setHours(0, 0, 0, 0)
          }
        },
        {}
      );
      if (res.code === 200) {
        this.sessionToken = res.data.sessionToken;
        this.sessionInfo = res.data.sessionInfo;
        //TODO: remove this if and always send identify after 01.July.2024
        if (res.data.sessionInfo.trackingID) {
          posthog.identify(res.data.sessionInfo.trackingID);
        }

        if (linkedInIdentifier) this.linkedInIdentifier = linkedInIdentifier;

        MessageBus.getInstance().emit('auth-renderer:logged-in', {
          sessionToken: res.data.sessionToken
        });
      }
      result = {
        success: res.code === 200,
        error: (() => {
          switch (res.code) {
            case 200:
              return undefined;
            case 409:
              return AuthError.INVALID_LINKEDIN_ACCOUNT;
            case 402:
              switch (res.data) {
                case 'NO_SUBSCRIPTION':
                  return AuthError.NO_SUBSCRIPTION;
                case 'SUBSCRIPTION_PAUSED':
                  return AuthError.SUBSCRIPTION_PAUSED;
                case 'SUBSCRIPTION_EXPIRED':
                  return AuthError.SUBSCRIPTION_EXPIRED;
                case 'TRIAL_EXPIRED':
                  return AuthError.TRIAL_EXPIRED;
                default:
                  return AuthError.PAYMENT_REQUIRED;
              }
            case 400:
              return AuthError.INVALID_REFRESH_TOKEN;
            case 500:
              return AuthError.SERVER_ERROR;
            default:
              return AuthError.UNKNOWN_ERROR;
          }
        })(),
        warning: (() => {
          if (res.code !== 200) return undefined;
          if (res.data.sessionInfo.cancelled)
            return AuthWarning.SUBSCRIPTION_CANCELED;
          if (res.data.sessionInfo.paymentFailed)
            return AuthWarning.PAYMENT_FAILED;
          return undefined;
        })()
      } as const;

      return result;
    } finally {
      resolve(
        result ?? {
          success: false,
          error: AuthError.UNKNOWN_ERROR,
          warning: undefined
        }
      );
      this.resettingSessionToken = null;
    }
  }

  private async waitForRefreshToken() {
    if (!this.sessionToken) return await this.resetSessionToken();
    if (this.resettingSessionToken) return await this.resettingSessionToken;
    return {
      success: true,
      error: undefined
    };
  }

  public setRefreshToken(refreshToken: string) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    this.refreshToken = refreshToken;
  }

  private getLanguageKeyForError(error: AuthError): keyof LanguageObject {
    switch (error) {
      case AuthError.INVALID_LINKEDIN_ACCOUNT:
        return 'invalid_linkedin_account';
      case AuthError.PAYMENT_REQUIRED:
        return 'payment_required';
      case AuthError.INVALID_REFRESH_TOKEN:
        return 'invalid_refresh_token';
      case AuthError.SERVER_ERROR:
        return 'server_error';
      case AuthError.UNKNOWN_ERROR:
        return 'unknown_error';
      case AuthError.NO_SUBSCRIPTION:
        return 'no_subscription';
      case AuthError.SUBSCRIPTION_PAUSED:
        return 'subscription_paused';
      case AuthError.SUBSCRIPTION_EXPIRED:
        return 'subscription_expired';
      case AuthError.TRIAL_EXPIRED:
        return 'trial_expired';
      default:
        return 'generic_error';
    }
  }

  /**
   * Get the current refresh token
   */
  public async getToken(): Promise<string> {
    const codeRes = await this.waitForRefreshToken();
    if (!codeRes.success)
      throw new PrintableError(
        language.text[
          this.getLanguageKeyForError(
            codeRes.error ?? AuthError.INVALID_REFRESH_TOKEN
          )
        ] as string,
        {
          shouldRetry: false,
          internalMessage: 'AuthManager.renderer|getToken:1'
        }
      );
    if (!this.sessionToken)
      throw new PrintableError(
        'Authorization failed: Could not get session token',
        {
          shouldRetry: false,
          langKey: this.getLanguageKeyForError(AuthError.INVALID_REFRESH_TOKEN),
          internalMessage: 'AuthManager.renderer|getToken:2'
        }
      );
    return this.sessionToken;
  }

  /**
   * Get auth token for a route \
   * Automatically refreshes token if needed
   */
  public async execRoute<R>(
    func: (token: string) => Promise<
      | { code: 401; data: 'UNAUTHORIZED' }
      | { code: 403; data: 'FORBIDDEN' }
      | {
          code: 500;
          data: 'INTERNAL SERVER ERROR';
        }
      | {
          code: 502;
          data: 'Bad Gateway';
        }
      | {
          code: 503;
          data: 'Service Unavailable';
        }
      | R
    >,
    isRetry = false
  ): Promise<
    | R
    | {
        code: 500;
        data: 'INTERNAL SERVER ERROR';
      }
  > {
    const token = await this.getToken();
    const result = await func(token);

    if (
      typeof result === 'object' &&
      result &&
      'code' in result &&
      (result.code === 502 || result.code === 503)
    ) {
      return {
        code: 500,
        data: 'INTERNAL SERVER ERROR'
      };
    }

    if (
      typeof result === 'object' &&
      result &&
      'code' in result &&
      (result.code === 401 || result.code === 403)
    ) {
      if (isRetry) {
        log.error("Couldn't get session token (renderer)");
        throw new PrintableError('Authorization failed', {
          shouldRetry: false,
          langKey: this.getLanguageKeyForError(AuthError.INVALID_REFRESH_TOKEN),
          internalMessage: 'AuthManager.renderer|execRoute:1'
        });
      }

      // get new token
      const codeRes = await this.resetSessionToken();
      if (!codeRes.success)
        throw new PrintableError(
          language.text[
            this.getLanguageKeyForError(
              codeRes.error ?? AuthError.INVALID_REFRESH_TOKEN
            )
          ] as string,
          {
            shouldRetry: false,
            langKey: this.getLanguageKeyForError(
              codeRes.error ?? AuthError.INVALID_REFRESH_TOKEN
            ),
            internalMessage: 'AuthManager.renderer|execRoute:2'
          }
        );
      return this.execRoute(func, true);
    }

    return result as Exclude<R, { code: 401 } | { code: 403 }>;
  }

  /**
   * Checks if a LinkedIn identifier is valid to be user for the logged in user
   */
  public async checkLinkedInIdentfier(publicIdentifier: string) {
    try {
      await this.resetSessionToken({ linkedInIdentifier: publicIdentifier });
      return true;
    } catch (_) {
      return false;
    }
  }

  public get sscLoggedIn() {
    return this.refreshToken !== null;
  }

  public getRefreshToken() {
    return this.refreshToken;
  }

  public async checkAccountValidity() {
    if (!this.refreshToken)
      return { error: undefined, warning: undefined, loggedIn: false };
    const res = await this.resetSessionToken();
    return { error: res.error, warning: res.warning, loggedIn: res.success };
  }

  public reset() {
    this.refreshToken = null;
    this.sessionToken = null;
    this.sessionInfo = {};
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  public getSessionInfo() {
    return this.sessionInfo;
  }
}

/**
 * Manager for authentication tokens
 */
const Auth = AuthManager.getInstance();

export default Auth;
