import { Box, Button, ButtonGroup, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { language } from '../Config';
import { Background, CircularSelect } from 'ui-utils';
import { CircularSelectProps } from 'ui-utils/src/components/CircularSelect/CircularSelect';
import { Constraint, ConstraintPreset } from './Constraints';
import { Info } from '@mui/icons-material';

type StrippedCircularSelectProps = Omit<
  CircularSelectProps,
  'onSelect' | 'onChange' | 'value' | 'sx' | 'label' | 'lang'
> & {
  defaultValue?: number;
};

export interface TaskOptionsConfig {
  time: StrippedCircularSelectProps | null;
  items: StrippedCircularSelectProps | null;
  texts?: {
    taskName?: string;
    explanation?: string;
    lowerText?: string;
    labelInfo?: string;
  };
  defaultValue?: ConstraintPreset;
}

export interface TaskOptionsProps {
  onFinish: (constraint: ConstraintPreset) => void;
  onSkip: () => void;
  config: TaskOptionsConfig;
}

export default function TaskOptions(props: TaskOptionsProps) {
  const {
    config: { defaultValue, ...config }
  } = props;
  const [selected, setSelected] = React.useState<'items' | 'time'>(
    defaultValue?.type ?? (config.time ? 'time' : 'items')
  );
  const [time, setTime] = React.useState(
    defaultValue?.type === 'time'
      ? defaultValue.time
      : config.time?.defaultValue ?? 1
  );
  const [items, setItems] = React.useState(
    defaultValue?.type === 'items'
      ? defaultValue.items
      : config.items?.defaultValue ?? 1
  );

  const circularSelectorProps =
    selected === 'items' ? config.items : config.time;

  return (
    <Background
      variant="frame"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
      minWidth="20vw"
      maxWidth="min(80vw, 800px)"
      m="auto"
      gap={2}
      p={4}
      boxSizing="border-box">
      <Typography variant="h4" mb={4}>
        {language.text.task_options}
        {config.texts?.taskName ? ` - ${config.texts.taskName}` : ''}
      </Typography>
      <Typography>
        {config.texts?.explanation ?? language.text.task_options_explanation}
      </Typography>
      {config.time && config.items && (
        <ButtonGroup>
          <Button
            sx={{ flex: '1 0 0' }}
            variant={selected === 'items' ? 'contained' : 'outlined'}
            onClick={() => setSelected('items')}>
            {language.text.count}
          </Button>
          <Button
            sx={{ flex: '1 0 0' }}
            variant={selected === 'time' ? 'contained' : 'outlined'}
            onClick={() => setSelected('time')}>
            {language.text.time}
          </Button>
        </ButtonGroup>
      )}

      <CircularSelect
        {...circularSelectorProps}
        value={selected === 'items' ? items : time}
        onSelect={selected === 'items' ? setItems : setTime}
        label={(value) => {
          const string = (() => {
            const rounded = (Math.round(value * 100) / 100).toLocaleString(
              language.getLanguage()
            );
            if (selected === 'items') return rounded;
            return rounded + 'min';
          })();
          const info = config.texts?.labelInfo ? (
            <Tooltip title={<Typography>{config.texts.labelInfo}</Typography>}>
              <Info
                color="disabled"
                sx={{
                  cursor: 'pointer',
                  fontSize: '.8em',
                  verticalAlign: 'text-top',
                  ml: 0.5
                }}
              />
            </Tooltip>
          ) : null;

          return (
            <Typography textAlign="center">
              {string}
              {info}
            </Typography>
          );
        }}
      />
      {config.texts?.lowerText && (
        <Typography variant="subtitle1">{config.texts.lowerText}</Typography>
      )}

      <Box display="flex" justifyContent="flex-end" gap={2} width="100%">
        <Button onClick={props.onSkip} variant="contained" color="neutral">
          {language.text.skip_task}
        </Button>
        <Button
          onClick={() => {
            let constraint: Constraint;
            if (selected === 'items')
              constraint = {
                type: 'items',
                items: items,
                itemsDone: 0
              };
            else
              constraint = {
                type: 'time',
                time: time,
                start: new Date()
              };
            props.onFinish(constraint);
          }}
          disabled={
            (selected === 'items' && items === 0) ||
            (selected === 'time' && time === 0)
          }
          color="primary"
          variant="contained">
          {language.text.lets_go}
        </Button>
      </Box>
    </Background>
  );
}
