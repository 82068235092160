import React from 'react';
import { Box, CircularProgress, Fab, TextField } from '@mui/material';
import { FancyButton, SaveIcon } from 'ui-utils';
import ConnectProfileTask, { ConnectionType } from './ConnectProfileTask';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import { useSnackbar } from 'notistack';
import { wait } from '@idot-digital/generic-helpers';
import HUDSidePanel from '@/components/HUD/HUDSidePanel';
import HUDFocusBar from '@/components/HUD/HUDFocusBar';
import { language } from '@/index';
import { CSEventType } from 'webview-preload';
import { useScheduler } from 'focus-mode-scheduler';
import Logger from 'electron-log';

export interface ConnectProfileTaskUIProps {
  task: ConnectProfileTask;
}

export default function ConnectProfileTaskUI(props: ConnectProfileTaskUIProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { task } = props;
  const { complete, skip } = useScheduler();

  const [notes, setNotes] = React.useState('');
  React.useEffect(() => {
    task.setNotes(notes);
  }, [notes]);
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const [nextLoading, setNextLoading] = React.useState(false);
  const loading = React.useRef(false);
  loading.current = nextLoading;

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const messageHandler = React.useCallback(
    <Event extends CSEventType>(channel: Event) => {
      switch (channel) {
        case 'linkedin:connect':
          // const connectProfile = data as CSEventData<'linkedin:connect'>;
          enqueueSnackbar(language.text.connect_via_sidebar_buttons, {
            variant: 'info'
          });
          break;
        case 'linkedin:connection-pending':
          if (loading.current) return;
          Logger.info(
            '[ConnectProfileTaskUI] Profile has pending connection -> skip task instance'
          );
          skip('instance');
          // remove marked profile to not show again in connection task
          // once connection request is accepted we will find the contact during load of the network and prompt the user to categorize the contact
          props.task.contact.remove();
          break;
      }
    },
    [task]
  );

  const next = async (type: ConnectionType) => {
    try {
      setNextLoading(true);
      task.setConnectionType(type);
      await complete({
        itemsDone:
          type === ConnectionType.POTENTIAL_CUSTOMER ||
          type === ConnectionType.AUDIENCE_HOLDER
            ? 1
            : 0
      });
    } finally {
      if (mounted.current) setNextLoading(false);
    }
  };

  const boxRef = React.useRef<HTMLDivElement>(null);

  const categorizeButtons = (
    <Box
      ref={boxRef}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}>
      <Box
        sx={{
          'flexGrow': 1,
          'width': '100%',
          'display': 'flex',
          'flexDirection': 'column',
          'justifyContent': 'center',
          'alignItems': 'center',
          'gap': (theme) => theme.spacing(1),
          '> *': {
            flex: '1 0 0'
          }
        }}>
        <FancyButton
          fType={{
            promise: true,
            pulsating: 'default'
          }}
          animations={{
            disableElevation: true,
            disableOnActive: true,
            color: (theme) => theme.palette.secondary.main
          }}
          disabled={nextLoading}
          size="large"
          variant="contained"
          color="primary"
          sx={{
            width: '100%',
            p: (theme) => theme.spacing(1, 3),
            fontWeight: 400,
            display: 'inline',
            fontSize: {
              md: '0.8em',
              lg: '0.8em',
              xl: '1em'
            }
          }}
          onClick={() => next(ConnectionType.POTENTIAL_CUSTOMER)}>
          {language.text.connect_as_potential_customer}
        </FancyButton>

        <FancyButton
          disabled={nextLoading}
          fType={{
            promise: true,
            pulsating: 'default'
          }}
          animations={{
            disableElevation: true,
            disableOnActive: true
          }}
          size="large"
          variant="contained"
          color="secondary"
          sx={{
            width: '100%',
            p: (theme) => theme.spacing(1, 3),
            fontWeight: 400,
            display: 'inline',
            fontSize: {
              md: '0.8em',
              lg: '0.8em',
              xl: '1em'
            }
          }}
          onClick={() => next(ConnectionType.AUDIENCE_HOLDER)}>
          {language.text.connect_as_audience_holder}
        </FancyButton>

        <FancyButton
          fType={{
            promise: true,
            pulsating: 'default'
          }}
          animations={{
            disableElevation: true,
            disableOnActive: true,
            color: (theme) => theme.palette.action.disabled
          }}
          disabled={nextLoading}
          size="large"
          variant="contained"
          color="inherit"
          sx={{
            color: (theme) => theme.palette.text.primary,
            width: '100%',
            p: (theme) => theme.spacing(1, 3),
            display: 'inline',
            fontSize: {
              md: '0.8em',
              lg: '0.8em',
              xl: '1em'
            }
          }}
          onClick={() => next(ConnectionType.LATER)}>
          {language.text.connect_later}
        </FancyButton>

        <FancyButton
          fType={{
            promise: true,
            pulsating: 'default'
          }}
          animations={{
            disableElevation: true,
            disableOnActive: true,
            color: (theme) => theme.palette.error.light
          }}
          disabled={nextLoading}
          size="large"
          variant="contained"
          sx={{
            'width': '100%',
            'backgroundColor': 'error.light',
            '&:hover': {
              backgroundColor: 'error.dark'
            },
            'color': 'error.contrastText',
            'p': (theme) => theme.spacing(1, 3),
            'display': 'inline',
            'fontSize': {
              md: '0.8em',
              lg: '0.8em',
              xl: '1em'
            }
          }}
          onClick={() => next(ConnectionType.NO_MATCH)}>
          {language.text.dont_connect}
        </FancyButton>
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        background: 'background.paper'
      }}>
      <HUDSidePanel>
        <Box flex={1} />
        {categorizeButtons}
        <Box
          sx={{
            position: 'relative',
            maxWidth: 'min(650px, 80vw)',
            width: '100%',
            mt: 6
          }}>
          <TextField
            multiline
            rows={6}
            placeholder={language.text.make_notes_about_person}
            variant="outlined"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper
            }}
          />
          <Fab
            variant="circular"
            color="primary"
            size="small"
            disabled={saveButtonLoading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises -- async managed via loading state
            onClick={async () => {
              try {
                setSaveButtonLoading(true);
                // add some delay to make sure the user sees the loading state
                const timeout = wait(600);
                task.setNotes(notes);
                await timeout;
              } finally {
                setSaveButtonLoading(false);
              }
            }}
            sx={{
              position: 'absolute',
              bottom: (theme) => theme.spacing(1),
              right: (theme) => theme.spacing(1),
              boxShadow: 'none'
            }}>
            {saveButtonLoading ? <CircularProgress size="1em" /> : <SaveIcon />}
          </Fab>
        </Box>
        <Box flex={1} />
      </HUDSidePanel>
      <HUDFocusBar />
      <Box
        width="100%"
        flex="1"
        sx={{
          boxSizing: 'border-box',
          paddingRight: {
            md: '180px',
            lg: '180px',
            xl: '180px'
          }
        }}>
        <LinkedInWebview
          reloadToStartURL
          startURL={`https://linkedin.com/in/${task.contact.publicIdentifier}/`}
          features={[
            // 'prevent-leave-profile',
            'disable-connecting',
            'hide-connect-button',
            'focus-mode-style'
          ]}
          messageHandler={messageHandler}
          onOpenChat={() => {
            // enqueueSnackbar(
            //   'Du kannst keine Nachrichten schreiben, während du dich vernetzt.',
            //   { variant: 'info' }
            // );
            return false;
          }}
        />
      </Box>
    </Box>
  );
}
