import MessageBus from '@common/MessageBus/MessageBus.renderer';
import LinkedInLoadingManager from '../LoadingManager/LinkedInLoadingManager.renderer';
import { Objects } from '@idot-digital/generic-helpers';
import { LinkedInAccount } from 'linkedin-domain-types';
import { getSelf } from '@/data/LinkedIn/Account';
import { SetMessages, UncategorizedProfile } from '@common/types/ApiTypes';
import { chats, contacts, tmp } from '@digital-sun-solutions/cloud-functions';
import Auth from '@common/AuthManager/Auth.renderer';
import { uncategorized } from '@digital-sun-solutions/cloud-functions/dist/client/contacts';
import apiRetry from '@common/FetchRetry/FetchRetry.renderer';
import log from 'electron-log';
import { Arrays } from '@/other/Arrays';
import ContactActions from '@/data/DataServer/Contact';
import { ContactType } from '@common/types/enums';
import { ServerPipelineStepType } from '@common/PipelineManager/PipelineTypes';

const MAX_CACHE_AGE = 5 * 60 * 1000; // 5 minutes

const changesCache = new Map<string, { data: unknown; saved_at: Date }>();
const messageCache = new Map<string, { data: unknown; saved_at: Date }>();
// list with connected profileIDs
const connectionList = new Set<string>();

// when checking for new messages we need to pause the request interceptor
let mode: 'active' | 'paused' = 'active';
const cachedChanges: (() => Promise<unknown>)[] = [];

let isAdvancedLoadActive = false;
export function setAdvancedLoadActive(active: boolean) {
  Auth.execRoute((token) =>
    tmp.saveEvent(
      {
        name: 'AdvancedLoadStatus',
        payload: {
          active: active
        }
      },
      { token }
    )
  ).catch((e: unknown) => log.error(e));
  isAdvancedLoadActive = active;
}

// prevent mounting listener multiple times
let started = false;
export function handleRequestInterceptorData() {
  // prevent mounting listener multiple times
  if (started) return;
  started = true;

  // clear old data from cache (profiles older than MAX_CACHE_AGE)
  setInterval(() => {
    const now = Date.now();
    for (const [key, value] of changesCache.entries())
      if (now - value.saved_at.getTime() > MAX_CACHE_AGE)
        changesCache.delete(key);

    for (const [key, value] of messageCache.entries())
      if (now - value.saved_at.getTime() > MAX_CACHE_AGE)
        messageCache.delete(key);
  }, 10_000);

  // mount message bus listeners to messages from request interceptor
  const messageBus = MessageBus.getInstance();

  // listen when to pause sending data to server
  // during the checking of messages in the webview we pause sending data to the server to prevent getting data from the server that was just updated
  messageBus.on('checking-messages', async ({ state }) => {
    mode = state === 'start' ? 'paused' : 'active';
    if (state === 'start') return;
    log.debug(`[ReqInt] commiting ${cachedChanges.length} cached changes`);
    // commit cached changes
    while (cachedChanges.length) {
      await cachedChanges.pop()?.();
    }
  });

  async function doChange(fct: () => Promise<unknown>): Promise<void> {
    if (mode === 'paused') {
      log.debug('[ReqInt] caching change');
      cachedChanges.push(fct);
      return Promise.resolve();
    }
    await fct();
  }

  // listen to connected events
  messageBus.on('ReqIntersect:connectionDistance', async (payload) => {
    log.debug(`[ReqInt:cd] received ${payload.distances.length} distances`);

    await LinkedInLoadingManager.waitForInit();

    for (const distance of payload.distances) {
      if (distance.isConnection) connectionList.add(distance.profileID);
    }

    const knownConnections = LinkedInLoadingManager.filterInterestingProfiles(
      payload.distances.filter((d) => d.isConnection).map((d) => d.profileID)
    );

    const newConnections = payload.distances.filter(
      (distance) =>
        distance.isConnection && !knownConnections.includes(distance.profileID)
    );

    // update connection requests to potential customers
    for (const connection of newConnections) {
      const contact = await ContactActions.getContact(connection.profileID);
      if (contact?.type === ContactType.CONNECTION_REQUEST_SENT) {
        contact.setContactType(ContactType.POTENTIAL_CUSTOMER);
      }
    }
  });

  // listen to received profiles
  messageBus.on('ReqIntersect:profile', async (payload) => {
    log.debug(`[ReqInt:mp] received ${payload.profiles.length} mini profiles`);

    await LinkedInLoadingManager.waitForInit();

    // saving mini profiles of categorized profiles
    const interestingProfiles =
      LinkedInLoadingManager.filterInterestingProfiles(
        payload.profiles.map((profile) => profile.profileID)
      );

    const profiles = Arrays.filterNull(
      interestingProfiles.map((profile) =>
        payload.profiles.find((p) => p.profileID === profile)
      )
    );

    // check with cache which profiles have changed
    const changedProfiles = profiles.filter((profile) =>
      hasMiniProfileChanged(profile)
    );

    for (const profile of changedProfiles) {
      changesCache.set(profile.profileID, {
        data: {
          ...(changesCache.get(profile.profileID)?.data as LinkedInAccount),
          ...profile
        },
        saved_at: new Date()
      });
    }

    if (changedProfiles.length) {
      await doChange(() =>
        apiRetry(() =>
          Auth.execRoute((token) =>
            contacts.setProfileData(
              {
                miniProfiles: changedProfiles.map((p) => ({
                  firstname: p.firstName,
                  lastname: p.lastName,
                  profileID: p.profileID,
                  publicIdentifier: p.publicIdentifier,
                  pictures: p.profilePictureUrl
                }))
              },
              { token }
            )
          )
        )
      );
    }

    const ownProfile = await getSelf(true);

    // saving mini profiles of uncategorized but connected profiles
    const connectedProfiles = payload.profiles.filter(
      (profile) =>
        !interestingProfiles.includes(profile.profileID) &&
        // is profile connected
        connectionList.has(profile.profileID) &&
        profile.profileID !== ownProfile?.profileID
    );
    if (connectedProfiles.length) {
      log.debug(
        `[ReqInt:mp] updating ${connectedProfiles.length} connected profiles`
      );
      await doChange(() =>
        Auth.execRoute((token) =>
          uncategorized.add(
            connectedProfiles.map((p) => ({
              firstname: p.firstName,
              lastname: p.lastName,
              profileID: p.profileID,
              publicIdentifier: p.publicIdentifier,
              pictures: p.profilePictureUrl
            })),
            { token }
          )
        )
      );
    }
  });

  // listen to received full profiles
  messageBus.on('ReqIntersect:fullProfile', async (payload) => {
    await LinkedInLoadingManager.waitForInit();
    log.debug(`[ReqInt:fp] received ${payload.profiles.length} full profiles`);
    const interestingProfiles =
      LinkedInLoadingManager.filterInterestingProfiles(
        payload.profiles
          .filter(
            (p) => p.firstName && p.lastName && p.infoText && p.publicIdentifier
          )
          .map((profile) => profile.profileID)
      );

    const profiles = Arrays.filterNull(
      interestingProfiles.map((profile) =>
        payload.profiles.find((p) => p.profileID === profile)
      )
    );

    // check with cache which profiles have changed
    const changedProfiles = profiles.filter((profile) => {
      const cachedProfile = changesCache.get(profile.profileID);
      if (!cachedProfile) return true;
      return !Objects.deepEqual(cachedProfile.data, profile);
    });

    for (const profile of changedProfiles) {
      changesCache.set(profile.profileID, {
        data: profile,
        saved_at: new Date()
      });
    }

    // TODO: expand types for full profile (get more data from intercepted request)
    if (changedProfiles.length) {
      log.debug(
        `[ReqInt:fp] updating ${changedProfiles.length} known profiles`
      );
      await doChange(() =>
        Auth.execRoute((token) =>
          apiRetry(() =>
            contacts.setProfileData(
              {
                profiles: changedProfiles.map((p) => ({
                  firstname: p.firstName,
                  lastname: p.lastName,
                  profileID: p.profileID,
                  publicIdentifier: p.publicIdentifier,
                  pictures: p.profilePictureUrl,
                  city: '',
                  country: '',
                  headline: p.infoText
                }))
              },
              { token }
            )
          )
        )
      );
    }

    // get connected profiles that are not yet in db -> create them as uncategorized
    const newConnections = payload.profiles.filter(
      (profile) =>
        profile.isConnection && !interestingProfiles.includes(profile.profileID)
    );

    log.debug(`[ReqInt:fp] received ${newConnections.length} new connections`);

    if (newConnections.length) {
      const uncategorizedProfiles: Omit<
        UncategorizedProfile,
        'id' | 'priority'
      >[] = [];
      for (const profile of newConnections) {
        const contact = await ContactActions.getContact(profile.profileID);
        if (contact?.type === ContactType.CONNECTION_REQUEST_SENT) {
          log.debug(
            `[ReqInt:fp] setting ${profile.profileID} as potential customer after connection now accepted`
          );
          await doChange(() =>
            contact.setContactType(ContactType.POTENTIAL_CUSTOMER)
          );
        } else {
          uncategorizedProfiles.push({
            firstname: profile.firstName,
            lastname: profile.lastName,
            profileID: profile.profileID,
            publicIdentifier: profile.publicIdentifier,
            pictures: profile.profilePictureUrl
          });
        }
      }
      if (uncategorizedProfiles.length) {
        log.debug(
          `[ReqInt:fp] saving ${uncategorizedProfiles.length} new unknown connections`
        );
        await doChange(() =>
          Auth.execRoute((token) =>
            uncategorized.add(uncategorizedProfiles, { token })
          )
        );
      }
    }
  });

  // listen to received messages
  messageBus.on('ReqIntersect:messages', async (payload) => {
    log.debug(`[ReqInt:me] received ${payload.messages.length} messages`);
    await LinkedInLoadingManager.waitForInit();
    const ownProfileID = (await getSelf(true))?.profileID;
    if (!ownProfileID) return;
    const interstingMessages: (SetMessages & {
      conversation_id: string;
      sentFrom: string;
    })[] = [];
    for (const message of payload.messages) {
      const isInteresting =
        LinkedInLoadingManager.filterInterestingConversations([
          { conversationID: message.conversationID }
        ]).length > 0;
      if (!isInteresting) continue;
      if (messageCache.has(message.messageID)) continue;
      messageCache.set(message.messageID, {
        data: message,
        saved_at: new Date()
      });
      interstingMessages.push({
        attachments: message.attachments,
        createdAt: message.createdAt,
        deleted: message.deleted,
        messageID: message.messageID,
        reactions: message.reactions,
        sendByYou: message.sentFrom === ownProfileID,
        text: message.text,
        conversation_id: message.conversationID,
        sentFrom: message.sentFrom
      });
    }

    const groupedMessages = interstingMessages.reduce<{
      [conversationID: string]:
        | { messages: SetMessages[]; profileID: string | undefined }
        | undefined;
    }>(
      (acc, message) => ({
        ...acc,
        [message.conversation_id]: {
          messages: [
            ...(acc[message.conversation_id]?.messages ?? []),
            message
          ],
          profileID:
            acc[message.conversation_id]?.profileID ?? message.sendByYou
              ? undefined
              : message.sentFrom
        }
      }),
      {}
    );

    for (const [conversationID, data] of Objects.entries(groupedMessages)) {
      if (!data) continue;
      const { messages, profileID } = data;
      log.debug(`[ReqInt:me] saving ${messages.length} messages`);
      await Promise.all(
        Arrays.split(messages, 50).map((messages) =>
          doChange(() =>
            Auth.execRoute((token) =>
              chats.addMessages(
                {
                  conversationID: conversationID as string,
                  messages
                },
                { token }
              )
            ).then(async (res) => {
              if (res.code !== 200) return;
              // if new messages from other party have been received, profileID must be defined
              if (!profileID) return;
              if (!res.data.newMessageFromOtherPartySaved) return;
              const contact = await ContactActions.getContact(profileID);
              if (
                contact?.lastPipelineEvent?.stepType !==
                ServerPipelineStepType.WaitForMessageReceived
              )
                return;
              log.debug(
                `[ReqInt:me] received message, moving ${profileID} to next pipeline step`
              );
              await contact.gotoNextPipelineStep();
            })
          )
        )
      );
    }
  });

  messageBus.on('ReqIntersect:conversations', async (payload) => {
    log.debug(
      `[ReqInt:co] received ${payload.conversations.length} conversations`
    );
    const interestingConversations =
      LinkedInLoadingManager.filterInterestingConversations(
        payload.conversations.map((conversation) => ({
          conversationID: conversation.conversationID,
          profileIDs: conversation.participants.map((p) => p.profileID)
        }))
      );

    const knownConversations =
      LinkedInLoadingManager.filterInterestingConversations(
        payload.conversations.map((conversation) => ({
          conversationID: conversation.conversationID
        }))
      );

    let knownCount = 0;
    let newCount = 0;
    for (const conversation of payload.conversations) {
      if (!interestingConversations.includes(conversation.conversationID))
        continue;

      const isKnown = knownConversations.includes(conversation.conversationID);

      if (isKnown) {
        {
          knownCount++;
          await doChange(() =>
            Auth.execRoute((token) =>
              chats.setState(
                {
                  archived: conversation.archived,
                  conversationID: conversation.conversationID,
                  unread: conversation.unreadCount
                },
                { token }
              )
            )
          );
        }
      } else {
        const profileID = conversation.participants[0]?.profileID;
        messageBus.emit('conversation-created', {
          conversationID: conversation.conversationID,
          profileID
        });
        newCount++;
        await doChange(() =>
          Auth.execRoute((token) =>
            chats.create(
              {
                archived: conversation.archived,
                conversationID: conversation.conversationID,
                profileID,
                unreadCount: conversation.unreadCount
              },
              { token }
            )
          )
        );
      }
    }
    if (knownCount)
      log.debug(`[ReqInt:co] updated ${knownCount} known conversations`);
    if (newCount)
      log.debug(`[ReqInt:co] created ${newCount} new conversations`);
  });

  messageBus.on('ReqIntersect:comments', async (payload) => {
    if (!isAdvancedLoadActive) return;
    await Auth.execRoute((token) =>
      tmp.saveEvent(
        {
          name: 'ReqIntersect:comments',
          payload
        },
        { token }
      )
    );
  });
  messageBus.on('ReqIntersect:post', async (payload) => {
    if (!isAdvancedLoadActive) return;
    await Auth.execRoute((token) =>
      tmp.saveEvent(
        {
          name: 'ReqIntersect:post',
          payload
        },
        { token }
      )
    );
  });
  messageBus.on('ReqIntersect:reactions', async (payload) => {
    if (!isAdvancedLoadActive) return;
    await Auth.execRoute((token) =>
      tmp.saveEvent(
        {
          name: 'ReqIntersect:reactions',
          payload
        },
        { token }
      )
    );
  });
  messageBus.on('ReqIntersect:repost', async (payload) => {
    if (!isAdvancedLoadActive) return;
    await Auth.execRoute((token) =>
      tmp.saveEvent(
        {
          name: 'ReqIntersect:repost',
          payload
        },
        { token }
      )
    );
  });
  messageBus.on('ReqIntersect:onlineStatuses', async (payload) => {
    if (!isAdvancedLoadActive) return;
    await Auth.execRoute((token) =>
      tmp.saveEvent(
        {
          name: 'ReqIntersect:onlineStatuses',
          payload: {
            profiles: payload.profiles
              .filter((profile) => profile.lastActiveAt)
              .map((profile) => ({
                ...profile,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- filtered out
                lastActiveAt: new Date(profile.lastActiveAt!).toISOString()
              }))
          }
        },
        { token }
      )
    );
  });
  messageBus.on('ReqIntersect:company', async (payload) => {
    if (!isAdvancedLoadActive) return;
    await Auth.execRoute((token) =>
      tmp.saveEvent(
        {
          name: 'ReqIntersect:company',
          payload: {
            companies: payload.companies.map((company) => ({
              ...company,
              id: company.entityUrn.toString()
            }))
          }
        },
        { token }
      )
    );
  });
}

function hasMiniProfileChanged(profile: LinkedInAccount) {
  const cachedProfile = changesCache.get(profile.profileID);
  if (!cachedProfile) return true;
  return !Objects.deepEqual(
    Objects.pick(cachedProfile.data as LinkedInAccount, Objects.keys(profile)),
    profile
  );
}
