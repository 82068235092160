import React from 'react';
import { Chip } from '@mui/material';
import {
  VariableBlockAttributes,
  VariableTypes,
  getVariableName
} from './VariableBlock';
import { NodeViewWrapper } from '@tiptap/react';
import OptionalTooltip from '../../../OptionalTooltip/OptionalTooltip';
import { language } from '../../../../Config';

export interface VariableBlockViewProps {
  node: {
    attrs: VariableBlockAttributes;
  };
  deleteNode: () => void;
  selected: boolean;
}

const VariableBlockView = React.forwardRef<
  HTMLSpanElement,
  VariableBlockViewProps
>((props, ref) => {
  const Tooltips = {
    fullname: language.text.fullname_explanation,
    firstname: language.text.firstname_explanation,
    lastname: language.text.lastname_explanation
  } as { [id in VariableTypes]: string | undefined };
  return (
    <NodeViewWrapper ref={ref} as="span" style={{ verticalAlign: 'baseline' }}>
      <OptionalTooltip title={Tooltips[props.node.attrs.id]} placement="top">
        <Chip
          data-drag-handle
          label={getVariableName(props.node.attrs.id)}
          size="small"
          onDelete={() => props.deleteNode()}
          sx={{
            fontSize: '1em',
            verticalAlign: 'top',
            backgroundColor: props.selected ? 'primary.main' : 'primary.light'
          }}
          color="primary"
        />
      </OptionalTooltip>
    </NodeViewWrapper>
  );
});

export default VariableBlockView;
