import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import React from 'react';
import { wait } from '@idot-digital/generic-helpers';
import { useNavigate } from 'react-router-dom';
import {
  ArrowBackIcon,
  AvaIcon,
  Background,
  FancyButton,
  Logo
} from 'ui-utils';
import { Delete } from '@mui/icons-material';
import { FocusMode } from 'focus-mode-scheduler';
import { language } from '@/index';
import FocusModeActions from '../Modes';

export interface StartWrapperProps {
  children: React.ReactNode | React.ReactNode[];
  mode?:
    | (Pick<FocusMode, 'title' | 'description' | 'tasks' | 'id'> & {
        custom?: boolean;
      })
    | null;
  onBack: () => void;
}

export default function StartWrapper(props: StartWrapperProps) {
  const navigate = useNavigate();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%'
      }}>
      <Background
        variant="frame"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: {
            lg: '86%',
            xl: '82%'
          }
        }}>
        <Box
          sx={{
            height: '100%',
            maxWidth: '840px',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto'
          }}>
          <Logo
            variant="blue_text"
            sx={{
              width: '200px',
              height: 'auto',
              mb: 5,
              mt: 5
            }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              pb: 2,
              boxSizing: 'border-box',
              flexGrow: 1
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                padding: 0,
                boxSizing: 'border-box'
              }}>
              <AvaIcon
                width={200}
                style={{ marginLeft: 10, marginRight: 10 }}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '100%'
              }}>
              <Box
                sx={{
                  'display': 'flex',
                  'flexDirection': 'column',
                  'justifyContent': 'flex-start',
                  'alignItems': 'center',
                  'width': '100%',
                  'height': '100%',
                  'px': 4,
                  'position': 'relative',
                  'borderLeft': '1.5px solid',
                  'borderLeftColor': (theme) => theme.palette.primary.light,
                  'boxSizing': 'border-box',
                  'gap': 2,
                  '&::before': {
                    content: '" "',
                    position: 'absolute',
                    top: '120px',
                    left: '-40px',
                    transform: 'translateY(-50%)',
                    border: '20px solid transparent',
                    borderRightColor: (theme) => theme.palette.primary.light
                  }
                }}>
                {props.mode && (
                  <>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 2,
                        mb: 2
                      }}>
                      <IconButton onClick={props.onBack} color="inherit">
                        <ArrowBackIcon />
                      </IconButton>
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 'bold',
                          mb: 0,
                          textAlign: 'center'
                        }}>
                        {props.mode.title}
                      </Typography>
                      {props.mode.custom ? (
                        <IconButton
                          onClick={setConfirmDeleteOpen.bind(null, true)}>
                          <Delete color="error" />
                        </IconButton>
                      ) : (
                        <Box sx={{ width: '2.5rem' }} />
                      )}
                    </Box>

                    <Typography
                      variant="body2"
                      sx={{
                        marginBottom: (theme) => theme.spacing(2),
                        textAlign: 'left',
                        whiteSpace: 'pre',
                        textWrap: 'pretty',
                        width: '100%'
                      }}>
                      {props.mode.description}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mt: 2
                      }}>
                      <Typography
                        variant="body2"
                        sx={{
                          textAlign: 'left',
                          fontSize: '1rem',
                          color: (theme) => theme.palette.primary.light,
                          marginBottom: (theme) => theme.spacing(0)
                        }}>
                        {props.mode.tasks.filter((t) => !t.hide).length > 1
                          ? language.text.multiple_steps(
                              props.mode.tasks.filter((t) => !t.hide).length
                            )
                          : language.text.one_step}
                      </Typography>
                    </Box>
                  </>
                )}
                {props.children}
              </Box>
            </Box>
          </Box>
        </Box>
      </Background>
      <Box
        sx={{
          width: '100%',
          height: {
            lg: '14%',
            xl: '18%'
          },
          backgroundColor: (theme) => theme.palette.background.paper,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <FancyButton
          fType={{
            promise: true
          }}
          variant="contained"
          onClick={async () => {
            await wait(Math.random() * 500 + 250);
            navigate('/manual');
          }}
          color="inherit"
          size="large">
          {language.text.back_to_manual_mode}
        </FancyButton>
      </Box>
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}>
        <DialogTitle>
          {language.text.confirm_delete_mode(props.mode?.title ?? '')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {language.text.confirm_delete_mode_description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={setConfirmDeleteOpen.bind(null, false)}
            variant="contained"
            color="neutral">
            {language.text.cancel}
          </Button>
          <FancyButton
            onClick={async () => {
              if (!props.mode) return;
              try {
                await FocusModeActions.removeFocusMode(props.mode.id);
                props.onBack();
              } finally {
                setConfirmDeleteOpen(false);
              }
            }}
            variant="contained"
            color="error">
            {language.text.delete}
          </FancyButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
