import { Contact } from '../Contact';
import { Message, MessageTemplate } from '@common/types/ApiTypes';
import {
  CategoryConversation,
  Conversation,
  ConversationContact
} from 'linkedin-domain-types';
import { language } from '@/index';
import { VariableTypes } from 'ui-utils/src/components/TextEditor/CustomBlocks/Variable/VariableBlock';
import ChatStorage from './ChatStorage';
import { EventEmitter } from '@idot-digital/generic-helpers';

const VARIABLE_WRAPPER = (id: VariableTypes) =>
  `<ssc-variable>${id}</ssc-variable>`;

const TEMPLATE_VARIABLES = () =>
  ({
    [VARIABLE_WRAPPER('firstname')]: (c) => c.firstname,
    [VARIABLE_WRAPPER('lastname')]: (c) => c.lastname,
    [VARIABLE_WRAPPER('fullname')]: (c) => c.name
  }) satisfies {
    [key: string]: (
      contact: Pick<Contact, 'firstname' | 'lastname' | 'name'>
    ) => string;
  };

export interface ChatEvent {
  'historic-messages': Message[];
  'new-messages': Message[];
}

export abstract class AbstractChat extends EventEmitter<ChatEvent> {
  /**
   * !IMPORTANT! call this function after the class is initialized
   */
  protected afterConstructor() {
    ChatStorage.registerChat(this);
  }

  public abstract readonly classID: string;
  public contact?: Contact;
  protected abstract conversationData:
    | Conversation
    | CategoryConversation
    | null;
  protected abstract _conversationID: string | undefined;

  /**
   * Fetch the conversation data from the API
   */
  public abstract getConversationData(): Promise<
    Conversation | CategoryConversation | null
  >;

  /**
   * Some message are chat events (like a person joining a group chat)
   * This function checks if a message is an actual message and should be displayed
   */
  protected shouldMessageBeDisplayed(message: Message): boolean {
    return Boolean(message.text) || message.attachments.length > 0;
  }

  public abstract listMessages(
    lastMessage?: Pick<Message, 'createdAt' | 'messageID'>
  ): Promise<(Message & { sentFrom?: string })[]>;

  public abstract sendMessage(
    text: string
  ): Promise<Omit<Message, 'id' | 'userid'>>;

  public abstract sendMessageReaction(
    message: Message,
    emoji: string,
    status: boolean
  ): Promise<void>;

  public abstract markAsRead(read: boolean): Promise<void>;

  public abstract setArchived(archived: boolean): Promise<void>;

  /**
   * Get the profileID for the current chat
   */
  public abstract get profileID(): string;

  public get participants():
    | ConversationContact[]
    | (CategoryConversation['participants'][0] & {
        publicIdentifier?: undefined;
      })[] {
    return this.conversationData?.participants ?? [];
  }
  public get unreadCount(): number {
    return this.conversationData?.unreadCount ?? 0;
  }
  public get read(): boolean {
    return this.conversationData?.read ?? true;
  }
  public get unread(): boolean {
    return !this.read;
  }
  public get archived(): boolean {
    return this.conversationData?.archived ?? false;
  }
  public get conversationID(): string | null {
    return (
      this._conversationID ?? this.conversationData?.conversationID ?? null
    );
  }
  public get lastActivityAt(): Date | null {
    const date = this.conversationData?.lastActivityAt ?? null;
    // copy to prevent mutation
    if (date) return new Date(date);
    return null;
  }
  public get lastMessage(): string | null {
    const lastMessage = this.conversationData?.lastMessages?.[0] ?? null;
    if (!lastMessage) return null;
    if (lastMessage.text) return lastMessage.text;
    if (lastMessage.attachments.length) {
      switch (lastMessage.attachments[0].type) {
        case 'image':
          return language.text.image;
        case 'audio':
          return language.text.voice_recording;
        case 'file':
          return language.text.file;
      }
    }
    return null;
  }

  protected _template: MessageTemplate | null = null;
  public get template() {
    return this._template;
  }

  public setTemplate(template: MessageTemplate | null) {
    this._template = template;
  }

  /**
   * Get the message template for the current step
   */
  public getFilledTemplate(
    template?: string | null,
    contact:
      | Parameters<ReturnType<typeof TEMPLATE_VARIABLES>[string]>[0]
      | undefined = this.contact
  ): string | null {
    let text = template ?? this._template?.text;
    if (!text) return null;
    if (!contact) return text;

    for (const [code, resolve] of Object.entries(TEMPLATE_VARIABLES())) {
      const value = resolve(contact);
      if (value) {
        text = text.replace(new RegExp(code, 'ig'), value);
      }
    }

    return text;
  }

  private updateFunctions = new Set<() => void>();
  public useUpdate() {
    // TODO: add
    console.warn('useUpdate not implemented');
  }
  protected sendUpdate() {
    for (const update of this.updateFunctions) {
      update();
    }
  }

  public abstract registerMessage(
    message: Omit<Message, 'id' | 'userid'>,
    unread?: boolean
  ): void;

  public abstract registerReaction(
    messageID: Message['messageID'],
    reactions: Message['reactions'][0]
  ): void;
}
