import { Box, Divider } from '@mui/material';
import AccountInformation from './AccountInformation';
import LanguageSettings from './LanguageSettings';
import LinkedInLoadingSettings from './LinkedInLoadingSettings';
import Subscription from './Subscription';
import VersionSettings from './VersionSettings';
import React from 'react';
import ZoomSettings from './WindowZoom';

const ITEMS = [
  AccountInformation,
  VersionSettings,
  ZoomSettings,
  LanguageSettings,
  Subscription,
  LinkedInLoadingSettings
];

export default function GeneralSettings() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {ITEMS.map((Item, index) => (
        <React.Fragment key={index}>
          {index !== 0 && <Divider />}
          <Item />
        </React.Fragment>
      ))}
    </Box>
  );
}
