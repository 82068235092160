import React from 'react';
import log from 'electron-log';
import { EventHandlerAndEmitter, Objects } from '@idot-digital/generic-helpers';
import { Task } from '../lib';

export interface TimeConstraintPreset {
  type: 'time';
  time: number; // time in minutes
}

export interface TimeConstraint extends TimeConstraintPreset {
  start: Date;
}

export interface ItemsConstraintPreset {
  type: 'items';
  items: number;
}

export interface ItemsConstraint extends ItemsConstraintPreset {
  itemsDone: number;
}

export type ConstraintPreset = TimeConstraintPreset | ItemsConstraintPreset;
export type Constraint = TimeConstraint | ItemsConstraint;

interface IncomingConstraintsEvents {
  'constraint:choice':
    | {
        type: 'increase';
        amount: number;
      }
    | {
        type: 'skip';
      };
}
interface OutgoingConstraintsEvents {
  'constraint:change': Constraint | null;
  'constraint:breach': Constraint;
  'no-instances': {
    taskname: string;
    hadInstances: boolean;
  };
}

const LOCALSTORAGE_OPTIONS_KEY = 'focusmode:constraints:';

export class Constraints extends EventHandlerAndEmitter<
  IncomingConstraintsEvents,
  OutgoingConstraintsEvents
> {
  constructor(private skipCategory: () => void) {
    super();
  }
  protected constraints = new Map<Task['id'], Constraint | null>();
  protected constraint: {
    constraint: Constraint;
    timeout?: NodeJS.Timeout;
  } | null = null;

  private currentTask: Pick<Task, 'id'> | null = null;

  public getDefaultConstraint(task: Pick<Task, 'id'>): Constraint | null {
    const storedConstraint = localStorage.getItem(
      `${LOCALSTORAGE_OPTIONS_KEY}${task.id}`
    );
    if (!storedConstraint) return null;
    try {
      const parsed = JSON.parse(storedConstraint);
      if (!parsed) return null;
      if (parsed.type === 'time') parsed.start = new Date(parsed.start);
      return parsed;
    } catch (e) {
      log.error('Error while parsing constraint from localstorage', e);
      return null;
    }
  }

  public getConstraint(): Constraint | null {
    return this.constraint?.constraint ?? null;
  }

  private clearTimeout() {
    if (this.constraint?.timeout) clearTimeout(this.constraint.timeout);
  }

  private resetTimeout() {
    this.clearTimeout();
    if (!this.constraint || this.constraint.constraint.type !== 'time') return;
    const msPassed = Date.now() - this.constraint.constraint.start.getTime();
    const msRemaining = this.constraint.constraint.time * 60 * 1000 - msPassed;
    this.constraint.timeout = setTimeout(() => {
      if (this.constraint) {
        this.internalEmit('constraint:breach', this.constraint.constraint);
        this.dialogResponseListener = (response) => {
          if (response.type === 'increase') {
            this.increaseConstraint(response.amount);
          } else {
            this.skipCategory();
          }
        };
      }
    }, msRemaining);
  }

  protected convertToConstraint(
    constraint: Constraint | ConstraintPreset
  ): Constraint {
    if (constraint.type === 'time') {
      return {
        start: new Date(),
        ...constraint
      };
    } else {
      return {
        itemsDone: 0,
        ...constraint
      };
    }
  }

  public async setContraint(
    task: Pick<Task, 'id'>,
    rawConstraint: Constraint | ConstraintPreset | null
  ) {
    this.clearTimeout();
    this.currentTask = task;
    const constraint = rawConstraint
      ? this.convertToConstraint(rawConstraint)
      : null;
    if (!constraint) {
      this.constraint = null;
    } else {
      if (!this.constraint) this.constraint = { constraint };
      else this.constraint.constraint = constraint;
    }
    this.resetTimeout();

    if (constraint)
      localStorage.setItem(
        `${LOCALSTORAGE_OPTIONS_KEY}${task.id}`,
        JSON.stringify(constraint)
      );

    this.constraints.set(task.id, this.constraint?.constraint ?? null);
    this.internalEmit('constraint:change', constraint);
    if (!this.checkContraintBreached()) {
      return false;
    }

    return new Promise((res) => {
      this.internalEmit('constraint:breach', this.getConstraint()!);
      this.dialogResponseListener = (response) => {
        if (response.type === 'increase') {
          this.increaseConstraint(response.amount);
          res(false);
        } else {
          res(true);
        }
      };
    });
  }

  private dialogResponseListener: (
    response: IncomingConstraintsEvents['constraint:choice']
  ) => void = () => undefined;
  protected handleEvent(
    _: 'constraint:choice',
    data: IncomingConstraintsEvents['constraint:choice']
  ): void {
    this.dialogResponseListener(data);
  }

  public checkContraintBreached(): boolean {
    if (!this.constraint) return false;
    const constraint = this.constraint.constraint;

    if (constraint.type === 'time') {
      return (
        Date.now() - constraint.start.getTime() >= constraint.time * 60 * 1000
      );
    } else if (constraint.type === 'items') {
      return constraint.itemsDone >= constraint.items;
    }
    return false;
  }

  public displayNoInstances(taskname: string, hadInstances: boolean) {
    this.internalEmit('no-instances', { taskname, hadInstances });

    return new Promise<void>((res) => {
      this.dialogResponseListener = () => res();
    });
  }

  /**
   * Increases the constraint to give the user "one more" (e.g. one more iteration or one more minute)
   */
  public increaseConstraint(amount = 1) {
    if (!this.constraint || !this.currentTask) return;
    const constraint = this.constraint.constraint;
    if (constraint.type === 'items') constraint.items += amount;
    else if (constraint.type === 'time') {
      const msPassed = Date.now() - constraint.start.getTime();
      // when the time constraint has already passed, we move the start time forward to make so that there is [amount] minutes left
      if (msPassed > constraint.time * 60 * 1000) {
        const previousTime = constraint.time * 60 * 1000;
        constraint.start = new Date(Date.now() - previousTime);
      }
      constraint.time += amount;
    }

    this.setContraint(this.currentTask, constraint);
  }

  public setItemsConstraint(items: number) {
    if (!this.currentTask || this.constraint?.constraint.type !== 'items')
      return;
    const constraint = this.constraint.constraint;
    this.setContraint(this.currentTask, { ...constraint, items });
  }

  public setMinutesConstraint(minutes: number) {
    if (!this.currentTask || this.constraint?.constraint.type !== 'time')
      return;
    const constraint = this.constraint.constraint;
    this.setContraint(this.currentTask, { ...constraint, time: minutes });
  }

  public async registerItemDone(numberDone = 1) {
    if (!this.currentTask) return;
    const constraint = this.constraint?.constraint;
    if (!constraint || constraint.type !== 'items') return;
    constraint.itemsDone += numberDone;
    return this.setContraint(this.currentTask, constraint);
  }
  public setItemsDone(done: number) {
    if (!this.currentTask) return;
    const constraint = this.constraint?.constraint;
    if (!constraint || constraint.type !== 'items') return;
    constraint.itemsDone = done;
    this.setContraint(this.currentTask, { ...constraint }).then(
      (shouldSkip) => {
        if (shouldSkip) this.skipCategory();
      }
    );
  }

  public use<Type extends keyof OutgoingConstraintsEvents>(
    type: Type,
    listener: (data: OutgoingConstraintsEvents[Type]) => void
  ) {
    React.useEffect(() => {
      this.on(type, listener);
      return () => {
        this.off(type, listener);
      };
    }, [type, listener]);
  }

  public useCurrentConstraint() {
    const [constraint, setConstraint] = React.useState<Constraint | null>(
      this.getConstraint()
    );

    this.use('constraint:change', (constraint) =>
      setConstraint(Objects.deepClone(constraint))
    );

    return constraint;
  }

  public unmount() {
    if (this.constraint?.timeout) clearTimeout(this.constraint.timeout);
  }

  public get constraintsMap() {
    return this.constraints;
  }
}
