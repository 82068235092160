import { AbstractChat } from './AbstractChat';

export default class ChatStorage {
  // this storage is supposed to have references to all chats that could be in use
  // we assume that we do not have a SSCChat and an LinkedIn Chat open at the same time
  private static storage = new Map<
    AbstractChat['conversationID'],
    Map<AbstractChat['classID'], AbstractChat>
  >();
  static registerChat(chat: AbstractChat) {
    const map =
      ChatStorage.storage.get(chat.conversationID) ??
      new Map<string, AbstractChat>();
    map.set(chat.classID, chat);
    ChatStorage.storage.set(chat.conversationID, map);
  }

  static unregisterChat(chat: AbstractChat) {
    const map = ChatStorage.storage.get(chat.conversationID);
    map?.delete(chat.classID);
  }

  static getChats(conversationID: string): AbstractChat[] {
    return [...(ChatStorage.storage.get(conversationID)?.values() ?? [])];
  }
}

window.ChatStorage = ChatStorage;
