import React from 'react';
import { Box } from '@mui/material';
import FocusModeSchedulerClass from '../Scheduler';
import TaskOptions, { TaskOptionsConfig } from './TaskOptions';
import { ConstraintPreset, Task, TaskInfo } from '../lib';
import TaskBuilder from '../Task/TaskBuilder';
import { BindProps, MapProps, RandomAnimatedLoading } from 'ui-utils';
import ConstraintDialogs from './ConstraintDialogs';
import { Objects } from '@idot-digital/generic-helpers';

export interface SchedulerContextType {
  /**
   * Complete the current task
   * @param options.itemsDone - number of items done in the current task (do not pass number if already registered to `constraints` during task execution)
   */
  complete: (options: { itemsDone: number }) => Promise<void>;
  skip: (type: 'instance' | 'category') => Promise<void>;
  taskInfo: TaskInfo;
  header: Task['header'];
  constraints: FocusModeSchedulerClass['constraints'];
  customMode: boolean;
  loading: boolean;
}

export interface FocusMode {
  id: number;
  title: string;
  excerpt: string;
  description: string;
  tasks: TaskBuilder[];
}

const SchedulerContext = React.createContext<SchedulerContextType>(null!);

export function useScheduler() {
  return React.useContext(SchedulerContext);
}

/**
 * Context provider for focus mode, handles state and navigation
 * Handles queing of tasks
 */
export function FocusModeScheduler(props: {
  onQuit: () => void;
  onError?: (error: string) => void;
  basePath?: string;
  frame?: React.FC<{ children: React.ReactNode }>;
  startUI: React.FC<{
    onComplete: (
      mode: Pick<FocusMode, 'tasks' | 'id'> & { custom?: boolean }
    ) => Promise<void>;
  }>;
}) {
  const [loading, setLoading] = React.useState(false);

  const [options, setOptions] = React.useState<TaskOptionsConfig | null>(null);
  const [optionsDefaultValue, setOptionsDefaultValue] = React.useState<
    ConstraintPreset | undefined
  >(undefined);
  const resolveOptions = React.useRef<(value: ConstraintPreset | null) => void>(
    () => undefined
  );

  const scheduler = React.useRef(
    new FocusModeSchedulerClass(
      (
        options: TaskOptionsConfig,
        defaultValue: ConstraintPreset | undefined
      ) =>
        new Promise<ConstraintPreset | null>((res) => {
          resolveOptions.current = res;
          setLoading(false);
          setOptions(options);
          setOptionsDefaultValue(defaultValue);
        }),
      props.onError
    )
  );
  React.useEffect(() => {
    return () => {
      // this might fire twice, due to StrictMode
      scheduler.current.unmount();
    };
  }, []);

  const taskUI = React.useRef<React.FC<{}> | null>(
    BindProps(props.startUI, {
      onComplete: (mode) => scheduler.current.startQueue(mode)
    })
  );
  const [taskUIKey, refreshTaskUIKey] = React.useReducer((n) => n++, 0);
  const [taskInfo, setTaskInfo] = React.useState<TaskInfo>({
    description: '',
    title: ''
  });
  const [taskHeader, setTaskHeader] = React.useState<Task['header']>(null);
  const [taskDialogs, setTaskDialogs] = React.useState<
    TaskBuilder['constraintBreachedDialogContent'] | null
  >(null);

  const staticUI = React.useRef<React.FC<{ loading?: boolean }> | null>(null);
  const [staticUIKey, refreshStaticUIKey] = React.useReducer((n) => n++, 0);
  const StaticUI = staticUI.current;

  // listen to scheduler events (e.g. new task, loading, finished)
  React.useEffect(() => {
    function setUI({
      UI,
      info,
      header,
      dialogs
    }: {
      UI: React.FC<{}>;
      info: TaskInfo;
      header: Task['header'];
      dialogs: TaskBuilder['constraintBreachedDialogContent'];
    }) {
      taskUI.current = UI;
      refreshTaskUIKey();
      setTaskInfo(info);
      setTaskHeader(Objects.deepClone(header));
      setTaskDialogs(dialogs);
      setLoading(false);
      setOptions(null);
    }
    function onLoading(loading: boolean) {
      setLoading(loading);
    }
    function onFinish() {
      props.onQuit();
    }
    function onStaticUIChange(UI: React.FC<{ loading?: boolean }> | null) {
      staticUI.current = UI;
      refreshStaticUIKey();
    }

    const startLoading = onLoading.bind(null, true);
    const finishLoading = onLoading.bind(null, false);
    scheduler.current.on('task-changed', setUI);
    scheduler.current.on('start-loading', startLoading);
    scheduler.current.on('finish-loading', finishLoading);
    scheduler.current.on('finished', onFinish);
    scheduler.current.on('static-ui-changed', onStaticUIChange);

    return () => {
      scheduler.current.off('task-changed', setUI);
      scheduler.current.off('start-loading', startLoading);
      scheduler.current.off('finish-loading', finishLoading);
      scheduler.current.off('finished', onFinish);
      scheduler.current.off('static-ui-changed', onStaticUIChange);
    };
  }, []);

  const exportValue: SchedulerContextType = {
    async complete(options: { itemsDone: number }) {
      await scheduler.current.completeTask(options.itemsDone);
    },
    async skip(type: 'instance' | 'category') {
      if (type === 'instance') return scheduler.current.skipTask();
      else return scheduler.current.skipCategory();
    },
    taskInfo: taskInfo,
    header: taskHeader,
    constraints: scheduler.current.constraints,
    customMode: scheduler.current.isCustomMode,
    loading
  };
  const TaskUI = taskUI.current;

  //@ts-expect-error -- we want to expose the scheduler to the window for debugging purposes - this will and should never be used in code
  window.focusMode = { ...exportValue, scheduler: scheduler.current };

  const Frame = props.frame ?? React.Fragment;

  return (
    <SchedulerContext.Provider value={exportValue}>
      <Box
        sx={{
          position: 'fixed',
          inset: 0,
          pointerEvents: loading && !options ? undefined : 'none',
          zIndex: 1000
        }}>
        <RandomAnimatedLoading show={loading && !options} />
      </Box>
      {options && (
        <TaskOptions
          config={{
            ...options,
            defaultValue: optionsDefaultValue ?? options.defaultValue
          }}
          onFinish={(constraint) => {
            setLoading(true);
            resolveOptions.current(constraint);
            setOptions(null);
          }}
          onSkip={() => {
            setLoading(true);
            resolveOptions.current(null);
            setOptions(null);
          }}
        />
      )}
      <ConstraintDialogs
        constraints={scheduler.current.constraints}
        dialogs={taskDialogs}
      />
      {TaskUI && !options && (
        <Frame>
          <Box
            display="block"
            height="100%"
            className="focus-mode-task-container">
            {/* mount UI of current task */}
            <TaskUI key={`taskUI-${taskUIKey}`} />
            {StaticUI && (
              <StaticUI key={`staticUI-${staticUIKey}`} loading={loading} />
            )}
          </Box>
        </Frame>
      )}
    </SchedulerContext.Provider>
  );
}
