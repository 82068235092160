import HUD from '@/components/HUD/HUD';
import { language } from '@/index';
import { APP_PATHS } from '@/Paths';
import LinkedInWebview from '@common/Webview.renderer/Base/LinkedInWebview';
import LinkedInActions from '@common/Webview.renderer/LinkedInActions';
import { CheckCircle, Clear, ErrorOutline } from '@mui/icons-material';
import { Box, Button, IconButton, Popover, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProgressBar } from 'ui-utils';
import PostLoaderHelper from './PostLoaderHelper';
import * as Sentry from '@sentry/electron/renderer';
import { LinkedInWebviewElement } from '@common/Webview.renderer/Base/ContentScriptTypes';
import { setAdvancedLoadActive } from '@common/LinkedInEventHandlers/handleRequestInterceptorData.renderer';

const TOTAL_POSTS = 8;

export default function PostLoaderRun() {
  const navigate = useNavigate();
  // extra precautions to prevent executing the action multiple times
  const actionStarted = React.useRef(false);
  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const leaveButtonRef = React.useRef<HTMLButtonElement | null>(null);
  const [leaveConfirmOpen, setLeaveConfirmOpen] = React.useState(false);

  const [progress, setProgress] = React.useState(0);
  const lastProgress = React.useRef(0);

  const [finished, setFinished] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [webview, setWebview] = React.useState<
    LinkedInWebviewElement | undefined
  >(undefined);

  const startAction = React.useCallback(
    async (newWebview?: LinkedInWebviewElement) => {
      if (actionStarted.current) return;
      actionStarted.current = true;
      if (!newWebview) newWebview = webview;
      if (!newWebview) return;
      try {
        await LinkedInActions.executeAction(
          newWebview,
          'scroll-recent-posts',
          undefined,
          (info) => {
            let progress = 0;
            switch (info) {
              // 0.1 for opening post page
              case 'opening_own_profile':
              case 'opening_recent_posts':
                progress = 0.05;
                break;
              case 'opening_reactions':
                progress = (0.9 / TOTAL_POSTS / 3) * 1.5;
                break;
              case 'opening_reposts':
                progress = (0.9 / TOTAL_POSTS / 3) * 0.5;
                break;
              case 'opening_comments':
                progress = 0.9 / TOTAL_POSTS / 3;
                break;
            }
            setProgress((current) => {
              lastProgress.current = current;
              return current + progress;
            });
          }
        );
      } catch (err) {
        if (!mounted.current) return;
        Sentry.captureException(err);
        // setError(true);;
      } finally {
        if (mounted.current) {
          PostLoaderHelper.setLastTimeCompleted();
          setFinished(true);
          setProgress(1);
        }
      }
    },
    [webview]
  );

  // tell request interceptor to commit saveEvent data
  React.useEffect(() => {
    setAdvancedLoadActive(true);
    return () => {
      setAdvancedLoadActive(false);
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        boxSizing: 'border-box',
        pt: 16,
        pb: 6
      }}>
      <HUD
        topContent={
          <Box
            display="grid"
            gridTemplateColumns="1fr auto 1fr"
            alignItems="center"
            justifyItems="flex-end"
            height="100%"
            width="100%">
            <Box />
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                color: (theme) => theme.palette.secondary.main
              }}>
              {language.text.loading_posts}
            </Typography>
            <IconButton
              ref={leaveButtonRef}
              size="large"
              onClick={() => {
                if (finished) navigate(`/${APP_PATHS.Manual}`);
                else setLeaveConfirmOpen(true);
              }}>
              <Clear color="secondary" />
            </IconButton>
            <Popover
              open={leaveConfirmOpen}
              onClose={() => setLeaveConfirmOpen(false)}
              anchorEl={leaveButtonRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <Box p={2}>
                <Typography maxWidth="500px" mb={1}>
                  {language.text.confirm_leave_load_posts}
                </Typography>
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gap={1}
                  justifyContent="end">
                  <Button
                    onClick={() => setLeaveConfirmOpen(false)}
                    variant="contained"
                    color="neutral">
                    {language.text.cancel}
                  </Button>
                  <Button
                    onClick={() => navigate(`/${APP_PATHS.Manual}`)}
                    variant="contained"
                    color="primary">
                    {language.text.back_to_manual_mode}
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
        }
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          px: 6,
          py: 2,
          boxSizing: 'border-box',
          height: '100%',
          width: '100%',
          maxWidth: '80vw',
          m: 'auto'
        }}>
        <Box flexGrow={1} width="100%">
          <Box
            sx={{
              borderWidth: 4,
              borderColor: 'text.secondary',
              borderStyle: 'solid',
              borderRadius: 2,
              width: '100%',
              height: '100%',
              boxShadow: 7,
              overflow: 'hidden',
              position: 'relative'
            }}>
            <LinkedInWebview
              onWebviewReady={(webview) => {
                setWebview(webview);
                startAction(webview);
              }}
              hideReloadButton
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                bgcolor: 'rgba(255, 255, 255, 0.4)'
              }}
            />
            {(finished || error) && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  p: 2,
                  boxSizing: 'border-box',
                  background: (theme) => theme.palette.background.paper,
                  zIndex: 10
                }}>
                {finished && (
                  <>
                    <CheckCircle color="primary" sx={{ fontSize: '4rem' }} />
                    <Typography variant="h4">
                      {language.text.post_loading_finished}
                    </Typography>
                    <Button
                      size="large"
                      onClick={() => navigate(`/${APP_PATHS.Manual}`)}
                      variant="contained"
                      color="primary">
                      <Typography>
                        {language.text.back_to_manual_mode}
                      </Typography>
                    </Button>
                  </>
                )}
                {error && (
                  <>
                    <Typography variant="h4">
                      <ErrorOutline
                        color="error"
                        sx={{ mr: 1, verticalAlign: 'middle' }}
                      />
                      {language.text.error_while_loading_posts}
                    </Typography>
                    <Typography maxWidth="700px">
                      {language.text.error_while_loading_posts_description}
                    </Typography>
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: 2
                      }}>
                      <Button
                        size="large"
                        onClick={() => navigate(`/${APP_PATHS.Manual}`)}
                        variant="contained"
                        color="primary">
                        <Typography>
                          {language.text.back_to_manual_mode}
                        </Typography>
                      </Button>
                      <Button
                        size="large"
                        onClick={() => {
                          actionStarted.current = false;
                          setError(false);
                          lastProgress.current = 0;
                          setProgress(0);
                          setFinished(false);
                          startAction();
                        }}
                        variant="contained"
                        color="primary">
                        <Typography>{language.text.try_again}</Typography>
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>
        <ProgressBar
          getLabel={() => ''}
          to={progress}
          from={lastProgress.current}
          speedModifier={2}
          colors={{
            bar: 'primary.light',
            background: 'background.paper',
            label: 'primary.contrastText'
          }}
          sx={{
            borderRadius: 2,
            boxShadow: 7
          }}
        />
      </Box>
    </Box>
  );
}
