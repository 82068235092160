import { WireType } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// @generated message type with reflection information, may provide speed optimized methods
class CommentPosted$Type extends MessageType {
    constructor() {
        super("CommentPosted", [
            { no: 1, name: "postID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "posterProfileID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value) {
        const message = globalThis.Object.create((this.messagePrototype));
        message.postID = "";
        message.comment = "";
        message.posterProfileID = "";
        if (value !== undefined)
            reflectionMergePartial(this, message, value);
        return message;
    }
    internalBinaryRead(reader, length, options, target) {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string postID */ 1:
                    message.postID = reader.string();
                    break;
                case /* string comment */ 2:
                    message.comment = reader.string();
                    break;
                case /* string posterProfileID */ 4:
                    message.posterProfileID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message, writer, options) {
        /* string postID = 1; */
        if (message.postID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.postID);
        /* string comment = 2; */
        if (message.comment !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.comment);
        /* string posterProfileID = 4; */
        if (message.posterProfileID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.posterProfileID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message CommentPosted
 */
export const CommentPosted = new CommentPosted$Type();
